import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useMenuModalStates } from "../../../hooks/use-menu-modal-states";
import MenuModal from "../../../components/tools/menu-modal";
import AppointmentStatusHistoryModal from "../../../components/appointment-status-history/appointment-status-history-modal";

import api from "../../../API/api";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  Badge,
  IconButton,
  MenuItem,
  TablePagination,
  TextField,
  Tooltip,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import CustomizedMenus from "../../../components/tools/menu";
import TextFieldTableHead from "../../../components/table/textfield-table-head";
import useDebounce from "../../../hooks/use-debounce";
import DebounceSpinner from "../../../components/common/debounce-spinner";
import AccordionFilter from "../../../components/filter/accordion-filter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#016b8b",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DiagnosticAppointments = () => {
  const tableRef = useRef(null);
  const [getRow, setGetRow] = useState();
  const [appointments, setAppointments] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [filters, setFilters] = useState({
    filter_status: "",
  });

  const {
    openModal,
    modalProps,
    updatingStatus,
    setUpdatingStatus,
    statusHistoryModal,
    statusHistoryModalData,
    setStatusHistoryModalData,
    handleOpenStatusHistoryModal,
    handleCloseStatusHistoryModal,
    setModalProps,
    handleOpenModal,
    handleCloseModal,
  } = useMenuModalStates();

  const viewAppointments = (newPage, perPage) => {
    api
      .getDiagnosticAppointments({ page: newPage, per_page: perPage })
      .then((res) => {
        console.log("===>>>>", res);
        // setDepartment(res.data.data)
        setAppointments(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    viewAppointments();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    viewAppointments(newPage + 1, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    viewAppointments(1, event.target.value);
  };
  const handleStatus = (status, remarks) => {
    setUpdatingStatus(true);
    console.log(getRow, "getRow");
    const payload = {
      diagnostic_id: getRow.diagnostic_appointments[0].diagnostic_id,
      appointment_id: getRow.diagnostic_appointments[0].appointment_id,
      status: status,
      remarks,
    };
    api
      .updateDiagnosticAppointment(payload)
      .then((res) => {
        console.log(res);
        viewAppointments();
        handleCloseModal();
        if (res.data.success) {
          Swal.fire({
            title: "Success!",
            text: "Status Updated",
            icon: "success",
            confirmButtonText: "Ok",
          });
          toast.success("Status Updated Successfully");
        }
      })
      .catch((err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      })
      .finally(() => {
        setUpdatingStatus(false);
        setModalProps({});
      });
  };

  const menuButtons = [
    {
      id: 1,
      name: "Confirmed",
      onClick: (remarks) => handleStatus("confirmed", remarks),
    },
    {
      id: 2,
      name: "Cancelled",
      onClick: (remarks) => handleStatus("cancelled", remarks),
    },
    {
      id: 3,
      name: "Completed",
      onClick: (remarks) => handleStatus("completed", remarks),
    },
  ];
  const getStatusColor = (status) => {
    switch (status) {
      case "requested":
        return "primary";
      case "confirmed":
        return "success";
      case "completed":
        return "warning";
      case "cancelled":
        return "error";
      default:
        return "default";
    }
  };

  const viewAppointmentsByFilters = () => {
    setPage(0);
    setRowsPerPage(5);

    let newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key] === "") {
        delete newFilters[key];
      }
    });

    api
      .getDiagnosticAppointments({ page: 1, per_page: 5 }, newFilters)
      .then((res) => {
        console.log("===>>>>", res);
        // setDepartment(res.data.data)
        setAppointments(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [debouncedViewAppointmentsByFilter, isDebouncing] = useDebounce(
    viewAppointmentsByFilters,
    300
  );

  useEffect(() => {
    debouncedViewAppointmentsByFilter();
  }, [filters]);

  // Calculate the minimum width based on the number of columns
  const columnCount = 9; // Update this number if you add or remove columns
  const minWidth = columnCount * 150; // Assuming each column needs at least 150px

  const handleStatusBadgeClick = (row) => {
    setGetRow(row);
    setStatusHistoryModalData(row);
    handleOpenStatusHistoryModal();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <div className="row justify-content-between">
        <div className="col-12 text-center">
          <h4>Diagnostics Appointments </h4>
        </div>
      </div>
      <AccordionFilter
        filterOptions={{
          from: {
            id: "from",
            label: "From",
            type: "date",
          },
          to: {
            id: "to",
            label: "To",
            type: "date",
          },
        }}
        filters={filters}
        setFilters={setFilters}
        onFilterChange={debouncedViewAppointmentsByFilter}
      />
      <Box
        sx={{
          flexGrow: 1,
          overflow: "hidden",
          maxWidth: "90vw",
          md: {
            flexGrow: 1,
            maxWidth: "100%",
          },
          lg: {
            flexGrow: 1,
            maxWidth: "100%",
          },
        }}
      >
        <Box
          sx={{ width: "100%", overflowX: "auto", height: "100%", flexGrow: 1 }}
        >
          <TableContainer
            component={Paper}
            ref={tableRef}
            sx={{ minWidth: minWidth }}
          >
            <Table stickyHeader aria-label="customized table">
              <TableHead>
                <TableRow>
                  {/* Use responsive design for table headers */}
                  <StyledTableCell sx={{ minWidth: 50 }}>ID</StyledTableCell>
                  <StyledTableCell align="center" sx={{ minWidth: 150 }}>
                    <TextFieldTableHead
                      type={"text"}
                      title={"Patient Name"}
                      onChange={(e) =>
                        setFilters({ ...filters, patient_name: e.target.value })
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ minWidth: 120 }}>
                    Phone No.
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ minWidth: 80 }}>
                    Gender
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ minWidth: 60 }}>
                    Age
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ minWidth: 100 }}>
                    City
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ minWidth: 120 }}>
                    Generated At
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ minWidth: 120 }}>
                    Appoin. Date
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Last Updated By
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ minWidth: 150 }}>
                    <TextField
                      select
                      label="Status"
                      value={filters.filter_status}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          filter_status: e.target.value,
                        })
                      }
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "white",
                          },
                          "&:hover fieldset": {
                            borderColor: "white",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "white",
                          },
                          color: "white", // Text color for the select
                        },
                        "& .MuiInputLabel-root": {
                          color: "white",
                        },
                        "& .MuiSelect-root": {
                          color: "white",
                        },
                        "& .MuiSelect-icon": {
                          color: "white", // Dropdown icon color
                        },
                        "& .MuiMenuItem-root": {
                          color: "white", // Text color for menu items
                        },
                        minWidth: 150,
                        color: "white", // Text color for the TextField
                      }}
                      InputLabelProps={{
                        style: { color: "white" }, // Ensure label is white even when focused
                      }}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            sx: {
                              bgcolor: "rgba(0, 0, 0, 0.8)", // Dark background for dropdown
                              "& .MuiMenuItem-root": {
                                color: "white", // Ensure menu items are white
                              },
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value="">All</MenuItem>
                      <MenuItem value="requested">Requested</MenuItem>
                      <MenuItem value="confirmed">Confirmed</MenuItem>
                      <MenuItem value="completed">Completed</MenuItem>
                      <MenuItem value="cancelled">Cancelled</MenuItem>
                    </TextField>
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ minWidth: 100 }}>
                    Action
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isDebouncing && (
                  <DebounceSpinner isDebouncing={isDebouncing} />
                )}
                {!isDebouncing &&
                  appointments &&
                  appointments?.data?.map((row, index) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {index + 1}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.phone}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.gender}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.age}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.city}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(row.created_at).getDate() +
                          ", " +
                          new Date(row.created_at).toLocaleString("default", {
                            month: "long",
                          }) +
                          "-" +
                          new Date(row.created_at).getFullYear()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(
                          row.diagnostic_appointments[0].date
                        ).getDate() +
                          ", " +
                          new Date(
                            row.diagnostic_appointments[0].date
                          ).toLocaleString("default", {
                            month: "long",
                          }) +
                          "-" +
                          new Date(
                            row.diagnostic_appointments[0].date
                          ).getFullYear()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.last_updated_by &&
                          row.last_updated_by.length > 0 ? (
                          <Stack direction={"column"}>
                            <Typography variant="body2" color="text.secondary">
                              {/* <img  height="40px" src={`${process.env.REACT_APP_BACKEND_USER}/${row?.last_updated_by[0].id}/${row?.last_updated_by[0].image}`} /> */}
                              {
                                row.last_updated_by.length > 0 ?
                                  <img height="40px" src={`${process.env.REACT_APP_BACKEND_USER}/${row?.last_updated_by[row.last_updated_by.length - 1].id}/${row?.last_updated_by[row.last_updated_by.length - 1].image}`} />
                                  :
                                  <img height="40px" src={`${process.env.REACT_APP_BACKEND_USER}/${row?.last_updated_by[0].id}/${row?.last_updated_by[0].image}`} />
                              }
                              {
                                row.last_updated_by.length > 0 ?
                                  row.last_updated_by[row.last_updated_by.length - 1].name
                                  :
                                  row.last_updated_by[0].name

                              }
                            </Typography>
                            <Typography variant="body2">
                              {
                                row.last_updated_by.length > 0 ?
                                  row.last_updated_by[row.last_updated_by.length - 1].email
                                  :
                                  row.last_updated_by[0].email

                              }
                            </Typography>
                          </Stack>
                        ) : (
                          "null"
                        )}
                      </StyledTableCell>

                      {/* <StyledTableCell align="center">
                        {row.doctors[0].name}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        <Tooltip title="Click to view the history" arrow>
                          <IconButton
                            onClick={() => handleStatusBadgeClick(row)}
                          >
                            <Badge
                              badgeContent={row.status}
                              color={getStatusColor(row.status)}
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                              }}
                            ></Badge>
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <CustomizedMenus
                          opensModal={true}
                          setModalProps={setModalProps}
                          handleOpenModal={handleOpenModal}
                          openModal={openModal}
                          row={row}
                          menuButtons={menuButtons}
                          setGetRow={setGetRow}
                          btnText={"update status"}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={appointments?.total ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <MenuModal
        updatingStatus={updatingStatus}
        handleOpen={handleOpenModal}
        handleClose={handleCloseModal}
        open={openModal}
        status={modalProps.status}
        handleClick={modalProps.onClick}
        modalProps={modalProps}
        setModalProps={setModalProps}
      />
      <AppointmentStatusHistoryModal
        open={statusHistoryModal}
        handleClose={handleCloseStatusHistoryModal}
        data={statusHistoryModalData}
        setStatusHistoryModalData={setStatusHistoryModalData}
        getAppointmentHistoryFromServer={api.diagnosticAppointmentHistory}
        loading={updatingStatus}
        historyFor={"diagnostics"}
      />
    </Box>
  );
};

export default DiagnosticAppointments;
