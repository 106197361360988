import React, { useEffect, useState } from 'react'
import api from '../../API/api';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Button } from '@mui/material';
import { Divider } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const CallLeads = () => {

    const [calls, setCalls] = useState([]);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [open, setOpen] = React.useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        setdate();
    }, []);

    const setdate = () => {
        var today = new Date();
        var date = today.toISOString().substr(0, 10);
        setStartDate(date)
        console.log("formated date", date);

        getCallLeads(date, date);
    }

    const getCallLeads = (startDate, endDate) => {
        handleOpen()
        api.getCallLeads(startDate, endDate).then((res) => {
            console.log(res, 'call leades');
            setCalls(res.data.leads)
            handleClose()
        })
            .catch((err) => {
                console.log(err.response);
            })
    }

    const changeStartDate = (e) => {
        setStartDate(e.target.value)

    }

    const changeEndDate = (e) => {
        setEndDate(e.target.value)
    }

    const handleFind = () => {
        getCallLeads(startDate, endDate)
    }

    return (
        <>
            <Paper>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                    onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>


                <div className='p-3'>
                    <h3 className='text-center'>Call Button Clicked Leads</h3>
                    <div className="row m-2">
                        <div className="col-4">

                            <input type="date" className='form-control' value={startDate} onChange={changeStartDate} />
                        </div>
                        <div className="col-4">
                            <input type="date" className='form-control' value={endDate} onChange={changeEndDate} />
                        </div>
                        <div className="col-4">
                            <Button variant='contained' onClick={handleFind}>Find</Button>
                        </div>
                    </div>

                    <Divider />

                    <div className='row m-2'>
                        <Button variant='contained' color='error'>Total Leads: {calls && calls.length} </Button>

                    </div>

                    <div className="row m-2">
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>Ip Address</b></TableCell>
                                        <TableCell align="center"><b>Date</b></TableCell>
                                        <TableCell align="center"><b>Time</b></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {calls.map((row) => (
                                        <TableRow
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.ip_address}
                                            </TableCell>
                                            <TableCell align="center">
                                                {new Date(row.date).getDate()
                                                    + ", " +
                                                    new Date(row.date).toLocaleString("default", { month: "long", })
                                                    + "-" +
                                                    new Date(row.date).getFullYear()}
                                            </TableCell>
                                            <TableCell align="center">{new Date(row.date).toLocaleTimeString()}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                {calls.length === 0 &&
                                    <div className='text-center'>No Leads Found</div>
                                }
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </Paper>
        </>
    )
}

export default CallLeads;
