import {
  Box,
  Button,
  Icon,
  IconButton,
  Menu,
  Modal,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useEffect, useState } from "react";
import api from "../../../API/api";
import DebounceSpinner from "../../../components/common/debounce-spinner";
import CustomPagination from "../../../components/common/custom-pagination";
import TelecallerAddPage from "./telecaller-add.page";
import { toast } from "react-toastify";
import TelecallerPopoverCell from "./features/telecaller-popover-cell";

// style for centering the modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const TelecallerManagePage = () => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [paginateOptions, setPaginateOptions] = useState({
    current_page: 1,
    last_page: 1,
    total: 1,
    per_page: 10,
  });

  // for handling the modal of add telecaller
  const [showAddTelecaller, setShowAddTelecaller] = useState(false);
  const [telecallerForm, setTelecallerForm] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleOpenAddTelecaller = () => {
    setShowAddTelecaller(true);
  };

  const handleCloseAddTelecaller = () => {
    setShowAddTelecaller(false);
  };

  const handleAddTelecaller = () => {
    // validate the form

    if (telecallerForm.name.trim() === "") {
      toast.error("Name is required");
      return;
    }

    if (telecallerForm.email.trim() === "") {
      toast.error("Email is required");
      return;
    }

    if (telecallerForm.password.trim() === "") {
      toast.error("Password is required");
      return;
    }

    // make network request
    setSubmitting(true);
    api
      .addTelecaller(telecallerForm)
      .then((res) => {
        if (res.data.success) {
          toast.success("Telecaller added successfully");
          setShowAddTelecaller(false);
          setTelecallerForm({
            name: "",
            email: "",
            password: "",
          });
          getTelecallers({ current_page: 1 }, {});
        }
      })
      .catch((err) => {
        toast.error("Error adding telecaller");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const [filters, setFilters] = useState({});

  const [telecallers, setTelecallers] = useState([]);
  const [selectedTelecaller, setSelectedTelecaller] = useState(null);

  const getTelecallers = (pagination, filters) => {
    setLoading(true);

    let localPagination = { ...paginateOptions, ...pagination };
    let localFilters = { ...filters };
    api
      .getTelecallers(localPagination, localFilters)
      .then((res) => {
        if (res.data.success) {
          let localTelecallers = res.data.telecallers;

          setTelecallers(localTelecallers.data);
          delete localTelecallers.data;
          setPaginateOptions(localTelecallers);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const activateDeactivateHandler = (telecaller_id, isActive) => {
    updateIsActiveTelecaller(telecaller_id, isActive);
  };

  const updateIsActiveTelecaller = (telecaller_id, isActive) => {
    setLoading(true);
    api
      .updateIsActiveTelecaller(telecaller_id, isActive)
      .then((res) => {
        if (res.data.success) {
          let localTelecallers = telecallers;
          localTelecallers = localTelecallers.map((telecaller) => {
            if (telecaller.id === telecaller_id) {
              telecaller.isActive = isActive;
            }
            return telecaller;
          });
          setTelecallers(localTelecallers);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeFirstPage = () => {
    setPaginateOptions({ ...paginateOptions, current_page: 1 });
    getTelecallers({ current_page: 1 }, filters);
  };

  const handleChangeLastPage = () => {
    setPaginateOptions({
      ...paginateOptions,
      current_page: paginateOptions.last_page,
    });
    getTelecallers({ current_page: paginateOptions.last_page }, filters);
  };

  const handleChangePrevPage = () => {
    setPaginateOptions({
      ...paginateOptions,
      current_page: paginateOptions.current_page - 1,
    });
    getTelecallers({ current_page: paginateOptions.current_page - 1 }, filters);
  };

  const handleChangeNextPage = () => {
    setPaginateOptions({
      ...paginateOptions,
      current_page: paginateOptions.current_page + 1,
    });
    getTelecallers({ current_page: paginateOptions.current_page + 1 }, filters);
  };

  useEffect(() => {
    getTelecallers({ current_page: 1, per_page: 10 }, filters);
  }, [filters]);

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2}></Stack>
      <Stack direction="column" spacing={2}>
        <TableContainer
          component={Paper}
          sx={{
            height: "100%",
            flexGrow: 1,
            maxHeight: "80vh",
            overflow: "hidden",
          }}
        >
          <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
            <Typography variant="h6" component="div">
              Telecallers
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenAddTelecaller}
            >
              Add Telecaller
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
            <Box></Box>
            <CustomPagination
              last_page={paginateOptions.last_page}
              total={paginateOptions.total}
              current_page={paginateOptions.current_page}
              handleChangeFirstPage={handleChangeFirstPage}
              handleChangeLastPage={handleChangeLastPage}
              handleChangePrevPage={handleChangePrevPage}
              handleChangeNextPage={handleChangeNextPage}
            />
          </Stack>
          {loading && <DebounceSpinner isDebouncing={loading} />}
          <Stack
            direction="column"
            sx={{
              minHeight: "500px",
              maxHeight: "calc(100vh - 35vh)",
              overflowY: "scroll",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Sl no.</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Active</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {telecallers.map((row, index) => (
                  <TableRow
                    key={row.name}
                    onClick={() => setSelectedTelecaller(row)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                      backgroundColor:
                        selectedTelecaller === row ? "rgba(0, 0, 0, 0.04)" : "",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">
                      {row.isActive ? "Yes" : "No"}
                    </TableCell>
                    <TelecallerPopoverCell
                      telecallers={telecallers}
                      setTelecallers={setTelecallers}
                      row={row}
                      setSelectedTelecaller={setSelectedTelecaller}
                      activateDeactivateHandler={activateDeactivateHandler}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        </TableContainer>
      </Stack>
      <Modal
        open={showAddTelecaller}
        onClose={handleCloseAddTelecaller}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            ...style,

            width: {
              md: "30%",
              sm: "90%",
              xs: "90%",
            },
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Add Telecaller
          </Typography>
          <TelecallerAddPage
            submitting={submitting}
            handleAddTelecaller={handleAddTelecaller}
            telecallerForm={telecallerForm}
            setTelecallerForm={setTelecallerForm}
          />
          <Button
            onClick={handleCloseAddTelecaller}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Stack>
  );
};

export default TelecallerManagePage;
