import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import Swal from 'sweetalert2'
import api from '../../../API/api';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


export const BannerPharmacy = () => {

    const [image, setImage] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [banner, setBanner] = useState('');

    const handleInput = (e) => {
        setImagePreview(URL.createObjectURL(e[0]))
        setImage(e[0])
        console.log(e[0], 'dasd');
        // console.log(banner,'banner');
    }

    const handleupload = () => {

        
        const formdata = new FormData();
        if (banner.id) {
            formdata.append('id',banner.id )
        }
        formdata.append('bannerPharmacy', image)
        formdata.append('banner_type', 1)

        api.bannerUpload(formdata).then(res => {
            console.log(res);
            if (res.data.status === 200) {
                fetchbanenr();
                Swal.fire({
                    title: "Success!",
                    text: "Added Succeffully !",
                    icon: "success",
                    confirmButtonText: "Ok",
                });
            }
        }).catch(err => {
            console.log(err.response);
        })
    }

    function fetchbanenr(){
        api.getBanner().then(res => {
            console.log(res, 'banner');
            setBanner(res.data.data)
        }).catch(err => err)
    }
    useEffect(() => {
        fetchbanenr();
    }, [])



    return (
        <div className='row'>
            <div className='col-md-6'>
                <h2 className='mb-5'>Banner Upload of Phamracy</h2>
                <Button
                    component="label"
                    role={undefined}
                    variant="outlined"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}

                >
                    Upload Banner
                    <VisuallyHiddenInput
                        type="file"
                        onChange={(event) => handleInput(event.target.files)}
                        multiple
                    />
                </Button>
                {
                    image &&
                    <Button component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                        onClick={handleupload}
                        className='mx-5'
                    >
                        Submit
                    </Button>
                }
                { imagePreview &&
                    <img id="blah" src={imagePreview} alt="Cover Image Preview" style={{ height: "180px", width: "fit-content" }} />
                }
            </div>
            <div class="col-md-6">
                <h3>Old Banner</h3>
                <img id="blah" src={`${process.env.REACT_APP_BACKEND}assets/banner/pharmacy/${banner.bannerPharmacy}`} alt="Cover Image Preview" style={{ height: "180px", width: "fit-content" }} />
            </div>
        </div>
    )
}