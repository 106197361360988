import "./App.css";
import React, { useEffect, useState, createContext } from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "./PublicPages/homepage";

import Dashboard from "./AdminPages/dashboard/dashboard";
import AddDepartmentPage from "./AdminPages/pages/addDepartment";
import ViewDepartmentPage from "./AdminPages/pages/viewDepartment";
import Doctors from "./components/home/doctor";

import DoctorPage from "./PublicPages/doctorPage";

import AddDoctorPage from "./AdminPages/pages/addDoctor";
import ViewDoctor from "./AdminPages/pages/viewDoctor";
import ViewAppointments from "./AdminPages/pages/appointments";
import DashboardPage from "./AdminPages/pages/Dashboard";
import AddServices from "./AdminPages/pages/addServices";
import ServicePage from "./PublicPages/servicePage";
import CallLeads from "./AdminPages/pages/callLeads";
import Pharmacy from "./AdminPages/pages/viewpharmacy";

import Login from "./AdminPages/form/login";

import api from "./API/api";
import HealthPackageAppointments from "./AdminPages/pages/health-package/health-package.appointments";
import AddHealthPackage from "./AdminPages/pages/health-package/health-package.add-health-package";
import HealthPackages from "./AdminPages/pages/health-package/health-package.view";
import ServiceAppointments from "./AdminPages/pages/service/service.appointments";
import DoctorAppointments from "./AdminPages/pages/doctor/doctor.appointments";
import PharmacyOrders from "./AdminPages/pages/pharmacy/pharmacy.orders";
import Complaints from "./AdminPages/pages/complaint/complaint.index";
import { CenterContextProvider } from "./context/center-context";
import AddDiagnostic from "./AdminPages/pages/diagnostic/diagnostic.add-text";
import Diagnostics from "./AdminPages/pages/diagnostic/diagnostic.index";
import DiagnosticAppointments from "./AdminPages/pages/diagnostic/diagnostic.appointments";
import DoctorTimeSchedule from "./AdminPages/pages/doctor/doctor.time-schedule";
import { BannerPharmacy } from "./AdminPages/pages/pharmacy/bannerForm";
import { BannerHealthPackage } from "./AdminPages/pages/health-package/add.banner";
import { BannerDiagnostics } from "./AdminPages/pages/diagnostic/add.banner";
import NumberListPage from "./AdminPages/pages/number/number-list.page";
import { BannerHomePage } from "./AdminPages/form/homebanner";
import Testimonials from "./AdminPages/pages/testimonials/list";

// Blogs
import Blogs from "./AdminPages/pages/blogs/list";
import TelecallerAddPage from "./AdminPages/pages/telecaller/telecaller-add.page";
import TelecallerManagePage from "./AdminPages/pages/telecaller/telecaller-manage.page";
import AdminManagePage from "./AdminPages/pages/admin/admin-manage.page";
import ProfileIndexPage from "./AdminPages/pages/profile/profile.index";

function App() {
  useEffect(() => {
    visitor();
  }, []);

  const visitor = () => {
    api.visitor().then((res) => {
      console.log("welcome");
    });
  };

  const [user, setUser] = useState("");
  useEffect(() => {
    if (sessionStorage.getItem("user")) {
      setUser("admin");
    } else {
      setUser("public");
    }
    setUser("admin");
  }, [user]);

  return (
    <>
      {/* <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/department/:dept*" element={<DoctorPage />} />
        <Route path="/service/:slug" element={<ServicePage />} />
        </Routes> */}

      {/* <Routes>
        <Route path='/admin' element={<Dashboard />} />
        </Routes> */}
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
      {user === "admin" && (
        <CenterContextProvider>
          <Routes>
            {/* admin  */}
            <Route path="/admin" element={<Dashboard />}>
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="addDepartment" element={<AddDepartmentPage />} />
              <Route path="viewDepartment" element={<ViewDepartmentPage />} />
              <Route path="addAdminDoctor" element={<AddDoctorPage />} />
              <Route path="viewDoctor" element={<ViewDoctor />} />
              {/* <Route path="AddServices" element={<AddServices />} /> */}
              <Route path="appointments" element={<ViewAppointments />} />
              <Route path="CallLeads" element={<CallLeads />} />
              <Route path="Pharmacy" element={<Pharmacy />} />
              <Route path="BannerPharmacy" element={<BannerPharmacy />} />
              <Route path="BannerDiagnostics" element={<BannerDiagnostics />} />
              <Route path="BannerHomePage" element={<BannerHomePage />} />
              <Route path="Testimonials" element={<Testimonials />} />
              <Route path="Blogs" element={<Blogs />} />

              {/* Doctor */}
              <Route
                path="doctor/appointments"
                element={<DoctorAppointments />}
              />

              <Route
                path="doctor/time-schedule"
                element={<DoctorTimeSchedule />}
              />

              {/* Services */}
              <Route
                path="service/appointments"
                element={<ServiceAppointments />}
              />
              <Route path="service/add" element={<AddServices />} />

              {/* Health Package */}
              <Route path="health-package/view" element={<HealthPackages />} />
              <Route path="health-package/add" element={<AddHealthPackage />} />
              <Route
                path="BannerHealthPackage"
                element={<BannerHealthPackage />}
              />
              <Route
                path="health-package/appointments"
                element={<HealthPackageAppointments />}
              />

              {/* Pharmacy */}
              <Route path="pharmacy/orders" element={<PharmacyOrders />} />

              {/* Complaints */}
              <Route
                path="complaints/user-complaints"
                element={<Complaints />}
              />

              {/* Diagnostic */}
              <Route path="diagnostic/add" element={<AddDiagnostic />} />
              <Route path="diagnostic/all" element={<Diagnostics />} />
              <Route
                path="diagnostic/appointments"
                element={<DiagnosticAppointments />}
              />

              {/* NUmbers */}
              <Route path="numbers" element={<NumberListPage />} />

              {/* Telecaller Management */}
              <Route path="telecaller/add" element={<TelecallerAddPage />} />
              <Route
                path="telecaller/manage"
                element={<TelecallerManagePage />}
              />

              {/* Admin Management */}
              <Route path="admin/manage" element={<AdminManagePage />} />
            </Route>
            {/* Profile Management for Current User */}
            <Route path="/profile" element={<Dashboard />}>
              <Route path="" element={<ProfileIndexPage />} />
            </Route>
          </Routes>
        </CenterContextProvider>
      )}
    </>
  );
}

export default App;
