import {
  Box,
  Button,
  IconButton,
  Modal,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Popover,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StatusHistoryTable from "./status-history-table";
import { useEffect, useState } from "react";
import api from "../../../../API/api";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import useDebounce from "../../../../hooks/use-debounce";

const style = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const StatusHistoryModal = ({ open, handleClose, data }) => {
  const [paginateOptions, setPaginateOptions] = useState({
    current_page: 1,
    per_page: 10,
    total: 0,
    last_page: 1,
  });

  console.log("what about here", data);

  const [statusHistory, setStatusHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAppointmentHistory = (page, per_page) => {
    setLoading(true);
    api
      .doctorAppointmentHistory({
        current_page: page,
        per_page,
        appointment_id: data.id, // data.appointment_id should exists
        // otherwise why would this modal be even open at the first place
      })
      .then((res) => {
        if (res.data.success) {
          let localResponseData = res.data.status_history;
          setStatusHistory(localResponseData.data);
          delete localResponseData.data;
          setPaginateOptions(localResponseData);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePage = (page) => {
    setPaginateOptions({ ...paginateOptions, current_page: page });
    debouncedGetAppointmentHistory(page, paginateOptions.per_page);
  };

  const handlePerPage = (per_page) => {
    setPaginateOptions({ ...paginateOptions, per_page });
    debouncedGetAppointmentHistory(paginateOptions.current_page, per_page);
  };

  const handleFirstPage = () => {
    setPaginateOptions({ ...paginateOptions, current_page: 1 });
    debouncedGetAppointmentHistory(1, paginateOptions.per_page);
  };

  const handleLastPage = () => {
    setPaginateOptions({
      ...paginateOptions,
      current_page: paginateOptions.last_page,
    });
    debouncedGetAppointmentHistory(
      paginateOptions.last_page,
      paginateOptions.per_page
    );
  };

  const handleNextPage = () => {
    setPaginateOptions({
      ...paginateOptions,
      current_page: paginateOptions.current_page + 1,
    });
    debouncedGetAppointmentHistory(
      paginateOptions.current_page + 1,
      paginateOptions.per_page
    );
  };

  const handlePreviousPage = () => {
    setPaginateOptions({
      ...paginateOptions,
      current_page: paginateOptions.current_page - 1,
    });
    debouncedGetAppointmentHistory(
      paginateOptions.current_page - 1,
      paginateOptions.per_page
    );
  };
  const [debouncedGetAppointmentHistory] = useDebounce(
    getAppointmentHistory,
    500
  );

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      getAppointmentHistory(
        paginateOptions.current_page,
        paginateOptions.per_page
      );
    }
  }, [data]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          ...style,
          width: {
            md: "80%",
            xs: "90%",
          },
          height: {
            md: "80%",
            xs: "90%",
          },
        }}
      >
        <IconButton
          sx={{ position: "absolute", top: 0, right: 0 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6">Status Change History</Typography>
        <Stack
          direction={"column"}
          spacing={2}
          flex={1}
          overflow={"auto"}
          position={"relative"}
        >
          {loading && (
            <Stack
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                bgcolor: "rgba(0, 0, 0, 0.5)",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1000,
                borderRadius: 2,
              }}
            >
              <CircularProgress />
            </Stack>
          )}
          {!loading && statusHistory.length === 0 && (
            <Typography>No data found</Typography>
          )}
          {!loading && statusHistory.length > 0 && (
            <StatusHistoryTable
              handleChangePage={handleChangePage}
              handlePerPage={handlePerPage}
              paginateOptions={paginateOptions}
              loading={loading}
              statusHistory={statusHistory}
            />
          )}
        </Stack>
        <Stack
          mt={2}
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Paper>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <IconButton
                onClick={handleFirstPage}
                disabled={paginateOptions.current_page === 1}
              >
                <FirstPageIcon />
              </IconButton>
              <IconButton
                onClick={handlePreviousPage}
                disabled={paginateOptions.current_page === 1}
              >
                <KeyboardArrowLeft />
              </IconButton>
              <Typography>{paginateOptions.current_page}</Typography>
              <Typography>of</Typography>
              <Typography>{paginateOptions.last_page}</Typography>
              <IconButton
                onClick={handleNextPage}
                disabled={
                  paginateOptions.current_page === paginateOptions.last_page
                }
              >
                <KeyboardArrowRight />
              </IconButton>
              <IconButton
                onClick={handleLastPage}
                disabled={
                  paginateOptions.current_page === paginateOptions.last_page
                }
              >
                <LastPageIcon />
              </IconButton>
            </Stack>
          </Paper>
        </Stack>
        {/* <Button onClick={handleClose} variant="contained" sx={{ mt: 2 }}>
          Close
        </Button> */}
      </Box>
    </Modal>
  );
};

export default StatusHistoryModal;
