import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function ResponsiveDialog({setModalOpen,modalOpen,form,title,cancelButtonText,submitButtonText, size,submitFunction}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(size ? size : 'lg'));

  const handleClickOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  // const handleDelete = ()=> {

  // }

  return (
    <React.Fragment>
      <Dialog
        maxWidth={fullScreen}
        open={modalOpen}
        // onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
         {form}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            {cancelButtonText ? cancelButtonText : 'cancel'}
          </Button>
          <Button onClick={submitFunction ? submitFunction : handleClose} autoFocus>
          {submitButtonText ? submitButtonText : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}