import { MenuItem, TextField } from "@mui/material";

const TextFieldTableHead = ({ title, type, options, value, onChange }) => {
  return (
    <TextField
      select={type === "select"}
      label={title}
      value={value}
      onChange={onChange}
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "white",
          },
          "&:hover fieldset": {
            borderColor: "white",
          },
          "&.Mui-focused fieldset": {
            borderColor: "white",
          },
          color: "white",
        },
        "& .MuiInputLabel-root": {
          color: "white",
        },
        "& .MuiSelect-root": {
          color: "white",
        },
        "& .MuiSelect-icon": {
          color: "white",
        },
        "& .MuiMenuItem-root": {
          color: "white",
        },
        minWidth: 150,
        color: "white",
      }}
      InputLabelProps={{
        style: { color: "white" },
      }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              bgcolor: "rgba(0, 0, 0, 0.8)",
              "& .MuiMenuItem-root": {
                color: "white",
              },
            },
          },
        },
      }}
    >
      {type === "select" &&
        options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default TextFieldTableHead;
