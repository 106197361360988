import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import axios from "axios";
import api from "../../API/api";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddDepartmentPage = ({ editData, setModalOpen }) => {
  const navigate = useNavigate();

  const [coverpreview, setCoverpreview] = useState();
  const [bannerpreview, setBannerpreview] = useState();
  const [cover, setCover] = useState();
  const [banner, setBanner] = useState();
  const [centers, setCenters] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState("");

  const [name, setName] = useState({
    department: "",
    cover: "",
    description: "",
  });

  const handleInput = (e) => {
    setName({
      ...name,
      [e.target.name]: e.target.value,
    });
  };
  const handleCenter = (e) => {
    console.log(e.target.value);
    setSelectedCenter(e.target.value);
  };

  const handleCoverImg = (e) => {
    setCover(e.target.files[0]);
    setCoverpreview(URL.createObjectURL(e.target.files[0]));
  };
  const handleBannerImg = (e) => {
    setBanner(e.target.files[0]);
    setBannerpreview(URL.createObjectURL(e.target.files[0]));
  };

  const toastError = (message) => {
    toast.error(message, {
      position: "top-center",
    });
  };

  const handleSubmit = () => {
    console.log("=>> remember me", name);

    if (!name.department || name.department.trim().length === 0) {
      toastError("Department name is required!");
      return;
    }

    if (!name.description || name.description.trim().length === 0) {
      toastError("Department description is required!");
      return;
    }

    if (!selectedCenter) {
      toastError("Center is required!");
      return;
    }

    if (!cover) {
      toastError("Department cover is required!");
      return;
    }

    if (!banner) {
      toastError("Department banner is required!");
      return;
    }

    const postData = new FormData();
    postData.append("department", name.department);
    postData.append("description", name.description);
    postData.append("cover", cover);
    postData.append("banner", banner);
    postData.append("center_id", selectedCenter);

    api
      .postDepartments(postData)
      .then((res) => {
        console.log(res);
        if (setModalOpen) {
          setModalOpen(false);
        }
        Swal.fire({
          title: "Success!",
          text: "Added Succeffully !",
          icon: "success",
          confirmButtonText: "Ok",
        });

        if (!editData) {
          navigate("/admin/viewDepartment");
        }
      })
      .catch((err) => {
        if (err.response.data.message) {
          toastError(err.response.data.message);
        } else {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong!",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
  };

  const handleUpdate = () => {
    console.log("=>>", name);

    const postData = new FormData();
    postData.append("department_id", editData.id);
    if (name.department) {
      postData.append("department", name.department);
    }
    if (name.description) {
      postData.append("description", name.description);
    }
    if (cover) {
      postData.append("cover", cover);
    }
    if (banner) {
      postData.append("banner", banner);
    }
    if (selectedCenter) {
      postData.append("center_id", selectedCenter);
    }
    console.log(cover, banner, selectedCenter, name, "get detaisl");

    // return
    api
      .updateDepartments(postData)
      .then((res) => {
        console.log(res);
        setModalOpen(false);
        Swal.fire({
          title: "Success!",
          text: "Added Succeffully !",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.status === 422) {
          let errorMessages = "";
          Object.keys(err.response.data.errors).forEach((field) => {
            err.response.data.errors[field].forEach((message) => {
              errorMessages += `${message} ,`;
            });
          });

          // Swal.fire({'Error', errorMessages, 'error'});
          Swal.fire({
            title: "Success!",
            text: `${errorMessages}`,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
  };

  const centerFetch = () => {
    api
      .centerFetch()
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          setCenters(res.data.centers);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    centerFetch();
  }, []);
  useEffect(() => {
    if (editData) {
      setName(editData);
    }
    // console.log(editData);
    // setCover(editData?.cover)
    // setBanner(editData?.banner)
    setBannerpreview(editData?.banner);
  }, [editData]);

  return (
    <section>
      <div className="col-md-12">
        <div className="p-2 border">
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1 },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="row">
              <div className="col-md-6">
                <label htmlFor="">Add Center:</label>
                {/* <TextField id="standard-basic" label="Add Center" variant="outlined" size="small" className="form-control" name='Center' onChange={handleInput} value={name?.department} /> */}
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Center</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={age}
                    label="Age"
                    onChange={handleCenter}
                  >
                    {centers?.data?.map((o) => (
                      <MenuItem value={o.id}>{o.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-6">
                <label htmlFor="">Department name:</label>
                <TextField
                  id="standard-basic"
                  label="Add Department"
                  variant="outlined"
                  size="small"
                  className="form-control"
                  name="department"
                  onChange={handleInput}
                  value={name?.department}
                />
              </div>
            </div>

            <label htmlFor="">Department Description:</label>
            <textarea
              className="form-control"
              id=""
              rows="4"
              name="description"
              onChange={handleInput}
              value={name?.description}
            >
              {name?.description}
            </textarea>

            <br />

            <div className="col-md-12 form-group my-3">
              <label htmlFor="">Department Icon/Image</label>
              <input
                type="file"
                className="form-control"
                name="cover"
                id="imgInp"
                onChange={handleCoverImg}
              />
            </div>
            {coverpreview && (
              <div className="col-md-12 form-group">
                <label>Preview</label> <br />
                <div
                  className="border text-center p-3"
                  style={{ height: "auto", alignItems: "center" }}
                >
                  <img
                    id="blah"
                    src={coverpreview}
                    alt="Cover Image Preview"
                    style={{ height: "180px", width: "fit-content" }}
                  />
                </div>
              </div>
            )}

            <div className="col-md-12 form-group my-3">
              <label htmlFor="">Department Banner</label>
              <input
                type="file"
                className="form-control"
                name="banner"
                id="imgInp"
                onChange={handleBannerImg}
              />
            </div>
            {bannerpreview && (
              <div className="col-md-12 form-group">
                <label>Preview</label> <br />
                <div
                  className="border text-center p-3"
                  style={{ height: "auto", alignItems: "center" }}
                >
                  <img
                    id="blah"
                    src={bannerpreview}
                    alt="Cover Image Preview"
                    style={{ height: "180px", width: "fit-content" }}
                  />
                </div>
              </div>
            )}
            <div className="text-center my-4">
              {editData ? (
                <Button variant="contained" onClick={handleUpdate}>
                  Update
                </Button>
              ) : (
                <Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
              )}
            </div>
          </Box>
        </div>
      </div>
    </section>
  );
};

export default AddDepartmentPage;
