import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Swal from "sweetalert2";
import axios from "axios";
import api from "../../API/api";
import JoditEditor from "jodit-react";
import { Paper } from "@mui/material";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCenterContext } from "../../context/center-context";
import { useNavigate } from "react-router-dom";

const AddDoctorPage = (props) => {
  const navigate = useNavigate();

  const [coverpreview, setCoverpreview] = useState();
  const [cover, setCover] = useState();
  const [centers, setCenters] = useState([]);
  const [centerId, setCenterId] = useState(null);
  const centerss = useCenterContext();

  console.log(centerss, "centerss");

  // for all department getting from api
  const [department, setDepartment] = useState();

  const [doctorName, setDoctorName] = useState({
    id: "",
    name: "",
    specialization: "",
    department: "",
    qualification: "",
    achievements: "",
    image: "",
  });

  const [description, setDescription] = useState("");

  useEffect(() => {
    if (props.data) {
      console.log(props.data);
      setDoctorName(props.data);
      setDescription(props.data.description);
    } else {
      setDoctorName({});
    }

    // viewDepartment();
    console.log(props, "iSEdit");
  }, []);

  const viewDepartment = (center_id) => {
    console.log(center_id, "center_id");

    api
      .getDepartmentsByCenter(center_id)
      .then((res) => {
        console.log("===>>>>", res);
        setDepartment(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const handleChangeCenter = (e) => {
    setCenterId(e.target.value);
    viewDepartment(e.target.value);
  };

  const handleInput = (e) => {
    setDoctorName({
      ...doctorName,
      [e.target.name]: e.target.value,
    });
  };

  const handleCoverImg = (e) => {
    setCover(e.target.files[0]);
    setCoverpreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = () => {
    console.log("=>>", cover);
    console.log("=>>", description);
    console.log("=>>", doctorName);
    // return;
    if (doctorName.name === null) {
      toast.error("doctor name cannot empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }
    if (doctorName.specialization === null) {
      toast.error("specialization cannot empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }
    if (doctorName.department === null) {
      toast.error("department cannot empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }
    if (doctorName.qualification === null) {
      toast.error("qualification cannot empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }
    if (description === null) {
      toast.error("description cannot empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    if (centerId === null) {
      toast.error("Center cannot empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      return;
    }
    const postData = new FormData();
    postData.append("doctor_id", doctorName.id);
    postData.append("name", doctorName.name);
    postData.append("specialization", doctorName.specialization);

    postData.append("qualification", doctorName.qualification);
    postData.append("achievements", doctorName.achievements);
    postData.append("description", description);
    postData.append("center_id", centerId);
    if (doctorName.department.length !== 0) {
      postData.append("department_id", doctorName.department);
    }
    if (cover !== undefined) {
      postData.append("image", cover);
    }

    api
      .addDoctor(postData)
      .then((res) => {
        console.log(res, "success");

        if (props.handleClose) {
          Swal.fire({
            title: "Success!",
            text: "Added Succeffully !",
            icon: "success",
            confirmButtonText: "Ok",
          });
          props.handleClose();
        } else {
          Swal.fire({
            title: "Success!",
            text: "Added Succeffully !",
            icon: "success",
            confirmButtonText: "Ok",
          });
          navigate("/admin/viewDoctor");
        }

        // window.location.reload();
      })
      .catch((err) => {
        console.log(err.response, "dasbjd");
        if (err.response.data.errors) {
          toast.error(`${err.response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else {
          toast.error(`Something went wrong`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      });
  };

  const updateDoctor = () => {
    // for edit
    const postData2 = new FormData();
    if (doctorName.id) {
      postData2.append("doctor_id", doctorName.id);
    }
    if (doctorName.name) {
      postData2.append("name", doctorName.name);
    }
    if (doctorName.specialization) {
      postData2.append("specialization", doctorName.specialization);
    }
    if (doctorName.qualification) {
      postData2.append("qualification", doctorName.qualification);
    }
    if (doctorName.achievements) {
      postData2.append("achievements", doctorName.achievements);
    }
    if (description) {
      postData2.append("description", description);
    }
    if (doctorName.department) {
      postData2.append("department_id", doctorName.department);
    }
    if (cover !== undefined) {
      postData2.append("image", cover);
    }
    // for edit

    // console.log(props.isEdit, 'props.isEdit')
    for (const value of postData2.values()) {
      console.log(value);
    }
    // return;

    api
      .updateDoctor(postData2)
      .then((res) => {
        console.log(res, "success");
        props.handleClose();
        Swal.fire({
          title: "Success!",
          text: "Added Succeffully !",
          icon: "success",
          confirmButtonText: "Ok",
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log(err.response, "dasbjd");
        if (err.response.data.errors) {
          toast.error(`${err.response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      });
  };

  const handleDetails = (e) => {
    setDescription(e);
  };

  // jodit editor
  const editor = useRef(null);

  const config = {
    uploader: {
      insertImageAsBase64URI: true,
    },
    height: 420,
    triggerChangeEvent: true,
    readonly: false,
  };

  const Jodit = () => {
    return useMemo(
      () => (
        <JoditEditor
          ref={editor}
          config={config}
          value={description}
          onChange={handleDetails}
        />
      ),
      [department]
    );
  };

  const centerFetch = () => {
    api
      .centerFetch()
      .then((res) => {
        console.log(res.data, "centers");
        if (res.data.success) {
          setCenters(res.data.centers);
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    centerFetch();
  }, []);

  return (
    <section>
      {/* <ToastContainer /> */}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="p- border row">
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1 },
                }}
                noValidate
                autoComplete="off"
                className="row"
              >
                <div className="row">
                  <div className="col-md-6 my-2">
                    <TextField
                      id="standard-basic"
                      label="Doctor's Name"
                      variant="outlined"
                      size="small"
                      className="form-control"
                      name="name"
                      onChange={handleInput}
                      value={doctorName.name}
                      required
                    />
                  </div>
                  <div className="col-md-6 my-2">
                    <TextField
                      id="standard-basic"
                      label="Specialization"
                      variant="outlined"
                      size="small"
                      className="form-control col-6"
                      name="specialization"
                      onChange={handleInput}
                      value={doctorName.specialization}
                      required
                    />
                  </div>
                  <div className="col-md-6 my-2">
                    <FormControl fullWidth size="small" variant="outlined">
                      <InputLabel id="demo-select-small-label">
                        Select Center{" "}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={doctorName.center_id}
                        label="Select Center"
                        onChange={handleChangeCenter}
                        name="center_id"
                      >
                        {centers?.data?.map((dept) => (
                          <MenuItem value={dept.id}>{dept.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-6 my-2">
                    <FormControl fullWidth size="small" variant="outlined">
                      <InputLabel id="demo-select-small-label">
                        Select Department{" "}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={doctorName.department}
                        label="Select Department"
                        onChange={handleInput}
                        name="department"
                      >
                        {department &&
                          department?.data?.map((dept) => (
                            <MenuItem value={dept.id}>
                              {dept.department}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-12">
                    <TextField
                      id="standard-basic"
                      label="Qualification"
                      variant="outlined"
                      size="small"
                      className="form-control my-2"
                      name="qualification"
                      onChange={handleInput}
                      value={doctorName.qualification}
                      required
                    />
                    <TextField
                      id="standard-basic"
                      label="Achievement"
                      variant="outlined"
                      size="small"
                      className="form-control my-2"
                      name="achievements"
                      onChange={handleInput}
                      value={doctorName.achievements}
                      required
                    />
                  </div>

                  <label htmlFor="">Description</label>
                  {/* <textarea name="description" id="" cols="30" className='form-control' rows="3" onChange={handleInput} >{doctorName.description}</textarea> */}

                  {Jodit()}

                  <div className="col-md-12 form-group my-3">
                    <input
                      type="file"
                      className="form-control"
                      name="cover"
                      id="imgInp"
                      onChange={handleCoverImg}
                    />
                  </div>
                  {coverpreview && (
                    <div className="col-md-12 form-group">
                      <label>Preview</label> <br />
                      <div
                        className="border text-center p-3"
                        style={{ height: "auto", alignItems: "center" }}
                      >
                        <img
                          id="blah"
                          src={coverpreview}
                          alt="Cover Image Preview"
                          style={{ height: "180px", width: "fit-content" }}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="text-center my-2">
                  {props.isEdit ? (
                    <Button variant="contained" onClick={updateDoctor}>
                      Update
                    </Button>
                  ) : (
                    <Button variant="contained" onClick={handleSubmit}>
                      Submit
                    </Button>
                  )}
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddDoctorPage;
