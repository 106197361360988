import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AddDoctorPage from "../pages/addDoctor";

// import JoditEditor from "jodit-react";
import Swal from "sweetalert2";
import axios from "axios";
import api from "../../API/api";
import { useCenterContext } from "../../context/center-context";
import { toast } from "react-toastify";
import JoditEditorCustom from "../../components/common/jodit-editor";
import JoditEditor from "jodit-react";

const AddServiceModal = (props) => {
  const { centers } = useCenterContext();

  const [formValues, setFormValues] = useState({
    id: "",
    service: "",
    shortDetails: "",
    center: "",
  });

  useEffect(() => {
    if (props.data) {
      let center = props.data.centers[0].id;
      let tempData = props.data;
      tempData.service_id = props.data.id;
      tempData.center = center;
      setFormValues(tempData);
      console.log("props.data", props.data);
      setDescription(props.data.description);
    }
  }, []);

  const [icon, setIcon] = useState();
  const [banner, setBanner] = useState();
  const [description, setDescription] = useState();
  const [clicked, setClicked] = useState(false);

  const handleInput = (e, field) => {
    setFormValues({
      ...formValues,
      [field]: e.target.value,
    });
  };

  const handleIcon = (e) => {
    setIcon(e.target.files[0]);
  };
  const handleBanner = (e) => {
    setBanner(e.target.files[0]);
  };

  // jodit editor

  const handleDetails = (e) => {
    setDescription(e);
  };

  const editor = useRef(null);

  const config = {
    uploader: {
      insertImageAsBase64URI: true,
    },
    height: 420,
    triggerChangeEvent: true,
    readonly: false,
  };

  const handleSelect = (e) => {
    console.log("the e is ", e, "the values is ", e.target.value);
  };

  // const Jodit = () => {
  //   return useMemo(
  //     () => (
  //       <JoditEditor
  //         ref={editor}
  //         config={config}
  //         // value={description}
  //         onChange={(e) => handleDetails(e)}
  //       />
  //     ),
  //     [formValues]
  //   );
  // };

  const handleSubmit = () => {
    // setClicked(true);

    // validate the form data
    if (formValues.service === "" || !formValues.service) {
      toast.error("Please enter a service name");
      return;
    }
    if (formValues.center === "" || !formValues.center) {
      toast.error("Please select a center");
      return;
    }

    if (icon === "") {
      toast.error("Please upload a service icon");
      return;
    }
    if (banner === "") {
      toast.error("Please upload a service banner");
      return;
    }

    if (description === "" || !description) {
      toast.error("Please enter a service description");
      return;
    }

    if (formValues.shortDetails === "" || !formValues.shortDetails) {
      toast.error("Please enter a service short details");
      return;
    }
    const postData = new FormData();

    postData.append("id", formValues.id);
    postData.append("service", formValues.service);
    postData.append("icon", icon);
    postData.append("description", description);
    postData.append("shortDetails", formValues.shortDetails);
    postData.append("banner", banner);
    postData.append("center_id", formValues.center);

    if (formValues.center === "") {
      toast.error("Please select a center");
      return;
    }

    api
      .postService(postData)
      .then((res) => {
        console.log(res);
        setClicked(false);
        props.handleClose();
        props.getServices();

        Swal.fire({
          title: "Success!",
          text: "Added Succeffully !",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response.data.errors) {
          Object.keys(err.response.data.errors).forEach((errorKeys) => {
            err.response.data.errors[errorKeys].forEach((errorMessage) => {
              toast.error(errorMessage);
            });
          });
        }
      });
  };

  const handleUpdate = () => {
    setClicked(true);
    const postData = new FormData();

    Object.keys(formValues).forEach((key) => {
      postData.append(key, formValues[key]);
    });

    if (formValues.center) {
      postData.append("center_ids[0]", formValues.center);
    }

    if (formValues.icon) {
      if (formValues.icon === props.data.icon) {
        postData.delete("icon");
      }
    }
    if (formValues.banner) {
      if (formValues.banner === props.data.banner) {
        postData.delete("banner");
      }
    }

    if (formValues.id) postData.append("id", formValues.id);
    if (formValues.service) postData.append("service", formValues.service);
    if (icon) postData.append("icon", icon);
    if (description) postData.append("description", description);
    if (formValues.shortDetails)
      postData.append("shortDetails", formValues.shortDetails);
    if (banner) postData.append("banner", banner);
    if (formValues.center) postData.append("center_id", formValues.center);

    let newPostData = new FormData();

    // Log FormData contents
    for (let [key, value] of postData.entries()) {
      if (value != "null") {
        newPostData.append(key, value);
      }
    }

    api
      .updateService(newPostData)
      .then((res) => {
        console.log(res);
        toast.success("Updated Successfully");
        setClicked(false);
        props.handleClose();
        props.getServices();
      })
      .catch((err) => {
        toast.error("Failed to update");
        console.log(err.response);
        setClicked(false);
      });
  };
  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 mt-3">
            <label htmlFor="">Service Name</label>
            <input
              type="text"
              className="form-control"
              name="service"
              onChange={(e) => handleInput(e, "service")}
              value={formValues.service}
            />
          </div>
          <div className="col-md-6 mt-3">
            <label htmlFor="">Center</label>
            {centers.length > 0 ? (
              <select
                className="form-control"
                name="center"
                onChange={(e) => handleInput(e, "center")}
                value={formValues.center}
              >
                <option value="">Select Center</option>
                {centers.map((center, index) => (
                  <option key={index} value={center.id}>
                    {center.name}
                  </option>
                ))}
              </select>
            ) : (
              <p>Loading...</p>
            )}
          </div>

          <div className="col-12 mt-3">
            <label htmlFor="">Service Icon</label>
            <input
              type="file"
              className="form-control"
              name="icon"
              onChange={handleIcon}
            />
          </div>
          <div className="col-12 mt-3">
            <label htmlFor="">Service Banner</label>
            <input
              type="file"
              className="form-control"
              name="banner"
              onChange={handleBanner}
            />
          </div>

          <div className="col-12 mt-3">
            <label htmlFor="">Short Details</label>
            <textarea
              name="shortDetails"
              id=""
              cols="30"
              rows="3"
              className="form-control"
              onChange={(e) => handleInput(e, "shortDetails")}
              value={formValues.shortDetails}
            >
              {" "}
            </textarea>
          </div>

          <div className="col-12 mt-3">
            <label htmlFor="">Description</label>
            <JoditEditorCustom value={description} onChange={handleDetails} />
          </div>

          <div className="col-12 mt-3 text-center">
            {props.data && (
              <Button
                variant="contained"
                disabled={clicked ? true : false}
                onClick={handleUpdate}
              >
                Update Service
              </Button>
            )}
            {!props.data && (
              <Button
                variant="contained"
                disabled={clicked ? true : false}
                onClick={handleSubmit}
              >
                Save Service
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddServiceModal;
