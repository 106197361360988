import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import AddDepartmentPage from "./addDepartment";
import CustomizedMenus from "../../components/tools/menu";
import api from "../../API/api";
import ResponsiveDialog from "../../components/modal/prompt";

import { TableHeadTextFieldGenerator } from "../../components/table/tablehead-textfield-generator";
import { useCenterContext } from "../../context/center-context";
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#016b8b',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ViewDepartmentPage = () => {
  const { centers } = useCenterContext();
  const [department, setDepartment] = useState();
  const [getRow, setGetRow] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [modalData, setModalData] = useState();

  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const [filters, setFilters] = useState({});

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    viewDepartment(newPage + 1, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    viewDepartment(page, event.target.value);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleDeleteRestoreDepartment = async (row) => {
    try {
      let response = null;

      if (getRow.isActive) {
        response = await api.deleteDepartment(getRow.id);
      } else {
        response = await api.restoreDepartment(getRow.id);
      }

      if (!response) {
        toast.error("Something went wrong");
        setDeleteOpen(false);
        return;
      }

      if (response.data.success) {
        setGetRow({ ...getRow, isActive: !getRow.isActive });
        toast.success(
          getRow.isActive
            ? "Department deleted successfully"
            : "Department restored successfully"
        );

        setDeleteOpen(false);
      }
      viewDepartment();
    } catch (error) {
      toast.error("Something went wrong");
      setDeleteOpen(false);
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - department?.length) : 0;

  useEffect(() => {
    viewDepartment();
  }, []);

  const viewDepartment = (newPage, perPage, filters) => {
    api
      .getDepartments(newPage, perPage, filters)
      .then((res) => {
        console.log(res, "departments");
        setDepartment(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleEdit = (e) => {
    console.log("edit", getRow && getRow);
    setModalOpen(true);
    setModalData(getRow);
  };
  const handleDelete = () => {
    console.log("delete", getRow && getRow);
    setDeleteOpen(true);
  };

  const menuButtons = [
    {
      id: 1,
      name: "edit",
      onClick: handleEdit,
      icon: "EditIcon",
    },
    {
      id: 2,
      name: "delete",
      onClick: handleDelete,
      icon: "DeleteIcon",
    },
  ];

  useEffect(() => {
    setPage(0);
    setRowsPerPage(10);
    let localFilters = { ...filters };
    Object.keys(localFilters).forEach((key) => {
      if (localFilters[key] === "") {
        delete localFilters[key];
      }
    });
    viewDepartment(1, 10, localFilters);
  }, [filters]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <div>
          <h3>Departments</h3>
        </div>
        <div>
          <button
            onClick={() => setModalOpen(true)}
            className="btn btn-small btn-primary"
          >
            Add Department
          </button>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableHeadTextFieldGenerator
                heads={[
                  {
                    label: "ID",

                    align: "center",
                  },
                  {
                    label: "Departments",
                  },
                  {
                    label: "Center",
                    align: "center",
                    type: "select",
                    isFilter: true,
                    onChange: (e) => {
                      setFilters({ ...filters, center_id: e.target.value });
                    },
                    options: [
                      {
                        label: "All",
                        value: "",
                      },
                      ...centers.map((center) => ({
                        label: center.name,
                        value: center.id,
                      })),
                    ],
                  },
                  {
                    label: "Cover",
                  },
                  {
                    label: "Banner",
                  },
                  {
                    label: "Status",
                  },
                  {
                    label: "Action",
                  },
                ]}
                Wrapper={StyledTableCell}
                align="center"
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {department &&
              department.data.map((row) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {row.id}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.department}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.center?.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <img
                      src={`${process.env.REACT_APP_BACKEND}assets/department/${row.cover}`}
                      height="100px"
                    />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <img
                      src={`${process.env.REACT_APP_BACKEND}assets/department/banner/${row.banner}`}
                      height="100px"
                    />{" "}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.isActive ? "Active" : "Inactive"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomizedMenus
                      row={row}
                      menuButtons={[
                        {
                          id: 1,
                          name: "edit",
                          onClick: handleEdit,
                          icon: "EditIcon",
                        },
                        {
                          id: 2,
                          name: row.isActive ? "Deactivate" : "Activate",
                          onClick: handleDelete,
                          icon: row.isActive ? "DeleteIcon" : "ActiveIcon",
                        },
                      ]}
                      setGetRow={setGetRow}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: (dense ? 33 : 53) * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={department?.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
      <ResponsiveDialog
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        form={
          <AddDepartmentPage editData={getRow} setModalOpen={setModalOpen} />
        }
        title={"Add/Edit Departments"}
        cancelButtonText={"cancel"}
        submitButtonText={"submit"}
      />
      <ResponsiveDialog
        modalOpen={deleteOpen}
        setModalOpen={setDeleteOpen}
        form={<p></p>}
        title={
          getRow
            ? getRow.isActive
              ? "Are You Sure to Deactivate"
              : "Are You Sure to Activate"
            : ""
        }
        cancelButtonText={"cancel"}
        submitButtonText={
          getRow ? (getRow.isActive ? "Deactivate" : "Activate") : ""
        }
        submitFunction={handleDeleteRestoreDepartment}
        size="lg"
      />
    </>
  );
};

export default ViewDepartmentPage;
