import React, { useState, useEffect, useRef } from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Swal from 'sweetalert2'

import api from '../../API/api';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { Badge, TablePagination } from '@mui/material';
import CustomizedMenus from '../../components/tools/menu';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#016b8b',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));


const ViewAppointments = () => {

    const tableRef = useRef(null);
    const [getRow, setGetRow] = useState();
    const [appoinments, setAppoinments] = useState();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const viewDoctors = (newPage) => {
        api.GetAppointmentsForDoctor(newPage, rowsPerPage)
            .then((res) => {
                console.log("===>>>>", res);
                // setDepartment(res.data.data)
                setAppoinments(res.data.data)
            })
            .catch((err) => {
                console.log(err.response);
            })
    }

    useEffect(() => {
        viewDoctors()
    }, [])


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        viewDoctors(page, event.target.value)
    }
    const handleStatus = (status) => {
        console.log(getRow, 'getRow');
        const payload = {
            "doctor_id": getRow.doctors[0].id,
            "appointment_id": getRow.id,
            "status": status,
        }
        api.doctorAppointmentStatus(payload).then(res => {
            console.log(res);
            viewDoctors()
            if (res.data.success) {
                Swal.fire({
                    title: 'Success!',
                    text: 'Status Updated',
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
            }
        }).catch(err => {
            console.log(err);
        })
    }

    const menuButtons = [
        {
            id: 1,
            name: "Confirmed",
            onClick: () => handleStatus('confirmed'),
        },
        {
            id: 2,
            name: "Cancelled",
            onClick: () => handleStatus('cancelled'),
        },
        {
            id: 3,
            name: "Completed",
            onClick: () => handleStatus('completed'),
        },
    ];
    const getStatusColor = (status) => {
        switch (status) {
            case 'requested':
                return 'primary';
            case 'confirmed':
                return 'success';
            case 'completed':
                return 'warning';
            case 'cancelled':
                return 'error';
            default:
                return 'default';
        }
    };

    return (
        <>
            {/* <div>
                <p>Select Filters</p>
                <div className="row">
                    <div className='col-md-3 my-2'>
                        <FormControl fullWidth size="small" variant='outlined'>
                            <InputLabel id="demo-select-small-label">Select Center </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={{}}
                                label="Select Department"
                                onChange={handleChangeCenter}
                                name='department'
                            >
                                {centers?.data?.map((dept) => (
                                    <MenuItem value={dept.id}>{dept.name}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-md-3 my-2'>
                        <FormControl fullWidth size="small" variant='outlined'>
                            <InputLabel id="demo-select-small-label">Select Department </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={{}}
                                label="Select Department"
                                onChange={handleChangeDepartment}
                                name='department'
                            >
                                {department?.data?.map((dept) => (
                                    <MenuItem value={dept.id}>{dept.department}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </div>
                    <div className='col-md-3 my-2'>
                        <FormControl fullWidth size="small" variant='outlined'>
                            <InputLabel id="demo-select-small-label">Select Doctor </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={{}}
                                label="Select Department"
                                onChange={handleChangeCenter}
                                name='department'
                            >
                                {department?.data?.map((dept) => (
                                    <MenuItem value={dept.id}>{dept.department}</MenuItem>
                                ))}

                            </Select>
                        </FormControl>
                    </div>
                </div>
            </div> */}
            <DownloadTableExcel
                filename="Appointment List"
                sheet="Apollo"
                currentTableRef={tableRef.current}
            >
                <button className='btn btn-warning btn-sm'> Export excel </button>
            </DownloadTableExcel>

            <TableContainer component={Paper} ref={tableRef}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell align="center">Patient Name</StyledTableCell>
                            <StyledTableCell align="center">Phone No.</StyledTableCell>
                            <StyledTableCell align="center">Gender</StyledTableCell>
                            <StyledTableCell align="center">Age</StyledTableCell>
                            <StyledTableCell align="center">City</StyledTableCell>
                            <StyledTableCell align="center">Appoin. Date</StyledTableCell>
                            <StyledTableCell align="center">For Doctor</StyledTableCell>
                            <StyledTableCell align="center">Status</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {appoinments && appoinments?.data?.map((row, index) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell component="th" scope="row">
                                    {index + 1}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.name}</StyledTableCell>
                                <StyledTableCell align="center">{row.phone}</StyledTableCell>
                                <StyledTableCell align="center">{row.gender}</StyledTableCell>
                                <StyledTableCell align="center">{row.age}</StyledTableCell>
                                <StyledTableCell align="center">{row.city}</StyledTableCell>
                                <StyledTableCell align="center">
                                    {/* {new Date(row.date).getDate()
                                        + ", " +
                                        new Date(row.date).toLocaleString("default", { month: "long", })
                                        + "-" +
                                        new Date(row.date).getFullYear()} */}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                   {row.doctors[0].name}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <Badge
                                        badgeContent={row.status}
                                        color={getStatusColor(row.status)}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    ></Badge>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                    <CustomizedMenus
                                        row={row}
                                        menuButtons={menuButtons}
                                        setGetRow={setGetRow}
                                        btnText={'update status'}
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={appoinments?.total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </>
    )
}


export default ViewAppointments;

