import { useState, useEffect } from "react";
import Calender from "../calender";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import api from "../../API/api";
import { toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResponsiveDialog from "../modal/prompt";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, MenuItem, Stack, TextField } from "@mui/material";
import { getTime } from "date-fns";
import { data } from "jquery";
import { useCenterContext } from "../../context/center-context";

export function formatDate(date) {
  let dates = new Date(date);
  let day = dates.getDate();
  let month = dates.getMonth() + 1; // Months are zero-indexed in JavaScript
  let year = dates.getFullYear();
  return `${year}-${month}-${day}`;
}
export default function DoctorTimmings({ selectedDoctor }) {
  // center
  const { centers } = useCenterContext();

  const [selectedCenter, setSelectedCenter] = useState(centers[0].id);

  const localDate = new Date();
  const [loader, setLoader] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteValue, setDeletevalue] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [date, setDate] = useState({
    data: [localDate.getDate()],
    month: [localDate.getMonth() + 1],
    year: [localDate.getFullYear()],
  });
  const [handleTimes, setHandleTimes] = useState("");
  const [timeSchedule, setTimeSchedule] = useState(null);
  const [listAvailability, setListAvailability] = useState([]);
  const [time, setTime] = useState({
    start: "",
    end: "",
  });
  const [timeInput, setTimeInput] = useState({
    start_time: "",
    end_time: "",
  }); // State for current input
  const [timesList, setTimeList] = useState([]);

  const [timing, setTiming] = useState({
    month: "",
    month_number: "",
    day_number: "",
    day_name: "",
    start_time: "",
    end_time: "",
    year: "",
  });

  // this is for storing the on month change date in calendar
  const [activeDate, setActiveDate] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleDateSelect = (data, month, year) => {
    console.log(data, month, year, "range selecttion");
    // return;
    // setDate(1);
    setTiming({
      ...timing,
      // "month": data.toLocaleString('default', { month: 'long' }),
      month_number: month + 1,
      day_number: month,
      // "day_name": data.toLocaleString('default', { weekday: 'long' }),
      start_time: "",
      end_time: "",
      year: year,
    });
    console.log(data, month, year, "data month year");

    setDate({
      data: data,
      month: month + 1,
      year: year,
    });

    // setDate(formatDate(data));
    setCurrentDate(data);
  };

  const handleDeleteScheduleModal = (row) => {
    setDeleteOpen(true);
    setDeletevalue(row);
  };
  const handleDeleteSchedule = (data) => {
    let year = formatDate(new Date().toISOString());
    api
      .deleteDoctorTimeScheduleHour({
        doctor_id: selectedDoctor.id,
        hour_id: data.id,
        month_number: timeSchedule.month_number,
        day_number: deleteValue.day_number,
        year: timeSchedule.year,
        center_id: selectedCenter,
      })
      .then((res) => {
        console.log(res, deleteValue && deleteValue, "doctor delete tme");
        if (res.data.success) {
          toast.success(`${res.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          setDeleteOpen(false);
          // setCurrentDate(new Date())
          // setDeletevalue(deleteValue.filter(item => item.id !== res.data.data.id))

          //loop through the timeSchedule and remove the selected hour_schedule
          // find the day Schedule
          const daySchedule = timeSchedule.day_schedules.find(
            (day) => day.id === deleteValue.id
          );

          // now remove the hour_schedule from the day_schedule
          const hourTimeSchedule = daySchedule.hour_schedules.filter(
            (hour) => hour.id !== data.id
          );

          let localNewTimeSchedule = {
            ...timeSchedule,
          };

          localNewTimeSchedule.day_schedules = timeSchedule.day_schedules.map(
            (day) => {
              if (day.id === deleteValue.id) {
                return {
                  ...day,
                  hour_schedules: hourTimeSchedule,
                };
              }
              return day;
            }
          );
          setTimeSchedule({
            ...localNewTimeSchedule,
          });

          // setHandleTimes(
          //   deleteValue.filter((item) => item.id !== res.data.data.id)
          // );
        }
      })
      .catch((err) =>
        toast.error(`Something Went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        })
      );
  };

  const handleTime = (e) => {
    setHandleTimes(e.target.value);
    console.log(e.target.value, "e.target.value");

    setDate(date);
    setTiming({
      ...timing,
      [e.target.name]: e.target.value.replaceAll("/", ":"),
    });
  };
  //  uncomment this to use this function

  // React.useEffect(() => {
  //     let year = new Date().getFullYear();
  //     api.getDoctorTimeAvailability(selectedDoctor.id, currentDate.getMonth() + 1, currentDate.getDate(), 1, year).then(
  //         res => {
  //             console.log(res.data.data, 'appoint 2times')
  //             // let getArray = []
  //             // let getAvailability = res.data.data.map(e => {
  //             //     if (e.is_available === 1) {
  //             //         getArray.push(e.month.month_number + '-' + e.day_number + '-' + new Date(e.month.year).getFullYear())
  //             //     }
  //             // })
  //             // console.log(getArray, 'getArray');
  //             // setListAvailability(getArray)
  //         }

  //     ).catch(err => console.log(err, 'appoint times err'))
  // }, [])

  // React.useEffect(() => {
  //     setLoader(true);
  //     let year = new Date().getFullYear();
  //     console.log(selectedDoctor.id, currentDate?.getMonth() + 1, currentDate.getDate(), 1, year, 'false acuusiosion');

  //     return;
  //     api.getDoctorTimeSchedule(selectedDoctor.id, currentDate.getMonth() + 1, currentDate.getDate(), 1, year).then(
  //         res => {
  //             console.log(res, 'get time schedule')
  //             setTimeSchedule(res.data.data)
  //             setLoader(false);
  //             toast.success(`${res.data.message}`, {
  //                 position: "top-right",
  //                 autoClose: 5000,
  //                 hideProgressBar: false,
  //                 closeOnClick: true,
  //                 pauseOnHover: true,
  //                 draggable: true,
  //                 progress: undefined,
  //                 theme: "light",
  //                 transition: Bounce,
  //             })
  //         }
  //     ).catch(err => {
  //         console.log(err)
  //         setTimeSchedule(null)
  //         // toast.error(`${err.response.data.message}`, {
  //         //     position: "top-right",
  //         //     autoClose: 5000,
  //         //     hideProgressBar: false,
  //         //     closeOnClick: true,
  //         //     pauseOnHover: true,
  //         //     draggable: true,
  //         //     progress: undefined,
  //         //     theme: "light",
  //         //     transition: Bounce,
  //         // })
  //         setLoader(false);
  //     })

  // }, [selectedDoctor, currentDate])
  //  uncomment this to use this function

  const addTimmingMore = () => {
    console.log(timing, "timing");

    if (timing.start_time && timing.end_time) {
      let conflict = false;
      timesList.forEach((time, index) => {
        if (time.start_time === timing.start_time) {
          conflict = true;
          alert("Start time already exists");
          return;
        }

        if (time.end_time === timing.end_time) {
          conflict = true;
          alert("End time already exists");
          return;
        }

        // need to check if the start time is greater than end time
        if (
          time.start_time <= timing.end_time &&
          time.end_time >= timing.start_time
        ) {
          conflict = true;
          alert("Time range is overlapping");
          return;
        }

        // need to check if the start time and end time fall outside the range of already set times
        // for start time first
        // if (time.start_time < timing.start_time) {
      });

      if (conflict) return;

      // Add the current time range to the times array
      setTimeList([...timesList, { ...timing }]);

      setTimeInput({ start_time: "", end_time: "" }); // Reset input fields
    } else {
      alert("Please select both start and end times"); // Validation for empty fields
    }
  };

  const addTimming = () => {
    // console.log(timing, date && date, 'gettiming');

    // return;

    // convert timesList into startTime array and endTime array
    const startTimes = timesList.map((time) => time.start_time);
    const endTimes = timesList.map((time) => time.end_time);

    if (startTimes.length !== endTimes.length) {
      toast.error("Start and end times  mismatch");
      return;
    }

    if (startTimes.length === 0 || endTimes.length === 0) {
      toast.error("Start and end times mismatch");
      return;
    }

    // convert into proper object for sending to backend
    const localTiming = {
      month_number: timing.month_number,
      year: timing.year,
      days: date.data,
      start_times: startTimes,
      end_times: endTimes,
      center_id: selectedCenter,
    };
    setLoader(true);
    api
      .addDoctorTimeScheduleMultipleDays(selectedDoctor.id, localTiming)
      .then((res) => {
        // console.log(res, 'doctor timming ');
        if (res.data.success) {
          toast.success(`${res.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          // let savedHourSchedule = res.data.hour_schedule;
          getTimeScheduleByFilter({
            doctor_id: selectedDoctor.id,
            month_number: timing.month_number,
            year: timing.year,
          });

          setCurrentDate(new Date(date));
          setTimeList([]);

          setDate({
            data: [localDate.getDate()],
            month: [localDate.getMonth() + 1],
            year: [localDate.getFullYear()],
          });
        }
        setLoader(false);
      })
      .catch((err) =>
        toast.error(`${err.response.data.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        })
      );
  };

  const getTimeSchedule = async () => {
    try {
      const res = await api.getDoctorAllTimeSchedule({
        doctor_id: selectedDoctor.id,
        month_number: localDate.getMonth() + 1,
        year: localDate.getFullYear(),
        center_id: selectedCenter,
      });
      console.log("the res is here", res.data);
      setTimeSchedule(res.data.data.month_schedules[0]);
    } catch (error) {
      setTimeSchedule(null);
      console.log("the error is here", error);
    }
  };

  const getTimeScheduleByFilter = async (filter) => {
    try {
      const res = await api.getDoctorAllTimeSchedule({
        doctor_id: selectedDoctor.id,
        month_number: filter.month_number,
        year: filter.year,
        center_id: filter.center_id,
      });
      console.log("the res is here", res.data);
      setTimeSchedule(res.data.data.month_schedules[0]);
    } catch (error) {
      console.log("the error is here", error);
    }
  };

  const handleMonthChange = (active) => {
    let date = new Date(active);
    let month = date.getMonth();
    let year = date.getFullYear();
    console.log(date, "does ths work");
    getTimeScheduleByFilter({
      doctor_id: selectedDoctor.id,
      month_number: month + 1,
      year,
      center_id: selectedCenter,
    });
  };

  useEffect(() => {
    getTimeSchedule();
  }, [selectedCenter]);

  return (
    <div className="row  justify-content-between">
      <div className="col-6 d-flex flex-column text-center">
        <Chip
          label={"(1) Click on calender date to add time"}
          variant="outlined"
          color="success"
        />
        <Calender
          onMonthChange={handleMonthChange}
          date={date}
          time={time}
          setTime={setTime}
          handleDateSelect={handleDateSelect}
          listAvailability={listAvailability}
        />

        <div className="highlight">
          <i className="fa fa-circle2 "></i>
          Available dates{" "}
          <span>
            Showing for Center:{" "}
            {centers.find((center) => center.id === selectedCenter)?.name}
          </span>
        </div>
      </div>
      <div className="col-6">
        {date && (
          <div className="text-center w-100">
            <Chip
              label={`(2) Select time for date: days:[${
                date.data.length > 1
                  ? `${date.data[0]} to ${date.data[date.data.length - 1]}`
                  : date.data[0]
              }], month:${date.month}, year:${date.year}`}
              variant="outlined"
              color="success"
              className="w-100"
            />

            <Stack
              direction="row"
              spacing={2}
              justifyContent="flex-end"
              paddingTop={2}
            >
              <TextField
                label="Center"
                select
                value={selectedCenter}
                onChange={(e) => setSelectedCenter(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {centers.map((center) => (
                  <MenuItem value={center.id}>{center.name}</MenuItem>
                ))}
              </TextField>
              <Button
                sx={{
                  justifySelf: "flex-end",
                }}
                variant="contained"
                color="primary"
                onClick={addTimming}
              >
                Add time
              </Button>
            </Stack>

            <div className="row my-5">
              <div className="col-6">
                <label>Start time</label>
                <input
                  type="time"
                  className="form-control"
                  name="start_time"
                  onChange={(e) => handleTime(e)}
                />
              </div>
              <div className="col-6">
                <label>End time</label>
                <input
                  type="time"
                  className="form-control"
                  name="end_time"
                  onChange={(e) => handleTime(e)}
                />
              </div>
              <div className="text-center my-3" onClick={addTimmingMore}>
                <button className="btn btn-sm btn-success">Add time</button>
              </div>
            </div>
          </div>
        )}
        <div className="time-pills">
          {timesList &&
            timesList?.map((obj, index) => (
              <div key={index}>
                <div key={index} className="pill-size">
                  <div>
                    <strong>{index + 1}</strong>
                  </div>
                  <div>
                    <Chip
                      label={`Start Time :${obj.start_time.slice(-9)}`}
                      size="small"
                      color="success"
                    />
                    <br />
                    <Chip
                      label={`End Time   :${obj.end_time.slice(-9)}`}
                      size="small"
                      color="warning"
                      className="mt-1"
                    />
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="position-relative">
        {loader && (
          <div
            style={{
              position: "absolute",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              cursor: "no-drop",
            }}
          >
            <CircularProgress color="success" />
          </div>
        )}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>sl</StyledTableCell>
                <StyledTableCell align="right" width="100px">
                  Date
                </StyledTableCell>
                <StyledTableCell align="center">Times</StyledTableCell>
                <StyledTableCell align="right">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {timeSchedule !== null ? (
                timeSchedule &&
                timeSchedule.day_schedules &&
                timeSchedule.day_schedules.map((row) => {
                  return (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row?.day_name} {row?.day_number} of{" "}
                        {timeSchedule?.month_name}, {timeSchedule?.year}
                      </StyledTableCell>
                      <StyledTableCell>
                        <div>
                          <div className="time-pills">
                            {row.hour_schedules.map((obj, index) => (
                              <div key={index} className="pill-size">
                                <div>
                                  <strong>{index + 1}</strong>
                                </div>
                                <div>
                                  <Chip
                                    label={`Start Time :${obj.start_time.slice(
                                      -9
                                    )}`}
                                    size="small"
                                    color="success"
                                  />
                                  <br />
                                  <Chip
                                    label={`End Time   :${obj.end_time.slice(
                                      -9
                                    )}`}
                                    size="small"
                                    color="warning"
                                    className="mt-1"
                                  />
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <Button
                          variant="outlined"
                          size="small"
                          color="success"
                          onClick={() => handleDeleteScheduleModal(row)}
                        >
                          Delete
                        </Button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })
              ) : (
                <StyledTableRow key={1}>
                  <StyledTableCell align="right"></StyledTableCell>
                  <StyledTableCell align="right">
                    <p>No time scheule found</p>
                  </StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                  <StyledTableCell align="right"></StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <ResponsiveDialog
        modalOpen={deleteOpen}
        setModalOpen={setDeleteOpen}
        form={
          <div className="col-md-10">
            {deleteValue?.hour_schedules?.map((item, index) => (
              <div key={index} className="pill-size mb-4">
                <div className="d-flex gap-5">
                  <strong>{index + 1}</strong>
                  <div>
                    <Chip
                      label={`start Time :${item.start_time.slice(-9)}`}
                      size="small"
                      color="success"
                    />
                    <br />
                    <Chip
                      label={`End Time   :${item.end_time.slice(-9)}`}
                      size="small"
                      color="warning"
                      className="mt-1"
                    />
                  </div>
                  <div>
                    <button
                      className="btn btn-small btn-danger"
                      onClick={() => handleDeleteSchedule(item)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
        title={"Are You Sure to Delete"}
        cancelButtonText={"cancel"}
        submitButtonText={"  "}
        size="lg"
      />
    </div>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#016b8b',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
