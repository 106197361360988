import { Button, IconButton, Popover, Stack, TableCell } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import TelecallerEditModal from "./telecaller-edit-modal";

const TelecallerPopoverCell = ({
  row,
  setSelectedTelecaller,
  activateDeactivateHandler,
  setTelecallers,
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  const handleEditModalOpen = () => {
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    handleEditModalOpen();
  };

  const open = Boolean(anchorEl);
  const id = open ? "telecaller-action-popover" : undefined;

  return (
    <TableCell align="right">
      <IconButton
        onClick={(event) => {
          handlePopoverClick(event);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Stack
          direction="column"
          spacingX={2}
          spacingY={1}
          sx={{ px: 1, py: 1, gap: 1 }}
        >
          {/* <Button variant="contained" color="primary">
        Edit
      </Button> */}
          <Button
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() =>
              activateDeactivateHandler(row.id, row.isActive ? 0 : 1)
            }
          >
            {row.isActive ? "Deactivate" : "Activate"}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={handleEditClick}
          >
            Edit
          </Button>
        </Stack>
      </Popover>
      <TelecallerEditModal
        setTelecallers={setTelecallers}
        data={row}
        open={editModalOpen}
        handleClose={handleEditModalClose}
      />
    </TableCell>
  );
};

export default TelecallerPopoverCell;
