import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AddDoctorPage from "../pages/addDoctor";
import EditIcon from "@mui/icons-material/Edit";

import Switch from "@mui/material/Switch";

import AddServiceModal from "../panel/modalService";
import api from "../../API/api";
import { useCenterContext } from "../../context/center-context";

const AddServices = () => {
  const { centers } = useCenterContext();

  const [open, setOpen] = React.useState(false);
  const [row, setRow] = useState();

  const handleClickOpen = (data) => {
    setOpen(true);
    if (data) {
      setRow(data);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setRow(null);
  };

  const [services, setService] = useState([]);

  useEffect(() => {
    getServices();
  }, []);

  const getServices = () => {
    api
      .getServices()
      .then((res) => {
        console.log(res);
        setService(res.data.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleCheck = (id) => {
    api
      .deleteService(id)
      .then((res) => {
        console.log(res);
        getServices();
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  return (
    <>
      <div>
        <div>
          <div className="m-2">
            <Button
              variant="contained"
              size="small"
              color="warning"
              onClick={() => handleClickOpen(null)}
            >
              + Add Service
            </Button>
          </div>
        </div>
        <div>
          <AddServiceTable
            services={services}
            handleCheck={handleCheck}
            handleClickOpen={handleClickOpen}
          />
        </div>
      </div>

      <React.Fragment>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Services</DialogTitle>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <Box noValidate component="form">
              <AddServiceModal
                data={row}
                handleClose={handleClose}
                getServices={getServices}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </>
  );
};

const AddServiceTable = ({ services, handleCheck, handleClickOpen }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              <b> SL No</b>
            </TableCell>
            <TableCell>
              <b> Service</b>
            </TableCell>
            <TableCell>
              <b> Details</b>
            </TableCell>
            <TableCell align="center">
              <b> Action</b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services &&
            services.map((row, index) => (
              <TableRow
                key={row.service}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {index + 1}
                </TableCell>
                <TableCell align="">{row.service}</TableCell>
                <TableCell align="">
                  {row.shortDetails && row.shortDetails.slice(0, 70)}...
                </TableCell>
                <TableCell align="center">
                  {" "}
                  <Switch
                    checked={row.isActive ? true : false}
                    onClick={() => handleCheck(row.id)}
                  />
                  <Button onClick={() => handleClickOpen(row)}>
                    <EditIcon />
                  </Button>{" "}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AddServices;
