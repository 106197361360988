import axios from "axios";
// import AuthUser from './token';

// const {token} = AuthUser();

const tokenString = sessionStorage.getItem("token");
const token = tokenString ? String(tokenString.slice(1, -1)) : "";
// console.log(token, "tokenString");

const API = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/",
  baseURL: process.env.REACT_APP_MAIN_API,

  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});
export const APIFile = axios.create({
  // baseURL: "http://127.0.0.1:8000/api/",
  baseURL: process.env.REACT_APP_MAIN_API,

  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  },
});
export const APIdelete = axios.delete({
  // baseURL: "http://127.0.0.1:8000/api/",
  baseURL: process.env.REACT_APP_MAIN_API,

  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  },
});

export default API;
