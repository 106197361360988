import React, { useState, useEffect } from "react";

import api from "../../API/api";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ToastContainer, toast, Bounce } from "react-toastify";
import TablePagination from "@mui/material/TablePagination";

import Button from "@mui/material/Button";
import AddDoctorPage from "../pages/addDoctor";
import ResponsiveDialog from "../../components/modal/prompt";
import DoctorTimmings from "../../components/form/doctorTimmings";
import CustomizedMenus from "../../components/tools/menu";
import { TableHeadTextFieldGenerator } from "../../components/table/tablehead-textfield-generator";
import Swal from "sweetalert2";
import { useCenterContext } from "../../context/center-context";
import useDebounce from "../../hooks/use-debounce";
import DebounceSpinner from "../../components/common/debounce-spinner";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#016b8b',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ViewDoctor = () => {
  const { centers } = useCenterContext();

  const [doctor, setDoctor] = useState();
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [open, setOpen] = React.useState(false);
  const [openTimmings, setOpenTimmings] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [isEdit, setIsEdit] = useState(false);
  const [getRow, setGetRow] = useState();

  const [filters, setFilters] = useState({});

  const viewDoctors = () => {
    api
      .getDeptDoct(page, rowsPerPage)
      .then((res) => {
        console.log(res);
        setDoctor(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleChangePage = (event, newPage) => {
    console.log(
      newPage === page ? newPage + 1 : newPage > page ? newPage + 1 : newPage,
      "event"
    );
    setPage(
      newPage === page ? newPage + 1 : newPage > page ? newPage + 1 : newPage
    );
    // viewDoctors();
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteDoctor = () => {
    console.log(getRow, "getRow");
    api
      .deleteDoctor(getRow.id)
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          setDeleteOpen(false);
          viewDoctors();
        } else {
          setDeleteOpen(false);
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleClickOpen = (edit = false) => {
    setOpen(true);
    setSelectedDoctor(getRow);
    setIsEdit(edit);
  };
  const handleClickOpenTimmings = (data) => {
    setOpenTimmings(true);
    setSelectedDoctor(getRow);
  };
  const handleDeleteDoctor = (row) => setDeleteOpen(true);

  const hightLight = () => {
    const data = {
      doctor_id: getRow.id,
      isHighlighted: getRow.isHighlighted === 1 ? 0 : 1,
    };
    api
      .updateDoctor(data)
      .then((res) => {
        console.log(res, "success");
        Swal.fire({
          title: "Success!",
          text: "Shown on homepage !",
          icon: "success",
          confirmButtonText: "Ok",
        });
        viewDoctors();
      })
      .catch((err) => {
        console.log(err.response, "dasbjd");
      });
  };

  const handleClose = () => setOpen(false);
  const menuButtons = [
    {
      id: 1,
      name: "edit",
      onClick: () => handleClickOpen(true),
      icon: "EditIcon",
    },
    {
      id: 2,
      name: "Add timming",
      onClick: handleClickOpenTimmings,
      icon: "AccessTime",
    },
    {
      id: 3,
      name: "Show on homepage/remove",
      onClick: hightLight,
      icon: "AccessTime",
    },
    {
      id: 4,
      name: "delete",
      onClick: handleDeleteDoctor,
      icon: "DeleteIcon",
    },
  ];

  const viewDoctorsFilters = async (page, rowsPerPage, filters) => {
    let localFilters = { ...filters };
    Object.keys(localFilters).forEach((key) => {
      if (localFilters[key] === "") {
        delete localFilters[key];
      }
    });

    try {
      const response = await api.getDeptDoct(page, rowsPerPage, localFilters);

      setDoctor(response.data.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  const [debouncedViewDoctorsFilters, isDebouncing] = useDebounce(
    viewDoctorsFilters,
    500
  );

  const [debouncedViewDoctors, isDebouncingViewDoctors] = useDebounce(
    viewDoctors,
    500
  );

  useEffect(() => {
    debouncedViewDoctorsFilters(0, rowsPerPage, filters);
  }, [filters]);

  useEffect(() => {
    debouncedViewDoctors();
  }, [page, rowsPerPage]);

  useEffect(() => {
    console.log("lets see how many times i change");
  }, [doctor]);

  return (
    <>
      <ToastContainer limit={3} />
      <div className="row justify-content-between">
        <div className="col-6">
          <h4>Add Doctors </h4>
        </div>
        <div className="col-6 text-end"> 
          <Button
            variant="contained"
            size="small"
            color="warning"
            className="blinking-button "
            onClick={() => handleClickOpen(false)}
          >
            + Add Doctor
          </Button>
        </div>
      </div>

      <TableContainer component={Paper}>
        {isDebouncing && <DebounceSpinner isDebouncing={isDebouncing} />}
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableHeadTextFieldGenerator
                heads={[
                  {
                    label: "ID",
                    isFilter: false,
                  },
                  {
                    label: "Name",
                    isFilter: true,
                    type: "text",
                    onChange: (e) => {
                      setFilters({ ...filters, name: e.target.value });
                    },
                  },
                  {
                    label: "Specialization",
                    isFilter: false,
                  },
                  {
                    label: "Department",
                    isFilter: false,
                    align: "center",
                  },
                  {
                    label: "Center",
                    isFilter: true,
                    type: "select",
                    value: filters.center_id ? filters.center_id : "",
                    options: [
                      { label: "All", value: "" },
                      ...centers.map((e) => ({
                        label: e.name,
                        value: e.id,
                      })),
                    ],
                    onChange: (e) => {
                      setFilters({ ...filters, center_id: e.target.value });
                    },
                  },
                  {
                    label: "Qualification",
                    isFilter: false,
                  },
                  {
                    label: "Achievements",
                    isFilter: false,
                  },
                  {
                    label: "Image",
                    isFilter: false,
                  },
                  {
                    label: "Action",
                    isFilter: false,
                  },
                ]}
                Wrapper={StyledTableCell}
                align="center"
              ></TableHeadTextFieldGenerator>
            </TableRow>
          </TableHead>

          <TableBody>
            {!isDebouncing &&
              doctor &&
              doctor.data.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.specialization}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.departments.map((e) => e.department + ",")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.centers[0].name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.qualification}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.achievements}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <img
                      src={`${process.env.REACT_APP_BACKEND}assets/doctor/${row.image}`}
                      height="50px"
                    />{" "}
                  </StyledTableCell>

                  {/* <StyledTableCell align="center">
                                    <Button s variant='contained' size='small' color='warning' onClick={() => handleClickOpen(row, true)}>
                                        Edit doctor
                                    </Button>
                                    <Button variant='contained' size='small' color='success' onClick={() => handleClickOpenTimmings(row)}>
                                        Add/edit Timmings
                                    </Button>
                                </StyledTableCell> */}
                  <StyledTableCell align="center">
                    <CustomizedMenus
                      row={row}
                      menuButtons={menuButtons}
                      setGetRow={setGetRow}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={doctor ? doctor.total || 0 : 0}
        rowsPerPage={rowsPerPage}
        page={page === 1 ? 0 : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ResponsiveDialog
        modalOpen={open}
        setModalOpen={setOpen}
        form={
          <AddDoctorPage
            data={selectedDoctor}
            handleClose={handleClose}
            isEdit={isEdit}
          />
        }
        title={"Add Doctor"}
        cancelButtonText={"cancel"}
        submitButtonText={" "}
        size="lg"
      />
      <ResponsiveDialog
        modalOpen={openTimmings}
        setModalOpen={setOpenTimmings}
        form={<DoctorTimmings selectedDoctor={selectedDoctor} />}
        title={"Add Doctor Timmings"}
        cancelButtonText={"cancel"}
        submitButtonText={" "}
        size="lg"
      />
      <ResponsiveDialog
        modalOpen={deleteOpen}
        setModalOpen={setDeleteOpen}
        form={<p></p>}
        title={"Are You Sure to Delete"}
        cancelButtonText={"cancel"}
        submitButtonText={"Delete"}
        submitFunction={deleteDoctor}
        size="lg"
      />
    </>
  );
};

export default ViewDoctor;
