import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AddTaskIcon from "@mui/icons-material/AddTask";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import BabyChangingStationIcon from "@mui/icons-material/BabyChangingStation";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ApartmentIcon from "@mui/icons-material/Apartment";
import MedicationIcon from "@mui/icons-material/Medication";
import { InputBase, styled, Menu, MenuItem } from "@mui/material";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import LeaderboardSharpIcon from "@mui/icons-material/LeaderboardSharp";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import AdbIcon from "@mui/icons-material/Adb";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AuthUser from "../../API/token";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import { useNavigate, Outlet } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { BannerHealthPackage } from "../pages/health-package/add.banner";
import Add from "@mui/icons-material/Add";
import { Numbers } from "@mui/icons-material";
import Blogs from "../pages/blogs/list";
import { MenuComponent } from "./dashboard_items";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const drawerWidth = 240;

const SearchBar = styled("div")(({ theme }) => ({
  backgroundColor: "#ffffff",
  padding: "0 10px",
  borderRadius: theme.shape.borderRadius,
  width: "40%",
}));

const settings = ["Profile", "Account", "Dashboard", "Logout"];
const pages = ["Dashboard"];

const Dashboard = (props) => {
  const { token, logout, user } = AuthUser();

  console.log("user===>", user);

  const logoutuser = () => {
    // if (token != undefined) {
    //   logout();
    // }
  };
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [open, setOpen] = React.useState(false);
  const [openHealthPackage, setOpenHealthPackage] = React.useState(false);
  const [openService, setOpenService] = React.useState(false);
  const [openBlog, setBlogOpen] = React.useState(false);
  const [openPharmacy, setOpenPharmacy] = React.useState(false);
  const [openComplaints, setOpenComplaints] = React.useState(false);
  const [openDiagnostic, setOpenDiagnostic] = React.useState(false);
  const [openTelecaller, setOpenTelecaller] = React.useState(false);
  const [openAdmin, setOpenAdmin] = React.useState(false);

  const handleAdminClick = () => {
    setOpenAdmin(!openAdmin);
  };

  const handleTelecallerClick = () => {
    setOpenTelecaller(!openTelecaller);
  };

  const handleComplaintsClick = () => {
    setOpenComplaints(!openComplaints);
  };

  const handleDiagnosticClick = () => {
    setOpenDiagnostic(!openDiagnostic);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const handleHealthPackageClick = () => {
    setOpenHealthPackage(!openHealthPackage);
  };

  const handleServiceClick = () => {
    setOpenService(!openService);
  };

  const handlePharmacyClick = () => {
    setOpenPharmacy(!openPharmacy);
  };

  const blogClick = () => {
    setBlogOpen(!openBlog);
  };

  const drawer = (
    <div style={{ backgroundColor: '#def8f6' }} className="dashba">
      <Toolbar>
        <a href="/admin/dashboard">
          {/* <img src="/logo.png" className='img-fluid' /> */}
          <h2 style={{color : '#016b8b'}}>APOLLO</h2>
        </a>
      </Toolbar>
      <Divider />
      <List>
        <ListItem disablePadding onClick={() => navigate("/admin/dashboard")}>
          <ListItemButton>
            <ListItemIcon>
              <LeaderboardSharpIcon />
            </ListItemIcon>
            <ListItemText>Dashboard</ListItemText>
          </ListItemButton>
        </ListItem>

        {/* <ListItem
          disablePadding
          onClick={() => navigate("/admin/appointments")}
        >
          <ListItemButton>
            <ListItemIcon>
              <BookOnlineIcon />
            </ListItemIcon>
            <ListItemText>Appointments</ListItemText>
          </ListItemButton>
        </ListItem> */}
        <MenuComponent
          items={[
            {
              title: "Department",
              icon: <ApartmentIcon />,
              open: open,
              onClick: handleClick,
              role: ["superadmin", "admin"],
              subItems: [
                {
                  title: "Add Department",
                  icon: <AddTaskIcon />,
                  onClick: () => navigate("/admin/addDepartment"),
                  role: ["superadmin", "admin"],
                },
                {
                  title: "View Department",
                  icon: <RemoveRedEyeIcon />,
                  onClick: () => navigate("/admin/viewDepartment"),
                  role: ["superadmin", "admin"],
                },
              ],
            },
            {
              title: "Doctors",
              icon: <BabyChangingStationIcon />,
              open: openBlog,
              onClick: blogClick,
              role: ["superadmin", "admin", "telecaller"],
              subItems: [
                {
                  title: "View Doctors",
                  icon: <RemoveRedEyeIcon />,
                  onClick: () => navigate("/admin/viewDoctor"),
                  role: ["admin", "superadmin"],
                },
                {
                  title: "View Appointments",
                  icon: <RemoveRedEyeIcon />,
                  onClick: () => navigate("/admin/doctor/appointments"),
                  role: ["superadmin", "admin", "telecaller"],
                },
              ],
            },
            {
              title: "Services",
              icon: <RemoveRedEyeIcon />,
              open: openService,
              onClick: handleServiceClick,
              role: ["superadmin", "admin", "telecaller"],
              subItems: [
                {
                  title: "Add Services",
                  icon: <AddIcon />,
                  onClick: () => navigate("/admin/service/add"),
                  role: ["admin", "superadmin"],
                },
                {
                  title: "View Appointments",
                  icon: <RemoveRedEyeIcon />,
                  onClick: () => navigate("/admin/service/appointments"),
                  role: ["superadmin", "admin", "telecaller"],
                },
              ],
            },
            {
              title: "Health Package",
              icon: <HealthAndSafetyIcon />,
              open: openHealthPackage,
              onClick: handleHealthPackageClick,
              role: ["superadmin", "admin", "telecaller"],
              subItems: [
                {
                  title: "View Health Packages",
                  icon: <RemoveRedEyeIcon />,
                  onClick: () => navigate("/admin/health-package/view"),
                  role: ["admin", "superadmin"],
                },
                {
                  title: "Add Health Package",
                  icon: <AddIcon />,
                  onClick: () => navigate("/admin/health-package/add"),
                  role: ["admin", "superadmin"],
                },
                {
                  title: "View Appointments",
                  icon: <RemoveRedEyeIcon />,
                  onClick: () => navigate("/admin/health-package/appointments"),
                  role: ["superadmin", "admin", "telecaller"],
                },
              ],
            },
            {
              title: "Pharmacy",
              icon: <MedicationIcon />,
              open: openPharmacy,
              onClick: handlePharmacyClick,
              role: ["superadmin", "admin", "telecaller"],
              subItems: [
                {
                  title: "View Orders",
                  icon: <RemoveRedEyeIcon />,
                  onClick: () => navigate("/admin/pharmacy/orders"),
                  role: ["superadmin", "admin", "telecaller"],
                },
              ],
            },
            {
              title: "Complaints",
              icon: <ListAltIcon />,
              open: openComplaints,
              onClick: handleComplaintsClick,
              role: ["admin", "superadmin", "telecaller"],
              subItems: [
                {
                  title: "View Complaints",
                  icon: <ListAltIcon />,
                  onClick: () => navigate("/admin/complaints/user-complaints"),
                  role: ["admin", "superadmin", "telecaller"],
                },
              ],
            },
            {
              title: "Diagnostic",
              icon: <LocalHospitalIcon />,
              open: openDiagnostic,
              onClick: handleDiagnosticClick,
              role: ["superadmin", "admin", "telecaller"],
              subItems: [
                {
                  title: "Add Diagnostic",
                  icon: <AddIcon />,
                  onClick: () => navigate("/admin/diagnostic/add"),
                  role: ["admin", "superadmin"],
                },
                {
                  title: "View Diagnostics",
                  icon: <LocalHospitalIcon />,
                  onClick: () => navigate("/admin/diagnostic/all"),
                  role: ["admin", "superadmin"],
                },
                {
                  title: "View Appointments",
                  icon: <RemoveRedEyeIcon />,
                  onClick: () => navigate("/admin/diagnostic/appointments"),
                  role: ["superadmin", "admin", "telecaller"],
                },
              ],
            },
            {
              title: "Admin",
              icon: <SettingsIcon />,
              open: openAdmin,
              onClick: handleAdminClick,
              role: ["superadmin"],
              subItems: [
                {
                  title: "Manage Admin",
                  icon: <RemoveRedEyeIcon />,
                  onClick: () => navigate("/admin/admin/manage"),
                  role: ["superadmin"],
                },
              ],
            },
            {
              title: "Telecaller",
              icon: <BabyChangingStationIcon />,
              open: openTelecaller,
              onClick: handleTelecallerClick,
              role: ["admin", "superadmin"],
              subItems: [
                {
                  title: "Manage Telecallers",
                  icon: <RemoveRedEyeIcon />,
                  onClick: () => navigate("/admin/telecaller/manage"),
                  role: ["admin", "superadmin"],
                },
              ],
            },
            {
              title: "Clinic Numbers",
              icon: <Numbers />,
              onClick: () => navigate("/admin/numbers"),
              role: ["admin", "superadmin"],
            },
            {
              title: "Home Banner",
              icon: <AddIcon />,
              onClick: () => navigate("/admin/BannerHomePage"),
              role: ["admin", "superadmin"],
            },
            {
              title: "Testimonials",
              icon: <LeaderboardSharpIcon />,
              onClick: () => navigate("/admin/Testimonials"),
              role: ["admin", "superadmin"],
            },
            {
              title: "Blogs",
              icon: <LeaderboardSharpIcon />,
              onClick: () => navigate("/admin/blogs"),
              role: ["admin", "superadmin"],
            },
          ]}
          userRole={user.role}
        />
        <Divider />
        {/* Profile */}
        <ListItemButton disablePadding onClick={() => navigate("/profile")}>
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </ListItemButton>
        <Divider />

        {/* End Conversion  */}
        <ListItemButton disablePadding onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </ListItemButton>
        <Divider />
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor : '#016b8b'
        }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              APOLLO 
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{page}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              APOLLO Dashboard
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {/* {pages.map((page) => (
                <Button
                  key={page}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  {page}
                </Button>
              ))} */}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              {/* <Tooltip title="Account">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Apollo" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip> */}
              {/* <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem key={setting} onClick={handleCloseUserMenu}>
                    <Typography textAlign="center">{setting}</Typography>
                  </MenuItem>
                ))}
              </Menu> */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />

        <Outlet />
      </Box>
    </Box>
  );
};

export default Dashboard;
