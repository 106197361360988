import React, { useEffect, useState } from "react";
import {
  Avatar,
  Typography,
  Box,
  Button,
  TextField,
  Input,
  Stack,
  Paper,
  Divider,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import api from "../../../API/api";

const ProfileIndexPage = ({}) => {
  const [name, setName] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [localProfile, setLocalProfile] = useState({});

  const [isUsernameEditing, setIsUsernameEditing] = useState(false);
  const [updating, setUpdating] = useState(false);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUsernameEditDone = () => {
    setIsUsernameEditing(false);
    // if (onSave) {
    //   onSave({ name, imageUrl });
    // }
  };

  const handleUpdate = async () => {
    if (name === "") {
      toast.error("Name cannot be empty");
      return;
    }

    setUpdating(true);
    const formData = new FormData();

    if (name !== "") {
      formData.append("name", name);
    }

    if (imageUrl !== localProfile.image) {
      if (imageUrl !== "" && imageUrl != null && imageUrl !== undefined) {
        const base64Response = await fetch(imageUrl);
        const blob = await base64Response.blob();

        // Create a File object from the blob
        const file = new File([blob], "image.jpg");
        formData.append("image", file);
      }
    }

    formData.append("user_id", localProfile.id);
    console.log(localProfile, "local profile");
    try {
      const result = await api.userUpdate(formData);

      if (result.data.success) {
        toast.success("Profile Updated Successfully");

        const responseUser = result.data.user;
        let envImagePath = `${process.env.REACT_APP_BACKEND_USER}/${responseUser.id}/${responseUser.image}`;
        setLocalProfile({
          ...responseUser,
          image: envImagePath,
        });
        setName(responseUser.name);

        setImageUrl(envImagePath);
      }
    } catch (error) {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something went wrong");
      }
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    try {
      const user = JSON.parse(sessionStorage.getItem("user"));
      setName(user.name);
      let envImagePath = `${process.env.REACT_APP_BACKEND_USER}/${user.id}/${user.image}`;
      if (user.image) {
        let envImagePath = `${process.env.REACT_APP_BACKEND_USER}/${user.id}/${user.image}`;
        setImageUrl(envImagePath);
      }

      setLocalProfile({
        ...user,
        image: envImagePath,
      });
      console.log("user is here", user);
    } catch (error) {}
  }, []);

  return (
    <Stack direction={"column"} flex={1} minHeight={"85vh"}>
      <Stack direction={"row"} spacing={2} flex={1}>
        <Stack direction={"column"} spacing={2} flex={1} minHeight={"100%"}>
          <Paper
            elevation={3}
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              paddingX: 2,
            }}
          >
            <Stack direction={"column"}>
              <Typography variant="h3" padding={0} margin={0}>
                Account
              </Typography>
              <Typography variant="body2" color={"#808080"}>
                Manage your profile here
              </Typography>
            </Stack>

            <Stack direction={"column"} paddingTop={4}>
              <Button variant="ghost">
                <AccountCircleIcon></AccountCircleIcon>
                Profile
              </Button>
            </Stack>
          </Paper>
        </Stack>
        <Stack direction={"column"} spacing={2} flex={4} minHeight={"100%"}>
          <Paper
            elevation={1}
            sx={{
              display: "flex",
              flex: 1,
              paddingX: 2,
              paddingY: 1,
              flexDirection: "column",
            }}
          >
            <Stack>
              <Typography variant="h4">Profile Details</Typography>
            </Stack>
            <Divider sx={{ borderBottomWidth: 4 }} />
            <Stack direction={"column"} spacing={3} mt={2}>
              {/* Avatar upload feature */}
              <Box display="flex" flexDirection="column" alignItems="center">
                <Avatar
                  src={imageUrl}
                  sx={{
                    width: 250,
                    height: 250,
                    marginBottom: 2,
                  }}
                />
                <label htmlFor="avatar-upload">
                  <Input
                    id="avatar-upload"
                    type="file"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                  />
                  <Button variant="contained" component="span">
                    Upload New Avatar
                  </Button>
                </label>
              </Box>

              {/* Username update feature */}
              <Box>
                <TextField
                  label="Username"
                  value={name}
                  onChange={handleNameChange}
                  fullWidth
                  variant="outlined"
                  disabled={!isUsernameEditing}
                />
                {isUsernameEditing ? (
                  <Button
                    onClick={handleUsernameEditDone}
                    variant="contained"
                    sx={{ mt: 2 }}
                  >
                    Done
                  </Button>
                ) : (
                  <Button
                    onClick={() => setIsUsernameEditing(true)}
                    startIcon={<EditIcon />}
                    sx={{ mt: 2 }}
                  >
                    Edit Username
                  </Button>
                )}
              </Box>

              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
              >
                <Button onClick={handleUpdate} variant="contained">
                  Update
                </Button>
              </Box>
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ProfileIndexPage;
