import { createContext, useContext, useEffect, useState } from "react";
import api from "../API/api";

const CenterContext = createContext({
  centers: [],
  addCenter: (center) => {},
});

export const CenterContextProvider = ({ children }) => {
  const [centers, setCenters] = useState([]);

  const centerFetch = () => {
    api.centerFetch().then((res) => {
      if (res.data.success) {
        setCenters(res.data.centers.data);
      }
    });
  };

  useEffect(() => {
    centerFetch();
  }, []);

  const addCenter = (center) => {
    setCenters([...centers, center]);
  };

  return (
    <CenterContext.Provider value={{ centers, addCenter }}>
      {children}
    </CenterContext.Provider>
  );
};

export const useCenterContext = () => {
  return useContext(CenterContext);
};

export default CenterContext;
