import {
  Paper,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  IconButton,
  Select,
  MenuItem,
  Popover,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../API/api";
import { ArrowBack, ArrowForward, Edit, MoreVert } from "@mui/icons-material";
import NumberAddUpdateModal from "./features/number-add-update-modal";

const NumberListPage = () => {
  const [numbers, setNumbers] = useState([]);
  const [selectedNumber, setSelectedNumber] = useState(null);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  // modal
  const [openNumberModal, setOpenNumberModal] = useState(false);

  const handleOpenNumberModal = () => setOpenNumberModal(true);
  const handleCloseNumberModal = () => setOpenNumberModal(false);

  // pop over
  const [anchorElForNumberPopover, setAnchorElForNumberPopover] =
    useState(null);

  const handleClickForNumberPopover = (event) => {
    setAnchorElForNumberPopover(event.currentTarget);
  };

  const handleCloseForNumberPopover = () => {
    setAnchorElForNumberPopover(null);
  };

  const openForNumberPopover = Boolean(anchorElForNumberPopover);
  const idForNumberPopover = openForNumberPopover
    ? "simple-popover-for-number"
    : undefined;

  // Method to fetch numbers (placeholder)
  const fetchNumbers = () => {
    // Fetch numbers from an API or data source
    api
      .getNumbers({ page, perPage })
      .then((res) => {
        console.log(res.data.numbers);
        setNumbers(res.data.numbers.data);
        setTotal(res.data.numbers.total);
        setTotalPages(res.data.numbers.last_page);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePerPage = (event) => {
    setPerPage(event.target.value);
  };

  const onEditClick = (number) => {
    // setSelectedNumber(number);
    handleOpenNumberModal();
  };

  useEffect(() => {
    fetchNumbers();
  }, [page, perPage]);

  return (
    <Stack direction="column" spacing={2}>
      <Typography variant="h4">Numbers</Typography>
      <Paper
        elevation={3}
        sx={{ display: "flex", flexDirection: "column", minHeight: "80vh" }}
      >
        <TableContainer component={Box} sx={{ flexGrow: 1 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>{""}</TableCell>
                {/* Add more headers as needed */}
              </TableRow>
            </TableHead>
            <TableBody>
              {numbers.map((number, index) => (
                <TableRow key={index} onClick={() => setSelectedNumber(number)}>
                  <TableCell>{number.name}</TableCell>
                  <TableCell>{number.contact_number}</TableCell>
                  <TableCell>
                    <IconButton>
                      <MoreVert onClick={handleClickForNumberPopover} />
                    </IconButton>
                    <Popover
                      id={idForNumberPopover}
                      open={openForNumberPopover}
                      anchorEl={anchorElForNumberPopover}
                      onClose={handleCloseForNumberPopover}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left", // Align to the left side of the button
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right", // Adjust transform origin to align properly
                      }}
                    >
                      <Stack padding={2} spacing={2}>
                        <Button onClick={() => onEditClick(number)}>
                          Edit
                        </Button>
                      </Stack>
                    </Popover>
                  </TableCell>
                  {/* Add more cells as needed */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack
            direction="row"
            spacing={2}
            marginLeft={"auto"}
            alignItems="center"
          >
            <IconButton
              onClick={() => handleChangePage(null, page - 1)}
              disabled={page === 1}
            >
              <ArrowBack />
            </IconButton>
            <Typography>{`Page ${page} of ${totalPages}`}</Typography>
            <IconButton
              onClick={() => handleChangePage(null, page + 1)}
              disabled={page === totalPages}
            >
              <ArrowForward />
            </IconButton>
            <Select value={perPage} onChange={handleChangePerPage}>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
          </Stack>
        </Stack>
        <NumberAddUpdateModal
          data={selectedNumber}
          open={openNumberModal}
          handleClose={handleCloseNumberModal}
          fetchNumbers={fetchNumbers}
        />
      </Paper>
    </Stack>
  );
};

export default NumberListPage;
