import API, { APIFile } from "./apiConfig";

export default {
  // getEvents(){
  //     return API.get('/getEvents');
  // },
  // getEventData(id){
  //     return API.get(`/getEventData/${id}`);
  // },
  // updateEvent(data){
  //     return API.post('/updateEvent', {data});
  // },
  // postEvents(formData){
  //     return API.post('/postEvents', {formData});
  // },
  // getEventImg(id){
  //     return API.get(`/getEventImg/${id}`);
  // },
  // getBlog(){
  //     return API.get('/getBlog');
  // },

  getDepartments(newPage, perPage, filters) {
    let page = newPage || 1;
    let per_page = perPage || 10;

    return API.get(`admin/departments?page=${page}&per_page=${per_page}`, {
      params: {
        ...filters,
      },
    });
  },

  // getDepartmentsWithFilters(filters) {
  //   let page = 1;
  //   let per_page = 5;

  //   if (filters) {
  //     if (filters.page) {
  //       page = filters.page;
  //     }
  //     if (filters.per_page) {
  //       per_page = filters.per_page;
  //     }
  //   }
  //   return API.get(`admin/departments?page=${page}&per_page=${per_page}`, {
  //     params: {
  //       ...filters,
  //     },
  //   });
  // },

  getDepartmentsByCenter(center_id) {
    return API.get(`admin/departments?center_id=${center_id}`);
  },

  getDoctors(dept) {
    return API.get(`/getDoctors/${dept}`);
  },
  addDoctor(formData) {
    return APIFile.post("admin/doctor", formData);
  },
  addDoctorTimeSchedule(doctor_id, timeSchedule) {
    return API.post(
      `admin/doctor/time-schedule?doctor_id=${doctor_id}`,
      timeSchedule
    );
  },
  addDoctorTimeScheduleMultipleDays(doctor_id, timeSchedule) {
    return API.post(
      `admin/doctor/time-schedule/multiple-days?doctor_id=${doctor_id}`,
      timeSchedule
    );
  },
  getDoctorTimeSchedule(
    doctor_id,
    month_number = 1,
    day_number = 1,
    only_hour = 1,
    year
  ) {
    return API.get(
      `admin/doctor/time-schedule?doctor_id=${doctor_id}&month_number=${month_number}&day_number=${day_number}&only_hour=${only_hour}&year=${year}`
    );
    // return API.get(`admin/doctor/time-schedule?doctor_id=${doctor_id}&month_number=${month_number}&day_number=${day_number}&only_available=${true}`)
  },

  getDoctorAllTimeSchedule(filters) {
    return API.get(`admin/doctor/time-schedules`, {
      params: {
        ...filters,
      },
    });
  },

  getDoctorTimeAvailability(
    doctor_id,
    month_number = 1,
    day_number = 1,
    only_hour = 1,
    year
  ) {
    return API.get(
      `admin/doctor/time-schedule?doctor_id=${doctor_id}&month_number=${month_number}&day_number=${day_number}&only_available=${true}&year=${year}`
    );
  },
  deleteDoctorTimeSchedule(doctor_id, hour_id, year) {
    return API.delete(
      `admin/doctor/time-schedule?doctor_id=${doctor_id}&hour_id=${hour_id}&year=${year}`
    );
  },

  deleteDoctorTimeScheduleHour(filters) {
    return API.delete(`admin/doctor/time-schedule/hour`, {
      params: {
        ...filters,
      },
    });
  },
  updateDoctor(formData) {
    return APIFile.post("admin/doctor/update", formData);
  },
  deleteDoctor(doctor_id) {
    return API.delete(`admin/doctor?doctor_id=${doctor_id}`);
  },
  getDeptDoct(page, perPage, filters) {
    return API.get(
      `admin/doctors?page=${page === 0 ? 1 : page}&per_page=${perPage}`,
      {
        params: {
          ...filters,
        },
      }
    );
  },
  postAppointmemnt(formData) {
    return API.post("/postAppointmemnt", { formData });
  },
  GetAppointmentsForDoctor(page = 1, per_page = 10, filters) {
    return API.get(
      `/admin/appointments?for_doctor=${1}&page=${page}&per_page=${per_page}`,
      {
        params: {
          ...filters,
        },
      }
    );
  },
  GetAppointmentsForDoctorSingle(type, id) {
    return API.get(`/admin/appointments?for_doctor=${type}&doctor_id=${id}`);
  },
  GetAppointmentsForService(paginateOptions, type, id, filter) {
    let per_page = paginateOptions.per_page || 10;
    let page = paginateOptions.page || 1;

    if (id) {
      return API.get(
        `/admin/appointments?for_service=${type}&service_id=${id}&page=${page}&per_page=${per_page}`,
        {
          params: {
            ...filter,
          },
        }
      );
    } else {
      return API.get(
        `/admin/appointments?for_service=${type}&page=${page}&per_page=${per_page}`,
        {
          params: {
            ...filter,
          },
        }
      );
    }
  },

  UpdateServiceAppointmentStatus(payload) {
    return API.post(
      `/admin/appointment/service/update?service_id=${payload.service_id}&appointment_id=${payload.appointment_id}&status=${payload.status}`
    );
  },
  serviceAppointmentHistory(filters) {
    return API.get(`/admin/appointment/service/status-history`, {
      params: {
        ...filters,
      },
    });
  },

  addHealthPackage(formData) {
    return APIFile.post("admin/health-package", formData);
  },

  updateHealthPackage(formData) {
    return APIFile.post("admin/health-package/update", formData);
  },

  deleteHealthPackage(health_package_id) {
    return API.delete(
      `admin/health-package?health_package_id=${health_package_id}`
    );
  },

  getHealthPackages(paginateOptions, filters) {
    let per_page = paginateOptions.per_page || 5;
    let page = paginateOptions.page || 1;
    return API.get(`admin/health-packages?page=${page}&per_page=${per_page}`, {
      params: {
        ...filters,
      },
    });
  },

  GetAppointmentsForHealthPackage(paginateOptions, type, id, filter) {
    let per_page = paginateOptions.per_page || 10;
    let page = paginateOptions.page || 1;

    if (id) {
      return API.get(
        `/admin/appointments?for_health_package=${type}&health_package_id=${id}&page=${page}&per_page=${per_page}`,
        {
          params: {
            ...filter,
          },
        }
      );
    } else {
      return API.get(
        `/admin/appointments?for_health_package=${type}&page=${page}&per_page=${per_page}`,
        {
          params: {
            ...filter,
          },
        }
      );
    }

    // return API.get(`/admin/appointments?for_health_package=${type}&health_package_id=${id}`);
  },

  UpdateHealthPackageAppointmentStatus(payload) {
    return API.post(
      `/admin/appointment/health-package/update?health_package_id=${
        payload.health_package_id
      }&appointment_id=${payload.appointment_id}&status=${payload.status}${
        payload.remarks ? `&remarks=${payload.remarks}` : ""
      }`
    );
  },

  healthPackageAppointmentHistory(filters) {
    return API.get(`/admin/appointment/health-package/status-history`, {
      params: {
        ...filters,
      },
    });
  },

  // handleappoinetment status
  doctorAppointmentStatus(payload) {
    return API.post(
      `/admin/appointment/doctor/update?doctor_id=${
        payload.doctor_id
      }&appointment_id=${payload.appointment_id}&status=${payload.status}${
        payload.remarks ? `&remarks=${payload.remarks}` : ""
      }`
    );
  },
  doctorAppointmentHistory(filters) {
    return API.get(`/admin/appointment/doctor/status-history`, {
      params: {
        ...filters,
      },
    });
  },

  // get pharmacy orders
  GetPharmacyOrders(paginateOptions, _id, _type, filter) {
    let per_page = paginateOptions.per_page || 5;
    let page = paginateOptions.page || 1;
    return API.get(`/admin/pharmacy/orders?page=${page}&per_page=${per_page}`, {
      params: {
        ...filter,
      },
    });
  },

  // update pharmacy order status
  updatePharmacyOrderStatus(payload) {
    return API.post(
      `/admin/pharmacy/order/update-status?pharmacy_order_id=${
        payload.pharmacy_order_id
      }&status=${payload.status}${
        payload.remarks ? `&remarks=${payload.remarks}` : ""
      }`
    );
  },

  // pharmacy order status records
  pharmacyOrderStatusRecords(filters) {
    return API.get(`/admin/pharmacy/order/status-records`, {
      params: {
        ...filters,
      },
    });
  },

  // complaints
  GetComplaints(paginateOptions, filter) {
    let per_page = paginateOptions.per_page || 5;
    let page = paginateOptions.page || 1;
    return API.get(`/admin/complaints?page=${page}&per_page=${per_page}`, {
      params: {
        ...filter,
      },
    });
  },

  UpdateComplaintsStatus(payload) {
    return API.post(
      `/admin/complaint/update?complaint_id=${payload.complaint_id}&status=${
        payload.status
      }${payload.remarks ? `&remarks=${payload.remarks}` : ""}`
    );
  },

  complaintHistory(filters) {
    return API.get(`/admin/complaint/status-history`, {
      params: {
        ...filters,
      },
    });
  },

  // Diagnostic
  addDiagnostic(formData) {
    return APIFile.post("admin/diagnostic", formData);
  },
  updateDiagnostic(formData) {
    return APIFile.post("admin/diagnostic/update", formData);
  },
  updateDiagnosticAppointment(payload) {
    return API.post(
      `/admin/appointment/diagnostic/update?diagnostic_id=${
        payload.diagnostic_id
      }&appointment_id=${payload.appointment_id}&status=${payload.status}${
        payload.remarks ? `&remarks=${payload.remarks}` : ""
      }`
    );
  },
  diagnosticAppointmentHistory(filters) {
    return API.get(`/admin/appointment/diagnostic/status-history`, {
      params: {
        ...filters,
      },
    });
  },

  deleteDiagnostic(diagnostic_id) {
    return API.delete(`admin/diagnostic?diagnostic_id=${diagnostic_id}`);
  },

  getDiagnostics(paginateOptions, filter) {
    let per_page = paginateOptions.per_page || 5;
    let page = paginateOptions.page || 1;
    return API.get(`admin/diagnostics?page=${page}&per_page=${per_page}`, {
      params: {
        ...filter,
      },
    });
  },

  getDiagnosticAppointments(paginateOptions, filter) {
    let per_page = paginateOptions.per_page || 5;
    let page = paginateOptions.page || 1;
    return API.get(
      `admin/appointments?for_diagnostic=1&page=${page}&per_page=${per_page}`,
      {
        params: {
          ...filter,
        },
      }
    );
  },

  // handleappoinetment status
  calllead() {
    return API.post("/calllead");
  },

  visitor() {
    return API.post("/visitor");
  },
  getVisitors() {
    return API.get("/getVisitors");
  },
  postService(data) {
    return APIFile.post("/admin/service", data);
  },

  updateService(data) {
    return APIFile.post("/admin/service/update", data);
  },

  getServices(data) {
    let per_page = 100;
    let page = 1;
    let filter = {};
    if (data) {
      if (data.paginateOptions) {
        per_page = data.paginateOptions.per_page;
        page = data.paginateOptions.page;
      }
      if (data.filter) {
        filter = data.filter;
      }
    }

    return API.get(`/admin/services?page=${page}&per_page=${per_page}`, {
      params: {
        ...filter,
      },
    });
  },
  getServiceBySlug(slug) {
    return API.get(`/getServiceBySlug/${slug}`);
  },

  deleteService(id) {
    return API.post("/deleteService", { id });
  },
  getCallLeads(startDate, endDate) {
    return API.post("/getCallLeads", { startDate, endDate });
  },
  postDepartments(postData) {
    return APIFile.post("admin/department", postData);
  },
  updateDepartments(postData) {
    return APIFile.post("admin/department/update", postData);
  },
  deleteDepartment(id) {
    return API.delete(`admin/department?department_id=${id}`);
  },
  restoreDepartment(id) {
    return API.patch(`admin/department/restore?department_id=${id}`);
  },
  centerFetch() {
    return APIFile.get("admin/centers");
  },
  fetchPharmacy() {
    return API.get("admin/pharmacy");
  },
  bannerUpload(data) {
    return APIFile.post("banner/pharmacyBanner", data);
  },
  getBanner() {
    return API.get("banner/bannersGet");
  },

  // home page banner related
  getBanners(banner_for) {
    return API.get(`admin/banner?banner_for=${banner_for}`);
  },

  uploadBannerSingle(data) {
    return APIFile.post("admin/banner/single", data);
  },

  deleteBannerSingle(data) {
    return API.delete("admin/banner/single", {
      params: {
        ...data,
      },
    });
  },

  createTimeScheduleMultipleDays(data) {
    API.post("admin/doctor/time-schedule/createTimeScheduleMultipleDays", data);
  },
  // login
  register(formData) {
    return API.post("/register", { formData });
  },

  login(email, password, role) {
    return API.post("/login", { email, password, role });
  },
  // get numbers
  getNumbers(filters) {
    return API.get("/admin/numbers", {
      params: {
        ...filters,
      },
    });
  },
  updateNumber(data) {
    return API.post("/admin/number/update", data);
  },
  deleteNumber(id) {
    return API.delete(`/admin/number?id=${id}`);
  },
  addNumber(data) {
    return API.post("/admin/number/add", data);
  },
  // telecaller
  addTelecaller(data) {
    return API.post("/admin/telecaller", data);
  },
  getTelecallers(paginateOptions, filters) {
    return API.get(`/admin/telecallers`, {
      params: {
        ...paginateOptions,
        ...filters,
      },
    });
  },
  updateIsActiveTelecaller(telecaller_id, isActive) {
    return API.post(
      `/admin/telecaller/update-is-active?telecaller_id=${telecaller_id}&isActive=${isActive}`
    );
  },
  updateTelecaller(telecaller_id, data) {
    return API.post(
      `/admin/telecaller/update?telecaller_id=${telecaller_id}`,
      data
    );
  },

  updateIsActiveAdmin(admin_id, isActive) {
    return API.post(
      `/superadmin/update-admin-is-active?admin_id=${admin_id}&isActive=${isActive}`
    );
  },
  getAllAdmins(filters) {
    return API.get("/superadmin/get-all-admins", {
      params: {
        ...filters,
      },
    });
  },
  createAdmin(data) {
    return API.post("/superadmin/create-admin", data);
  },

  updateAdmin(admin_id, data) {
    return API.post(`/superadmin/update-admin?admin_id=${admin_id}`, data);
  },

  // testimonials and blogs
  getTestimonials(paginateOptions) {
    let per_page = paginateOptions.per_page || 5;
    let page = paginateOptions.page || 1;
    return API.get(`/admin/testimonials?page=${page}&per_page=${per_page}`);
  },
  addTestimonials(data) {
    return API.post("/admin/testimonial", data);
  },
  deleteTestimonials(data) {
    return API.post("/admin/testimonial/delete", { id: data });
  },
  getBlogs(paginateOptions) {
    let per_page = paginateOptions.per_page || 5;
    let page = paginateOptions.page || 1;
    return API.get(`/admin/blogs?page=${page}&per_page=${per_page}`);
  },
  addBlogs(data) {
    return API.post("/admin/blogs", data);
  },
  deleteBlogs(data) {
    return API.post("/admin/blogs/delete", data);
  },
  updateBannerPriority: (data) => {
    return API.post(`admin/banner/single/update`, data);
  },
  userUpdate(data) {
    return APIFile.post("/user/update", data);
  },
};
