import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccessTime from "@mui/icons-material/AccessTime";
import MenuModal from "./menu-modal";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function CustomizedMenus({
  opensModal,
  menuButtons,
  handleOpenModal,
  setModalProps,
  setGetRow,
  row,
  btnText,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setGetRow(row);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = (e) => {
    console.log("edit");
  };

  const handleOptionClick = (item, itemOnClick) => {
    setModalProps({
      status: item,
      onClick: itemOnClick,
    });
    // this is coming from the parent
    handleOpenModal();
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={(e, row) => {
          handleClick(e, row);
        }}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {btnText ? btnText : "Actions"}
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuButtons.map((item, index) => (
          <MenuItem
            key={index}
            onClick={
              // this is done only when a modal is required for each option
              opensModal
                ? (e) => {
                    handleOptionClick(item.name, item.onClick);
                  }
                : item.onClick
            }
            disableRipple
          >
            {item.icon === "EditIcon" ? (
              <EditIcon />
            ) : item.icon === "DeleteIcon" ? (
              <DeleteIcon />
            ) : item.icon === "AccessTime" ? (
              <AccessTime />
            ) : (
              ""
            )}
            {item.name}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
