import { useState, useEffect, useCallback } from "react";

const useDebounce = (callback, delay) => {
  const [timer, setTimer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const debouncedCallback = useCallback(
    async (...args) => {
      if (timer) {
        clearTimeout(timer);
      }
      setIsLoading(true);
      const newTimer = setTimeout(async () => {
        try {
          await callback(...args);
        } finally {
          setIsLoading(false);
        }
      }, delay);
      setTimer(newTimer);
    },
    [callback, delay, timer]
  );

  useEffect(() => {
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [timer]);

  return [debouncedCallback, isLoading];
};

export default useDebounce;
