import React, { useState, useEffect, useMemo, useRef } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import JoditEditor from "jodit-react";
import Button from '@mui/material/Button';
import Swal from 'sweetalert2'
import api from '../../API/api';


const AddBlogs = ({ editData, setModalOpen, data, setLoading, setRerun }) => {
    const [description, setDescription] = useState();
    const [name, setName] = useState({
        name: '',
        description: '',
        image: ''
    })
    const [coverpreview, setCoverpreview] = useState();
    const handleDetails = (e) => {
        setDescription(e);
    }
    const editor = useRef(null);
    const config = {
        uploader: {
            insertImageAsBase64URI: true,
        },
        height: 420,
        triggerChangeEvent: true,
        readonly: false,
    };
    const handleInput = (e) => {
        setName({
            ...name,
            [e.target.name]: e.target.value
        })
    }
    const Jodit = () => {
        return useMemo(
            () => (
                <JoditEditor
                    ref={editor}
                    config={config}
                    value={description}
                    onChange={handleDetails}
                />
            ),
            [name]
        );
    };

    const handleSubmit = () => {
        // setModalOpen(false)
        // setLoading(true);
        const postData = new FormData();
        postData.append('title', name.name);
        postData.append('desc', description);
        postData.append('image', name.image);
        if (name.id) {
            postData.append('id', name.id);
        }
        // return;
        api.addBlogs(postData).then(res => {
            console.log(res);
            setLoading(false);
            Swal.fire({
                title: 'Success!',
                text: 'Added Succeffully !',
                icon: 'success',
                confirmButtonText: 'Ok'
            })
            setRerun(true)
        }).catch(err => console.log(err.response))
    }

    const handleImage = (e) => {
        console.log(e.target.value, 'dsajdb');
        setCoverpreview(URL.createObjectURL(e.target.files[0]));
        setName({
            ...name,
            image: e.target.files[0]
        })
    }

    useEffect(() => {
        setName({
            name: data.title,
            description: data.desc,
            id: data.id,
            image: data.image
        })
        setDescription(data.desc)
    }, [editData])

    return (
        <section>
            <div className="col-md-12">
                <div className="p-2 border">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1 },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className='row'>
                            <div className='col-md-12'>
                                <label htmlFor="">Blog title:</label>
                                <TextField id="standard-basic" label="Add Package Name" variant="outlined" size="small" className="form-control" name='name' onChange={handleInput} value={name?.name} />
                            </div>
                            <div className='d-flex'>
                                <div className='col-md-12 d-block my-5'>
                                    <label htmlFor="">Blog Image:</label>
                                    <input name="file" value="" type='file' onChange={(e) => handleImage(e)} />
                                    {
                                        coverpreview && coverpreview &&
                                        <div width="100">
                                            <img src={coverpreview} height="100px" width="100px" />
                                        </div>
                                    }
                                    <div width="100">
                                        <img src={`${process.env.REACT_APP_BACKEND}assets/blogs/${name.image}`} height={100} width={100} />
                                    </div>
                                </div>
                            </div>
                            <label htmlFor="">Blog Description:</label>
                            {/* <textarea className='form-control' id="" rows="4" name='description' onChange={handleInput} value={name?.description}>{name?.description}</textarea> */}
                            {Jodit()}
                            <div className="text-center my-4">
                                {/* {
                                    editData ?
                                        <Button variant="contained" onClick={handleUpdate}>Update</Button>
                                        : */}
                                <Button variant="contained" onClick={handleSubmit}>Submit</Button>

                                {/* } */}
                            </div>
                        </div>

                    </Box>
                </div>
            </div>
        </section>
    )
}


export default AddBlogs;