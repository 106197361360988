import { MenuItem, TextField } from "@mui/material";
import { Fragment } from "react";

/**
 * Generates a table head text field component.
 *
 * either provide a wrapper in the Generator props
 * or in the heads props
 * as it will be used to wrap all the individual textfields
 *
 * @component
 * @param {import('./table__types').TableHeadProps} props - The properties for the table head items.
 * @returns {JSX.Element} A div containing the table head text fields.
 */
export const TableHeadTextFieldGenerator = ({
  heads,
  Wrapper = Fragment,
  align,
}) => {
  return heads.map((head) => (
    <Wrapper key={head.label} align={align || head.align}>
      {!head.isFilter ? <span>{head.label}</span> : null}
      {head.isFilter &&
        (head.Wrapper ? (
          <head.Wrapper>
            <TextFieldTableHead {...head} />
          </head.Wrapper>
        ) : (
          <TextFieldTableHead {...head} />
        ))}
    </Wrapper>
  ));
};

/**
 * @component
 * @param {import('./table__types').TableHeadTextFieldProps} props - The properties for the table head text field.
 */
export const TextFieldTableHead = ({
  type,
  label,
  value,
  onChange,
  options,
  sx,
}) => {
  return (
    <TextField
      select={type === "select"}
      label={label}
      value={value}
      onChange={onChange}
      sx={{
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor: "white",
          },
          "&:hover fieldset": {
            borderColor: "white",
          },
          "&.Mui-focused fieldset": {
            borderColor: "white",
          },
          color: "white",
        },
        "& .MuiInputLabel-root": {
          color: "white",
        },
        "& .MuiSelect-root": {
          color: "white",
        },
        "& .MuiSelect-icon": {
          color: "white",
        },
        "& .MuiMenuItem-root": {
          color: "white",
        },
        minWidth: 150,
        color: "white",
        ...sx,
      }}
      InputLabelProps={{
        style: { color: "white" },
      }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            sx: {
              bgcolor: "rgba(0, 0, 0, 0.8)",
              "& .MuiMenuItem-root": {
                color: "white",
              },
            },
          },
        },
      }}
    >
      {type === "select" &&
        options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  );
};
