import {
  Box,
  Button,
  Icon,
  IconButton,
  Menu,
  Modal,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { useEffect, useState } from "react";
import api from "../../../API/api";
import DebounceSpinner from "../../../components/common/debounce-spinner";
import CustomPagination from "../../../components/common/custom-pagination";
import AdminAddPage from "./admin-add.page";
import { toast } from "react-toastify";
import AdminPopoverCell from "./features/admin-popover-cell";

// style for centering the modal
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const AdminManagePage = () => {
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [paginateOptions, setPaginateOptions] = useState({
    current_page: 1,
    last_page: 1,
    total: 1,
    per_page: 10,
  });

  // for handling the modal of add telecaller
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [adminForm, setAdminForm] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleOpenAddAdmin = () => {
    setShowAddAdmin(true);
  };

  const handleCloseAddAdmin = () => {
    setShowAddAdmin(false);
  };

  const handleAddAdmin = () => {
    // validate the form

    if (adminForm.name.trim() === "") {
      toast.error("Name is required");
      return;
    }

    if (adminForm.email.trim() === "") {
      toast.error("Email is required");
      return;
    }

    if (adminForm.password.trim() === "") {
      toast.error("Password is required");
      return;
    }

    // make network request
    setSubmitting(true);
    api
      .createAdmin(adminForm)
      .then((res) => {
        if (res.data.success) {
          toast.success("Admin added successfully");
          setShowAddAdmin(false);
          setAdminForm({
            name: "",
            email: "",
            password: "",
          });
          getAdmins({ current_page: 1 }, {});
        }
      })
      .catch((err) => {
        toast.error("Error adding admin");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const [filters, setFilters] = useState({});

  const [admins, setAdmins] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState(null);

  const getAdmins = (pagination, filters) => {
    setLoading(true);

    let localPagination = { ...paginateOptions, ...pagination };
    let localFilters = { ...filters };
    api
      .getAllAdmins(localPagination, localFilters)
      .then((res) => {
        if (res.data.success) {
          let localAdmins = res.data.admins;

          setAdmins(localAdmins.data);
          delete localAdmins.data;
          setPaginateOptions(localAdmins);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const activateDeactivateHandler = (admin_id, isActive) => {
    updateIsActiveAdmin(admin_id, isActive);
  };

  const updateIsActiveAdmin = (admin_id, isActive) => {
    setLoading(true);
    api
      .updateIsActiveAdmin(admin_id, isActive)
      .then((res) => {
        if (res.data.success) {
          let localAdmins = admins;
          localAdmins = localAdmins.map((admin) => {
            if (admin.id === admin_id) {
              admin.isActive = isActive;
            }
            return admin;
          });
          setAdmins(localAdmins);
        }
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeFirstPage = () => {
    setPaginateOptions({ ...paginateOptions, current_page: 1 });
    getAdmins({ current_page: 1 }, filters);
  };

  const handleChangeLastPage = () => {
    setPaginateOptions({
      ...paginateOptions,
      current_page: paginateOptions.last_page,
    });
    getAdmins({ current_page: paginateOptions.last_page }, filters);
  };

  const handleChangePrevPage = () => {
    setPaginateOptions({
      ...paginateOptions,
      current_page: paginateOptions.current_page - 1,
    });
    getAdmins({ current_page: paginateOptions.current_page - 1 }, filters);
  };

  const handleChangeNextPage = () => {
    setPaginateOptions({
      ...paginateOptions,
      current_page: paginateOptions.current_page + 1,
    });
    getAdmins({ current_page: paginateOptions.current_page + 1 }, filters);
  };

  useEffect(() => {
    getAdmins({ current_page: 1, per_page: 10 }, filters);
  }, [filters]);

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2}></Stack>
      <Stack direction="column" spacing={2}>
        <TableContainer
          component={Paper}
          sx={{
            height: "100%",
            flexGrow: 1,
            maxHeight: "80vh",
            overflow: "hidden",
          }}
        >
          <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
            <Typography variant="h6" component="div">
              Admins
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenAddAdmin}
            >
              Add Admin
            </Button>
          </Stack>
          <Stack direction="row" justifyContent="space-between" sx={{ p: 2 }}>
            <Box></Box>
            <CustomPagination
              last_page={paginateOptions.last_page}
              total={paginateOptions.total}
              current_page={paginateOptions.current_page}
              handleChangeFirstPage={handleChangeFirstPage}
              handleChangeLastPage={handleChangeLastPage}
              handleChangePrevPage={handleChangePrevPage}
              handleChangeNextPage={handleChangeNextPage}
            />
          </Stack>
          {loading && <DebounceSpinner isDebouncing={loading} />}
          <Stack
            direction="column"
            sx={{
              minHeight: "500px",
              maxHeight: "calc(100vh - 35vh)",
              overflowY: "scroll",
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Sl no.</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Email</TableCell>
                  <TableCell align="center">Active</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {admins.map((row, index) => (
                  <TableRow
                    key={row.name}
                    onClick={() => setSelectedAdmin(row)}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                      },
                      backgroundColor:
                        selectedAdmin === row ? "rgba(0, 0, 0, 0.04)" : "",
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {index + 1}
                    </TableCell>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.email}</TableCell>
                    <TableCell align="center">
                      {row.isActive ? "Yes" : "No"}
                    </TableCell>
                    <AdminPopoverCell
                      telecallers={admins}
                      setTelecallers={setAdmins}
                      row={row}
                      setSelectedTelecaller={setSelectedAdmin}
                      activateDeactivateHandler={activateDeactivateHandler}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Stack>
        </TableContainer>
      </Stack>
      <Modal
        open={showAddAdmin}
        onClose={handleCloseAddAdmin}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            ...style,

            width: {
              md: "30%",
              sm: "90%",
              xs: "90%",
            },
          }}
        >
          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Add Admin
          </Typography>
          <AdminAddPage
            submitting={submitting}
            handleAddTelecaller={handleAddAdmin}
            telecallerForm={adminForm}
            setTelecallerForm={setAdminForm}
          />
          <Button
            onClick={handleCloseAddAdmin}
            variant="contained"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </Stack>
  );
};

export default AdminManagePage;
