import { Button, Stack, TextField } from "@mui/material";

const TelecallerAddPage = ({
  telecallerForm,
  setTelecallerForm,
  handleAddTelecaller,
  submitting,
}) => {
  return (
    <Stack direction={"column"} spacing={2}>
      <TextField
        label="Name"
        InputLabelProps={{
          shrink: true,
        }}
        value={telecallerForm.name}
        onChange={(e) =>
          setTelecallerForm({ ...telecallerForm, name: e.target.value })
        }
      />
      <TextField
        label="Email"
        InputLabelProps={{
          shrink: true,
        }}
        type="email"
        value={telecallerForm.email}
        onChange={(e) =>
          setTelecallerForm({ ...telecallerForm, email: e.target.value })
        }
      />
      <TextField
        label="Password"
        type="password"
        InputLabelProps={{
          shrink: true,
        }}
        value={telecallerForm.password}
        onChange={(e) =>
          setTelecallerForm({ ...telecallerForm, password: e.target.value })
        }
      />
      <Button
        disabled={submitting}
        variant="contained"
        sx={{ mt: 2 }}
        onClick={handleAddTelecaller}
      >
        {submitting ? "Adding..." : "Add Telecaller"}
      </Button>
    </Stack>
  );
};

export default TelecallerAddPage;
