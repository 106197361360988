import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Stack,
} from "@mui/material";

const AppointmentStatusHistoryTable = ({
  statusHistory,
  loading,
  handleChangePage,
  handlePerPage,
  paginateOptions,
  historyFor,
}) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: "100%",
        flex: 1,
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Sl No.</TableCell>
            {/* <TableCell align="right">Appointment By</TableCell>
            <TableCell align="right">Appointment For</TableCell> */}
            <TableCell align="right">Patient Name</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Changed At</TableCell>
            <TableCell align="right">Changed By</TableCell>
            <TableCell align="right">Remarks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statusHistory.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell align="right">{row.appointment.name}</TableCell>
              {/* <TableCell align="right">
                {historyFor === "services" && (
                  <Typography>
                    {row.appointment[historyFor][0].service}
                  </Typography>
                )}
                {historyFor !== "services" && (
                  <Typography>{row.appointment[historyFor][0].name}</Typography>
                )}
              </TableCell> */}
              <TableCell align="right">{row.status}</TableCell>
              <TableCell align="right">
                {new Date(row.created_at).toLocaleString()}
              </TableCell>
              <TableCell align="right">
                <Stack direction={"column"} spacing={2}>
                  <Typography>{row.user.name}</Typography>
                  <Typography>{row.user.email}</Typography>
                </Stack>
              </TableCell>
              <TableCell align="right">{row.remarks}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AppointmentStatusHistoryTable;

export const ComplaintStatusHistoryTable = ({
  statusHistory,
  loading,
  handleChangePage,
  handlePerPage,
  paginateOptions,
  historyFor,
}) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: "100%",
        flex: 1,
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Sl No.</TableCell>
            {/* <TableCell align="right">Complaint By</TableCell>
            <TableCell align="right">Subject</TableCell>
            <TableCell align="right">Description</TableCell> */}
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Changed At</TableCell>
            <TableCell align="right">Changed By</TableCell>
            <TableCell align="right">Remarks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statusHistory.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              {/* <TableCell align="right">
                {row.complaint.email} | {row.complaint.phone_number}
              </TableCell>
              <TableCell align="right">
                <Typography>{row.complaint.subject}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>{row.complaint.description}</Typography>
              </TableCell> */}
              <TableCell align="right">{row.status}</TableCell>
              <TableCell align="right">
                {new Date(row.created_at).toLocaleString()}
              </TableCell>
              <TableCell align="right">
                <Stack direction={"column"} spacing={2}>
                  <Typography>{row.user.name}</Typography>
                  <Typography>{row.user.email}</Typography>
                </Stack>
              </TableCell>
              <TableCell align="right">{row.remarks}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const PharmacyOrderStatusHistoryTable = ({
  statusHistory,
  loading,
  handleChangePage,
  handlePerPage,
  paginateOptions,
  historyFor,
}) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: "100%",
        flex: 1,
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Sl No.</TableCell>
            {/* <TableCell align="right">Complaint By</TableCell>
            <TableCell align="right">Subject</TableCell>
            <TableCell align="right">Description</TableCell> */}
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Changed At</TableCell>
            <TableCell align="right">Changed By</TableCell>
            <TableCell align="right">Remarks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statusHistory.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              {/* <TableCell align="right">
                {row.complaint.email} | {row.complaint.phone_number}
              </TableCell>
              <TableCell align="right">
                <Typography>{row.complaint.subject}</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>{row.complaint.description}</Typography>
              </TableCell> */}
              <TableCell align="right">{row.status}</TableCell>
              <TableCell align="right">
                {new Date(row.created_at).toLocaleString()}
              </TableCell>
              <TableCell align="right">
                <Stack direction={"column"} spacing={2}>
                  <Typography>{row.user.name}</Typography>
                  <Typography>{row.user.email}</Typography>
                </Stack>
              </TableCell>
              <TableCell align="right">{row.remarks}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
