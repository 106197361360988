import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import Swal from 'sweetalert2'
import api from '../../API/api';
import JoditEditor from 'jodit-react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useCenterContext } from '../../context/center-context';

const AddTestimonials = ({ editData, setModalOpen, data ,setLoading,setRerun}) => {
    const [description, setDescription] = useState();
    const [name, setName] = useState({
        name: '',
        description: '',
    })
    const handleDetails = (e) => {
        setDescription(e);
    }
    const handleInput = (e) => {
        setName({
            ...name,
            [e.target.name]: e.target.value
        })
    }


    const handleSubmit = () => {
        setModalOpen(false)
        setLoading(true);
        const postData = new FormData();
        postData.append('title', name.name);
        postData.append('desc', name.description);
        if (name.id) {
            postData.append('id', name.id);
        }
        
        api.addTestimonials(postData).then(res => {
            console.log(res);
            setLoading(false);
            Swal.fire({
                title: 'Success!',
                text: 'Added Succeffully !',
                icon: 'success',
                confirmButtonText: 'Ok'
            })
            setRerun(true)
        }).catch(err => console.log(err.response))
    }
 useEffect(() => {
        setName({
            name: data.title,
            description: data.desc,
            id: data.id
        })
    }, [editData])

    return (
        <section>
            <div className="col-md-12">
                <div className="p-2 border">
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1 },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <div className='row'>
                            <div className='col-md-12'>
                                <label htmlFor="">Testimonial title:</label>
                                <TextField id="standard-basic" label="Add Package Name" variant="outlined" size="small" className="form-control" name='name' onChange={handleInput} value={name?.name} />
                            </div>

                            <label htmlFor="">Testimonial Description:</label>
                            <textarea className='form-control' id="" rows="4" name='description' onChange={handleInput} value={name?.description}>{name?.description}</textarea>
                            <div className="text-center my-4">
                                {/* {
                                    editData ?
                                        <Button variant="contained" onClick={handleUpdate}>Update</Button>
                                        : */}
                                        <Button variant="contained" onClick={handleSubmit}>Submit</Button>

                                {/* } */}
                            </div>
                        </div>

                    </Box>
                </div>
            </div>
        </section>
    )
}


export default AddTestimonials;