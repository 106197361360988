import {
  FirstPage,
  LastPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";

const CustomPagination = ({
  last_page,
  total,
  current_page,
  handleChangeFirstPage,
  handleChangeLastPage,
  handleChangePrevPage,
  handleChangeNextPage,
}) => {
  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <IconButton disabled={current_page === 1} onClick={handleChangeFirstPage}>
        <FirstPage />
      </IconButton>
      <IconButton disabled={current_page === 1} onClick={handleChangePrevPage}>
        <KeyboardArrowLeft />
      </IconButton>
      <Typography>
        {current_page} of {last_page}
      </Typography>
      <IconButton
        disabled={current_page === last_page}
        onClick={handleChangeNextPage}
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        disabled={current_page === last_page}
        onClick={handleChangeLastPage}
      >
        <LastPage />
      </IconButton>
    </Stack>
  );
};

export default CustomPagination;
