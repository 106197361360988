import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Box,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { toast } from "react-toastify";
import api from "../../../../API/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

function AdminEditModal({ open, handleClose, data, setTelecallers }) {
  const [loading, setLoading] = useState(false);
  const [editForm, setEditForm] = useState({
    name: data.name,
    email: data.email,
    password: data.password,
  });

  const handleChange = (e, type) => {
    setEditForm({ ...editForm, [type]: e.target.value });
  };

  useEffect(() => {
    if (data) {
      setEditForm({
        name: data.name,
        email: data.email,
        password: data.password,
      });
    }
  }, [data]);

  const handleSubmit = () => {
    setLoading(true);

    api
      .updateTelecaller(data.id, editForm)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Admin updated successfully");
          handleClose();

          if (res.data.success) {
            setTelecallers((tels) => {
              return tels.map((tel) => {
                if (tel.id === data.id) {
                  return { ...tel, ...editForm };
                }
                return tel;
              });
            });
          }
        }
      })
      .catch((err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message.split(".")[0]);
        } else {
          toast.error("Something went wrong");
        }
      })
      .finally(() => {
        setLoading(false);
        setEditForm({
          name: "",
          email: "",
          password: "",
        });
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 0, right: 0 }}
        >
          <CloseIcon />
        </IconButton>
        <Stack spacing={1} direction={"column"}>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            position={"relative"}
            alignItems={"center"}
          >
            <Typography>Edit Admin</Typography>
          </Stack>

          <Stack direction={"column"} spacing={2}>
            <TextField
              label="Name"
              value={editForm.name}
              onChange={(e) => handleChange(e, "name")}
            />
            <TextField
              label="Email"
              value={editForm.email}
              onChange={(e) => handleChange(e, "email")}
            />
            <TextField
              label="Password"
              value={editForm.password}
              onChange={(e) => handleChange(e, "password")}
            />
          </Stack>
          <Stack direction={"row"} spacing={2} width={"100%"}>
            <Button
              fullWidth
              disabled={loading}
              variant="contained"
              onClick={handleSubmit}
            >
              {loading ? "Submitting..." : "Submit"}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

export default AdminEditModal;
