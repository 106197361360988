import React, { useRef, useState, useMemo } from "react";
import JoditEditor from "jodit-react";
const config = {
  uploader: {
    insertImageAsBase64URI: true,
  },
  height: 420,
  triggerChangeEvent: true,
  readonly: false,
};
const JoditEditorCustom = ({ value, onChange }) => {
  const editor = useRef(null);

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={value}
        config={config}
        onChange={onChange}
      />
    ),
    [value, onChange]
  );
};

export default JoditEditorCustom;
