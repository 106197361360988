import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Stack,
} from "@mui/material";

const StatusHistoryTable = ({
  statusHistory,
  loading,
  handleChangePage,
  handlePerPage,
  paginateOptions,
}) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        height: "100%",
        flex: 1,
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>Sl No.</TableCell>
            <TableCell align="right">Appointment By</TableCell>
            <TableCell align="right">Appointment For</TableCell>
            <TableCell align="right">Status</TableCell>
            <TableCell align="right">Changed At</TableCell>
            <TableCell align="right">Changed By</TableCell>
            <TableCell align="right">Remarks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statusHistory.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell align="right">{row.appointment.name}</TableCell>
              <TableCell align="right">
                {row.appointment.doctors[0].name}
              </TableCell>
              <TableCell align="right">{row.status}</TableCell>
              <TableCell align="right">
                {new Date(row.created_at).toLocaleString()}
              </TableCell>
              <TableCell align="right">
                <Stack direction={"column"} spacing={2}>
                  <Typography>{row.user.name}</Typography>
                  <Typography>{row.user.email}</Typography>
                </Stack>
              </TableCell>
              <TableCell align="right">{row.remarks}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default StatusHistoryTable;
