import {
  Button,
  Modal,
  Typography,
  Box,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../API/api";
import { toast } from "react-toastify";

let emptyNumber = {
  contact_number: "",
  name: "",
  id: "",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 4,
};

const NumberAddUpdateModal = (props) => {
  const { open, handleClose, data, fetchNumbers } = props;
  const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState({
    contact_number: "",
    name: "",
    id: "",
  });

  const handleUpdate = () => {
    let payload = {
      contact_number: number.contact_number,
      name: number.name,
      id: number.id,
    };

    setLoading(true);
    // Add API call to update the number
    api
      .updateNumber(payload)
      .then((res) => {
        console.log(res);
        toast.success("Number updated successfully");
        handleClose();
        fetchNumbers();
      })
      .catch((err) => {
        toast.error("Error updating number");
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (data) {
      setNumber({
        contact_number: data.contact_number,
        name: data.name,
        id: data.id,
      });
    }

    return () => {
      setNumber(emptyNumber);
    };
  }, [data]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Stack spacing={2}>
          <Typography variant="h6">Edit Number</Typography>
          <TextField
            label="Name"
            value={number.name}
            onChange={(e) => setNumber({ ...number, name: e.target.value })}
            fullWidth
          />
          <TextField
            label="Contact Number"
            value={number.contact_number}
            onChange={(e) =>
              setNumber({ ...number, contact_number: e.target.value })
            }
            fullWidth
          />
          <Button
            disabled={loading}
            onClick={handleUpdate}
            variant="contained"
            sx={{ mt: 2 }}
          >
            {loading ? "Updating..." : "Update"}
          </Button>
        </Stack>
        <Button onClick={handleClose} variant="contained" sx={{ mt: 2 }}>
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default NumberAddUpdateModal;
