import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Button,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Box,
  Chip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import AddIcon from "@mui/icons-material/Add";

const AccordionFilter = ({
  filterOptions,
  setFilters,
  onFilterChange,
  filters,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggle = (value) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [value]: !prevFilters[value],
    }));
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  useEffect(() => {
    // everytime something is selected, or unselected,
    // we need to update the parent filters
    // by comparing if any filter options provided even exists in the selected filters
    // if not, we need to remove it from the parent filters
    // if yes, we need to add it to the parent filters
    let localFilters = { ...filters };
    Object.keys(filterOptions).forEach((filter) => {
      if (selectedFilters[filter]) {
      } else {
        delete localFilters[filter];
      }
    });
    if (Object.keys(localFilters).length !== Object.keys(filters).length) {
      setFilters(localFilters);
    }
  }, [selectedFilters]);

  return (
    <Accordion
      sx={{
        my: 2,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="filter-content"
        id="filter-header"
      >
        <Stack direction="row" alignItems="center">
          <Box display="flex" alignItems="center" sx={{ marginRight: 2 }}>
            <FilterListIcon sx={{ marginRight: 1 }} />
            <Typography>Filters</Typography>
          </Box>

          <Stack direction="row" spacing={1} rowGap={1} flexWrap={"wrap"}>
            {Object.keys(selectedFilters).map((filter) => {
              if (!selectedFilters[filter]) {
                return null;
              }
              return (
                <Chip
                  key={filter}
                  label={
                    filterOptions[filter].label +
                    (selectedFilters[filter]
                      ? `: ${filters[filter] ? filters[filter] : "All"}`
                      : null)
                  }
                  onDelete={() => handleToggle(filter)}
                />
              );
            })}
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          flexWrap={"wrap"}
          rowGap={1}
        >
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            Add Filter
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <List sx={{ width: 250, maxWidth: 300 }}>
              {Object.keys(filterOptions).map((filter) => (
                <ListItem
                  key={filter}
                  role={undefined}
                  dense
                  button
                  onClick={() => handleToggle(filter)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={!!selectedFilters[filter]}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={filterOptions[filter].label} />
                </ListItem>
              ))}
            </List>
          </Popover>
          {Object.keys(filterOptions).map((filter) => {
            if (!selectedFilters[filter]) {
              return null;
            }
            return (
              <TextField
                key={filter}
                label={filterOptions[filter].label}
                variant="outlined"
                type={filterOptions[filter].type}
                InputLabelProps={
                  filterOptions[filter].type === "date"
                    ? { shrink: true }
                    : undefined
                }
                onChange={(e) => {
                  setFilters((prevFilters) => ({
                    ...prevFilters,
                    [filter]: e.target.value,
                  }));
                }}
              />
            );
          })}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionFilter;
