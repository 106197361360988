// import { Calendar } from 'react-date-range';
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css';
import { formatDate } from "./form/doctorTimmings";
import moment from "moment";
import { addDays, differenceInCalendarDays } from "date-fns";
import { useState } from "react";

export default function Calender({
  time,
  setTime,
  handleDateSelect,
  listAvailability,
  onMonthChange,
}) {
  const [dateRange, setDateRange] = useState([new Date(), new Date()]); // State for the selected date range
  const [startMonth, setStartMonth] = useState(null); // State to store the month of the first selected date
  const [startMonthYear, setStartMonthYear] = useState({
    month: null,
    year: null,
  }); // State to store month and year of the first selected date
  const [endMonthYear, setEndMonthYear] = useState({ month: null, year: null });

  function handleSelect(date) {
    // console.log(date); // native Date object
    handleDateSelect(date);
  }
  console.log(listAvailability, "formatDate(new Date()");
  // the format is like MM-DD-YYYY for titleClass

  const marks = new Set(["10-06-2024", "09-09-2024", "08-08-2024"]);
  function isSameDay(a, b) {
    return differenceInCalendarDays(a, b) === 0;
  }
  const highlightedDates = ["10-09-2024", "9-1-2024", "08-08-2024"];
  function tileClassName({ date, view }) {
    if (
      view === "month" &&
      listAvailability.find((dDate) => isSameDay(dDate, date))
    ) {
      return "highlight";
    }
  }

  const tileDisabled = ({ date, view }) => {
    if (view === "month") {
      // Only apply this logic in month view
      // if (startMonth !== null) {  // If a start month is defined
      //     // Disable dates that are not in the start month
      //     return date.getMonth() !== startMonth;
      // }
      if (startMonthYear.month !== null) {
        // If a start month is defined
        // Disable dates that are not in the start month
        return date.getMonth() !== startMonthYear.month;
      }
    }
    return false; // Do not disable any other date
  };
  const handleDateChange = (dates) => {
    setDateRange(dates);
    if (dates.length === 1) {
      // If only one date is selected
      const selectedDate = dates[0];
      setStartMonthYear({
        month: selectedDate.getMonth(),
        year: selectedDate.getFullYear(),
      }); // Set the start month and year
      setEndMonthYear({ month: null, year: null }); // Reset end month and year
      console.log(
        { month: selectedDate.getMonth(), year: selectedDate.getFullYear() },
        "get year"
      );
      handleDateSelect(
        dates,
        selectedDate.getMonth(),
        selectedDate.getFullYear()
      );
    } else if (dates.length === 2) {
      // If the range is complete (two dates)
      const start = dates[0];
      const end = dates[1];
      const date2 = new Date(start.getTime());
      const dates2 = [];

      while (date2 <= end) {
        dates2.push(new Date(date2).getDate());
        date2.setDate(date2.getDate() + 1);
      }
      if (
        start.getMonth() === end.getMonth() &&
        start.getFullYear() === end.getFullYear()
      ) {
        // Ensure both dates are in the same month and year
        setStartMonthYear({
          month: start.getMonth(),
          year: start.getFullYear(),
        });
        setEndMonthYear({ month: end.getMonth(), year: end.getFullYear() });
        console.log(
          { month: end.getMonth(), year: end.getFullYear() },
          "get year"
        );

        handleDateSelect(dates2, end.getMonth(), end.getFullYear());
      } else {
        // Reset if the user selects dates from different months or years

        alert("Please select dates within the same month and year.");
        setEndMonthYear({ month: start.getMonth(), year: start.getFullYear() });
      }
    }
  };
  return (
    // <Calendar
    //     date={new Date()}
    //     onChange={handleSelect}
    //     minDate={new Date()}
    //     // disabledDates={['Wed Aug 09 2024 18:35:15 GMT+0530 (India Standard Time)']}
    //     // shownDate={['Wed Aug 19 2024 18:35:15 GMT+0530 (India Standard Time)']}
    // />
    <Calendar
      onActiveStartDateChange={({ activeStartDate }) =>
        onMonthChange(activeStartDate)
      }
      style={{ height: 500 }}
      // onChange={handleSelect}
      // allowPartialRange={true}
      selectRange={true}
      // value={this.state.date}
      // tileContent={d}
      hover={"2024-08-29"}
      tileClassName={tileClassName}
      // tileDisabled={({ date }) => date.getDay() === 0}

      minDate={new Date()}
      // value={dateRange}  // Provide the selected range to the calendar
      onChange={handleDateChange} // Handle range selection changes
      value={dateRange} // Provide the selected range to the calendar
      tileDisabled={false}
    ></Calendar>
  );
}
