import React, { useState, useEffect, useRef } from "react";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";

import api from "../../../API/api";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  Badge,
  MenuItem,
  TablePagination,
  TextField,
  Tooltip,
  IconButton,
  Icon,
  Stack,
  Typography,
} from "@mui/material";
import CustomizedMenus from "../../../components/tools/menu";
import AccordionFilter from "../../../components/filter/accordion-filter";

import { toast } from "react-toastify"; // New import for toast notifications
import { useMenuModalStates } from "../../../hooks/use-menu-modal-states"; // New import for modal states
import MenuModal from "../../../components/tools/menu-modal"; // New import for menu modal
import AppointmentStatusHistoryModal, {
  ComplaintStatusHistoryModal,
} from "../../../components/appointment-status-history/appointment-status-history-modal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#016b8b',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Complaints = () => {
  const tableRef = useRef(null);
  const [getRow, setGetRow] = useState();
  const [complaints, setComplaints] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const {
    openModal,
    modalProps,
    updatingStatus,
    setUpdatingStatus,
    statusHistoryModal,
    statusHistoryModalData,
    setStatusHistoryModalData,
    handleOpenStatusHistoryModal,
    handleCloseStatusHistoryModal,
    setModalProps,
    handleOpenModal,
    handleCloseModal,
  } = useMenuModalStates(); // New hook for modal states

  const [filters, setFilters] = useState({
    filter_status: "",
  });

  const viewComplaints = (newPage, newRowsPerPage) => {
    api
      .GetComplaints({ page: newPage, per_page: newRowsPerPage }, 1)
      .then((res) => {
        console.log("===>>>>", res);
        // setDepartment(res.data.data)
        setComplaints(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    viewComplaints(0, 5);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    viewComplaints(newPage + 1, 5);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    viewComplaints(0, event.target.value);
  };
  const handleStatus = (status, remarks) => {
    setUpdatingStatus(true);
    console.log(getRow, "getRow");
    const payload = {
      complaint_id: getRow.id,
      status: status,
      remarks: remarks,
    };
    api
      .UpdateComplaintsStatus(payload)
      .then((res) => {
        console.log(res);
        viewComplaints();
        handleCloseModal();
        if (res.data.success) {
          Swal.fire({
            title: "Success!",
            text: "Status Updated",
            icon: "success",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      })
      .finally(() => {
        setUpdatingStatus(false);
        setModalProps({});
      });
  };

  const menuButtons = [
    {
      id: 1,
      name: "Pending",
      onClick: (remarks) => handleStatus("pending", remarks), // Updated to include remarks
    },
    {
      id: 2,
      name: "Resolved",
      onClick: (remarks) => handleStatus("resolved", remarks), // Updated to include remarks
    },
    {
      id: 3,
      name: "Rejected",
      onClick: (remarks) => handleStatus("rejected", remarks), // Updated to include remarks
    },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "primary";
      case "resolved":
        return "success";
      case "rejected":
        return "error";
      default:
        return "default";
    }
  };

  const viewComplaintsByFilters = () => {
    setPage(0);
    setRowsPerPage(5);

    let newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key] === "") {
        delete newFilters[key];
      }
    });
    api
      .GetComplaints({ page: 1, per_page: 5 }, newFilters)
      .then((res) => {
        console.log("===>>>>", res);
        // setDepartment(res.data.data)
        setComplaints(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  // status badge clic
  // will open a modal with a table
  const handleStatusBadgeClick = (row) => {
    setGetRow(row);
    setStatusHistoryModalData(row);
    handleOpenStatusHistoryModal();
  };

  useEffect(() => {
    viewComplaintsByFilters();
  }, [filters]);

  return (
    <>
      <div className="row justify-content-between">
        <div className="col-6">
          <h4>Complaints </h4>
        </div>
      </div>
      <AccordionFilter
        filters={filters}
        setFilters={setFilters}
        filterOptions={{
          from: {
            label: "From",
            type: "date",
          },
          to: {
            label: "To",
            type: "date",
          },
        }}
      ></AccordionFilter>
      <TableContainer component={Paper} ref={tableRef}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="center">Created At</StyledTableCell>
              <StyledTableCell align="center">Phone</StyledTableCell>
              <StyledTableCell align="center">Email</StyledTableCell>
              {/* <StyledTableCell align="center">Status</StyledTableCell> */}
              <StyledTableCell align="center">Subject</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              {/* <StyledTableCell align="center">For Doctor</StyledTableCell> */}

              <StyledTableCell align="center">Last Updated By</StyledTableCell>

              <StyledTableCell align="center">
                <TextField
                  select
                  label="Status"
                  value={filters.filter_status}
                  onChange={(e) =>
                    setFilters({ ...filters, filter_status: e.target.value })
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                      color: "white", // Text color for the select
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                    },
                    "& .MuiSelect-root": {
                      color: "white",
                    },
                    "& .MuiSelect-icon": {
                      color: "white", // Dropdown icon color
                    },
                    "& .MuiMenuItem-root": {
                      color: "white", // Text color for menu items
                    },
                    minWidth: 150,
                    color: "white", // Text color for the TextField
                  }}
                  InputLabelProps={{
                    style: { color: "white" }, // Ensure label is white even when focused
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          bgcolor: "rgba(0, 0, 0, 0.2)", // Dark background for dropdown
                          "& .MuiMenuItem-root": {
                            color: "white", // Ensure menu items are white
                          },
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="resolved">Resolved</MenuItem>
                  <MenuItem value="rejected">Rejected</MenuItem>
                </TextField>
              </StyledTableCell>

              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {complaints &&
              complaints?.data?.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(row.created_at).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.phone_number}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.email}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.subject}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.description}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.last_updated_by && row.last_updated_by.length > 0 ? (
                      <Stack direction={"column"}>
                        <Typography variant="body2" color="text.secondary">
                          {row.last_updated_by[0].name}
                        </Typography>
                        <Typography variant="body2">
                          {row.last_updated_by[0].email}
                        </Typography>
                      </Stack>
                    ) : (
                      "null"
                    )}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Tooltip title="click to view status history" arrow>
                      <IconButton onClick={() => handleStatusBadgeClick(row)}>
                        <Badge
                          badgeContent={row.status}
                          color={getStatusColor(row.status)}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        ></Badge>
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomizedMenus
                      opensModal={true}
                      setModalProps={setModalProps}
                      handleOpenModal={handleOpenModal}
                      openModal={openModal}
                      row={row}
                      menuButtons={menuButtons}
                      setGetRow={setGetRow}
                      btnText={"update status"}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={complaints?.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <MenuModal
        updatingStatus={updatingStatus}
        handleOpen={handleOpenModal}
        handleClose={handleCloseModal}
        open={openModal}
        status={modalProps.status}
        handleClick={modalProps.onClick}
        setModalProps={setModalProps}
      />
      <ComplaintStatusHistoryModal
        open={statusHistoryModal}
        handleClose={handleCloseStatusHistoryModal}
        data={statusHistoryModalData}
        setStatusHistoryModalData={setStatusHistoryModalData}
        getAppointmentHistoryFromServer={api.complaintHistory} // Adjusted API call
        historyFor="complaints" // Adjusted history type
      />
    </>
  );
};

export default Complaints;
