import React, { useState, useEffect, useRef } from "react";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";

import api from "../../../API/api";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Badge, MenuItem, TablePagination, TextField } from "@mui/material";
import CustomizedMenus from "../../../components/tools/menu";
import ResponsiveDialog from "../../../components/modal/prompt";
import AddDiagnostic from "./diagnostic.add-text";
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#016b8b',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Diagnostics = () => {
  const tableRef = useRef(null);
  const [getRow, setGetRow] = useState();
  const [diagnostics, setDiagnostics] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedDiagnostic, setSelectedDiagnostic] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);

  const handleDeleteDiagnostic = (row) => setDeleteOpen(true);

  const [filters, setFilters] = useState({
    filter_status: "",
  });

  const viewDiagnostics = (newPage, per_page) => {
    api
      .getDiagnostics({ page: newPage + 1, per_page: per_page })
      .then((res) => {
        console.log("===>>>>", res);
        // setDepartment(res.data.data)
        setDiagnostics(res.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    viewDiagnostics(page, rowsPerPage);
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    viewDiagnostics(newPage, rowsPerPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    viewDiagnostics(page, event.target.value);
  };

  const handleClickOpen = (edit = false) => {
    setOpen(true);
    setSelectedDiagnostic(getRow);
    setIsEdit(edit);
  };

  const handleClose = () => setOpen(false);

  const deleteDiagnostic = () => {
    api
      .deleteDiagnostic(getRow.id)
      .then((res) => {
        console.log(res);
        setDeleteOpen(false);
        toast.success("Diagnostic deleted successfully");
        viewDiagnostics(page, rowsPerPage);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const menuButtons = [
    {
      id: 1,
      name: "edit",
      onClick: () => handleClickOpen(true),
      icon: "EditIcon",
    },
    {
      id: 3,
      name: "delete",
      onClick: () => handleDeleteDiagnostic(),
      icon: "DeleteIcon",
    },
  ];

  return (
    <>
      <div className="row justify-content-between">
        <div className="col-12 text-center">
          <h4>Diagnostics Tests </h4>
        </div>
      </div>
      <TableContainer component={Paper} ref={tableRef}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="center">Test Name</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              <StyledTableCell align="center">Center</StyledTableCell>
              {/* <StyledTableCell align="center">For Doctor</StyledTableCell> */}

              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {diagnostics &&
              diagnostics?.data?.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.test_name}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.description}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.centers?.[0]?.name || "-"}
                  </StyledTableCell>
                  {/* <StyledTableCell align="center">
                    {new Date(
                      row.health_package_appointments[0].date
                    ).getDate() +
                      ", " +
                      new Date(
                        row.health_package_appointments[0].date
                      ).toLocaleString("default", {
                        month: "long",
                      }) +
                      "-" +
                      new Date(
                        row.health_package_appointments[0].date
                      ).getFullYear()}
                  </StyledTableCell> */}

                  {/* <StyledTableCell align="center">
                    {row.doctors[0].name}
                  </StyledTableCell> */}

                  <StyledTableCell align="center">
                    <CustomizedMenus
                      row={row}
                      menuButtons={menuButtons}
                      setGetRow={setGetRow}
                      btnText={"Actions"}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={diagnostics?.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <ResponsiveDialog
        modalOpen={open}
        setModalOpen={setOpen}
        form={
          <AddDiagnostic
            data={selectedDiagnostic}
            handleClose={handleClose}
            isEdit={isEdit}
            updateList={viewDiagnostics}
          />
        }
        title={"Add Diagnostic"}
        cancelButtonText={"cancel"}
        submitButtonText={" "}
        size="lg"
      />

      <ResponsiveDialog
        modalOpen={deleteOpen}
        setModalOpen={setDeleteOpen}
        form={<p></p>}
        title={"Are You Sure to Delete"}
        cancelButtonText={"cancel"}
        submitButtonText={"Delete"}
        submitFunction={deleteDiagnostic}
        size="lg"
      />
    </>
  );
};

export default Diagnostics;
