import React, { useState, useEffect, useRef } from "react";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";

import api from "../../../API/api";
import { Badge, Button, MenuItem, TablePagination, TextField } from "@mui/material";
import ResponsiveDialog from "../../../components/modal/prompt";
import AddTestimonials from "../../form/addtestimonials";
import CustomizedMenus from "../../../components/tools/menu";
import DebounceSpinner from "../../../components/common/debounce-spinner";
import useDebounce from "../../../hooks/use-debounce";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#016b8b',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Testimonials = () => {
  const tableRef = useRef(null);
  const [getRow, setGetRow] = useState();
  const [orders, setOrders] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [selectedTest, setSelectedTestimonials] = useState({})
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rerunfunc, setRerun] = useState(false);

  const viewOrders = (newPage) => {
    setLoading(true)
    api
      .getTestimonials({ page: newPage, per_page: rowsPerPage }, 1)
      .then((res) => {
        console.log("===>>>>", res);
        // setDepartment(res.data.data)
        setOrders(res.data.data);
        setLoading(false)
      })
      .catch((err) => {
        console.log(err.response);
      });
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    viewOrders();
  }, [rerunfunc]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    viewOrders(page, event.target.value);
  };
  const handleStatus = (status) => {
    console.log(getRow, "getRow");
    const payload = {
      order_id: getRow.id,
      status: status,
    };
    api
      .UpdateHealthPackageAppointmentStatus(payload)
      .then((res) => {
        console.log(res);
        viewOrders();
        if (res.data.success) {
          Swal.fire({
            title: "Success!",
            text: "Status Updated",
            icon: "success",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickOpen = (edit = false) => {
    setOpen(true);
    setSelectedTestimonials(getRow);
    setIsEdit(edit);
  };
  const handleDeleteDoctorOpen = () => setOpen2(true)

  const handleDeleteDoctor = () => {
    api.deleteTestimonials(getRow.id).then(res => {
      console.log(res);
      Swal.fire({
        title: "Success!",
        text: "Deleted",
        icon: "success",
        confirmButtonText: "Ok",
      });
      setOpen2(false)
      viewOrders();
    }).catch(err => console.log(err))

  }
  const getStatusColor = (status) => {
    switch (status) {
      case "requested":
        return "primary";
      case "confirmed":
        return "success";
      case "completed":
        return "warning";
      case "cancelled":
        return "error";
      default:
        return "default";
    }
  };
  const menuButtons = [
    {
      id: 1,
      name: "edit",
      onClick: () => handleClickOpen(true),
      icon: "EditIcon",
    },
    {
      id: 2,
      name: "delete",
      onClick: () => handleDeleteDoctorOpen(),
      icon: "DeleteIcon",
    },
  ];

  return (
    <>
      <Button variant="contained" onClick={() => { setOpen(true); setSelectedTestimonials({}) }}>Add Testimonials</Button>
      {loading && <DebounceSpinner isDebouncing={loading} />}
      <TableContainer component={Paper} ref={tableRef}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="center">Title</StyledTableCell>
              <StyledTableCell align="center">Description</StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders &&
              orders?.data?.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.title}</StyledTableCell>
                  <StyledTableCell align="center">{row.desc}</StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomizedMenus
                      row={row}
                      menuButtons={menuButtons}
                      setGetRow={setGetRow}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={orders?.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ResponsiveDialog
        modalOpen={open}
        setModalOpen={setOpen}
        form={
          <AddTestimonials
            isEdit={isEdit}
            data={selectedTest}
            handleClose={handleClose}
            setLoading={setLoading}
            setModalOpen={setOpen}
            setRerun={setRerun}
          />
        }
        title={"Add Testimonials"}
        cancelButtonText={"cancel"}
        submitButtonText={" "}
        size="lg"
      />
      <ResponsiveDialog
        modalOpen={open2}
        setModalOpen={setOpen2}
        form={
          <div className="row gap-1">
            <button className="btn btn-sm btn-danger" onClick={()=>handleDeleteDoctor()} >Delete </button>
            <button className="btn btn-sm btn-info" onClick={() => setOpen2(false)}>Cancel</button>
          </div>
        }
        title={"Delete Testimonials"}
        cancelButtonText={" "}
        submitButtonText={" "}
        size="lg"
      />
    </>
  );
};

export default Testimonials;
