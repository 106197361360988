import React, { useEffect, useState } from "react";
import api from "../../API/api";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import TablePagination from '@mui/material/TablePagination';
import CustomizedMenus from "../../components/tools/menu";
import Chip from '@mui/material/Chip';



const Pharmacy = () => {

    const [list, setList] = useState([])
    const [getRow, setGetRow] = useState();

    const handleClickOpen = (e) => e

    useEffect(() => {
        api.fetchPharmacy().then(res => {
            console.log(res);
            setList(res.data.pharmacy)
        }).catch(
            err => console.log(err, 'pharmacy error')
        )
    }, [])
    const menuButtons = [
        {
            id: 1,
            name: "edit",
            onClick: () => handleClickOpen(true),
            icon: "EditIcon",
        },
        {
            id: 3,
            name: "delete",
            // onClick: handleDeleteDoctor,
            icon: "DeleteIcon",
        },
    ];
    return (
        <div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>ID</StyledTableCell>
                            <StyledTableCell align="center">Name</StyledTableCell>
                            <StyledTableCell align="center">Email</StyledTableCell>
                            <StyledTableCell align="center">Delivery Address</StyledTableCell>
                            <StyledTableCell align="center">phone</StyledTableCell>
                            <StyledTableCell align="center">status</StyledTableCell>
                            <StyledTableCell align="center">prescription</StyledTableCell>
                            <StyledTableCell align="center">Action</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {list && list.data?.map((row, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell component="th" scope="row">
                                    {index + 1}
                                </StyledTableCell>
                                <StyledTableCell align="center">{row.name}</StyledTableCell>
                                <StyledTableCell align="center">{row.email}</StyledTableCell>
                                <StyledTableCell align="center">{row.delivery_address}</StyledTableCell>
                                <StyledTableCell align="center">{row.phone}</StyledTableCell>
                                {/* enum('requested', 'confirmed', 'cancelled', 'compl...	 */}
                                <StyledTableCell align="center">{
                                    row.status === 'requested' ?
                                        <Chip label={row.status} color="primary" /> :
                                        row.status === 'confirmed' ?
                                            <Chip label={row.status} color="success" />
                                            : row.status === 'cancelled' ?
                                                <Chip label={row.status} color="danger" />
                                                :
                                                <Chip label={row.status} color="danger" />

                                }</StyledTableCell>
                                <StyledTableCell align="center"><img src={row.prescription} height='50px' /> </StyledTableCell>

                                <StyledTableCell align="center">
                                    <CustomizedMenus row={row}
                                        menuButtons={menuButtons}
                                        setGetRow={setGetRow}
                                    />

                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
            // count={doctor?.data?.length}
            // rowsPerPage={rowsPerPage}
            // page={page === 1 ? 0 : page}
            // onPageChange={handleChangePage}
            // onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    )

}

export default Pharmacy;
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#016b8b',
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));
