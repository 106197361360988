import React, { useState, useEffect, useRef } from "react";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";

import api from "../../../API/api";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  Badge,
  MenuItem,
  TablePagination,
  TextField,
  Backdrop,
  CircularProgress,
  Box,
  Tooltip,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import CustomizedMenus from "../../../components/tools/menu";
import TextFieldTableHead from "../../../components/table/textfield-table-head";
import useDebounce from "../../../hooks/use-debounce";
import DebounceSpinner from "../../../components/common/debounce-spinner";
import AccordionFilter from "../../../components/filter/accordion-filter";
import MenuModal from "../../../components/tools/menu-modal";
import StatusHistoryModal from "./features/status-history-modal";
import { useCenterContext } from "../../../context/center-context";
import { toast } from "react-toastify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#016b8b",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const DoctorAppointments = () => {
  const { centers } = useCenterContext();

  const tableRef = useRef(null);
  const [getRow, setGetRow] = useState();
  const [appoinments, setAppoinments] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // modal state
  // this modal state is gonna go three levels deep
  // 1. on the menu component the modal props will be updated
  // along with setting the onClick function for the status update
  // 2. then on the menu-modal component remarks will be added
  // 3. then on the menu-modal component onClick function will be called
  // 4. then on the doctor.appointments component handleStatus function will be called
  const [openModal, setOpenModal] = useState(false);
  const [modalProps, setModalProps] = useState({
    status: "",
    onClick: () => { },
  });
  const [updatingStatus, setUpdatingStatus] = useState(false);

  // status history modal
  const [statusHistoryModal, setStatusHistoryModal] = useState(false);
  const [statusHistoryModalData, setStatusHistoryModalData] = useState({});
  const handleOpenStatusHistoryModal = () => {
    setStatusHistoryModal(true);
  };
  const handleCloseStatusHistoryModal = () => {
    setStatusHistoryModal(false);
    setStatusHistoryModalData({});
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [filters, setFilters] = useState({
    filter_status: "",
  });

  const viewDoctors = (newPage) => {
    api
      .GetAppointmentsForDoctor(newPage, rowsPerPage)
      .then((res) => {
        console.log("===>>>>", res);
        // setDepartment(res.data.data)
        setAppoinments(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    viewDoctors();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    viewDoctors(page, event.target.value);
  };
  const handleStatus = (status, remarks) => {
    setUpdatingStatus(true);
    console.log(getRow, "getRow");
    const payload = {
      doctor_id: getRow.doctors[0].id,
      appointment_id: getRow.id,
      status: status,
      remarks,
    };
    api
      .doctorAppointmentStatus(payload)
      .then((res) => {
        console.log(res);
        viewDoctors();
        handleCloseModal();
        if (res.data.success) {
          Swal.fire({
            title: "Success!",
            text: "Status Updated",
            icon: "success",
            confirmButtonText: "Ok",
          });
        }
      })
      .catch((err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went wrong.");
        }
      })
      .finally(() => {
        setUpdatingStatus(false);
        setModalProps({});
      });
  };

  // status badge clic
  // will open a modal with a table
  const handleStatusBadgeClick = (row) => {
    setGetRow(row);
    setStatusHistoryModalData(row);
    handleOpenStatusHistoryModal();
  };

  const menuButtons = [
    {
      id: 1,
      name: "Confirmed",
      onClick: (remarks) => handleStatus("confirmed", remarks),
    },
    {
      id: 2,
      name: "Cancelled",
      onClick: (remarks) => handleStatus("cancelled", remarks),
    },
    {
      id: 3,
      name: "Completed",
      onClick: (remarks) => handleStatus("completed", remarks),
    },
  ];

  const getStatusColor = (status) => {
    switch (status) {
      case "requested":
        return "primary";
      case "confirmed":
        return "success";
      case "completed":
        return "warning";
      case "cancelled":
        return "error";
      default:
        return "default";
    }
  };

  const viewAppointmentsByFilters = async () => {
    console.log(filters);
    let newFilters = { ...filters };
    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key] === "") {
        delete newFilters[key];
      }
    });
    try {
      const response = await api.GetAppointmentsForDoctor(1, 5, newFilters);
      setPage(0);
      setRowsPerPage(5);
      setAppoinments(response.data.data);
    } catch (error) {
      console.log(error);
    }
    // api.GetAppointmentsForDoctor(1, 5, newFilters).then((res) => {
    //   console.log("===>>>>", res);
    //   setPage(0);
    //   setRowsPerPage(5);
    //   setAppoinments(res.data.data);
    // });
  };

  const [debouncedViewAppointmentsByFilter, isDebouncing] = useDebounce(
    viewAppointmentsByFilters,
    300
  );

  useEffect(() => {
    debouncedViewAppointmentsByFilter();
  }, [filters]);

  return (
    <>
      <div className="row justify-content-between">
        <div className="col-6">
          <h4>Doctors Appoinments </h4>
        </div>
        <div className="col-6 text-end">
          <DownloadTableExcel
            filename="Appointment List"
            sheet="Apollo"
            currentTableRef={tableRef.current}
          >
            <button className="btn btn-warning btn-sm"> Export excel </button>
          </DownloadTableExcel>
        </div>
      </div>
      <AccordionFilter
        filterOptions={{
          from: {
            id: "from",
            label: "From",
            type: "date",
          },
          to: {
            id: "to",
            label: "To",
            type: "date",
          },
        }}
        filters={filters}
        setFilters={setFilters}
        onFilterChange={debouncedViewAppointmentsByFilter}
      ></AccordionFilter>
      <TableContainer component={Paper} ref={tableRef}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="center">
                <TextFieldTableHead
                  title={"Center"}
                  type={"select"}
                  options={[
                    { label: "All", value: "" },
                    ...centers.map((center) => ({
                      label: center.name,
                      value: center.id,
                    })),
                  ]}
                  onChange={(e) => {
                    setFilters({ ...filters, center_id: e.target.value });
                  }}
                ></TextFieldTableHead>
              </StyledTableCell>
              <StyledTableCell align="center">
                <TextFieldTableHead
                  title={"Patient Name"}
                  type={"text"}
                  onChange={(e) => {
                    setFilters({ ...filters, patient_name: e.target.value });
                  }}
                ></TextFieldTableHead>
              </StyledTableCell>
              <StyledTableCell align="center">Phone No.</StyledTableCell>
              <StyledTableCell align="center">Gender</StyledTableCell>
              <StyledTableCell align="center">Age</StyledTableCell>
              <StyledTableCell align="center">City</StyledTableCell>
              <StyledTableCell align="center">Generated At</StyledTableCell>
              <StyledTableCell align="center">Appoin. Date</StyledTableCell>
              <StyledTableCell align="center">For Doctor</StyledTableCell>
              <StyledTableCell align="center">Last Updated By</StyledTableCell>
              <StyledTableCell align="center">
                <TextField
                  select
                  label="Status"
                  value={filters.filter_status}
                  onChange={(e) =>
                    setFilters({ ...filters, filter_status: e.target.value })
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                      color: "white", // Text color for the select
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                    },
                    "& .MuiSelect-root": {
                      color: "white",
                    },
                    "& .MuiSelect-icon": {
                      color: "white", // Dropdown icon color
                    },
                    "& .MuiMenuItem-root": {
                      color: "white", // Text color for menu items
                    },
                    minWidth: 150,
                    color: "white", // Text color for the TextField
                  }}
                  InputLabelProps={{
                    style: { color: "white" }, // Ensure label is white even when focused
                  }}
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          bgcolor: "rgba(0, 0, 0, 0.8)", // Dark background for dropdown
                          "& .MuiMenuItem-root": {
                            color: "white", // Ensure menu items are white
                          },
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value="requested">Requested</MenuItem>
                  <MenuItem value="confirmed">Confirmed</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                  <MenuItem value="cancelled">Cancelled</MenuItem>
                </TextField>
              </StyledTableCell>
              <StyledTableCell align="center">Action</StyledTableCell>
            </TableRow>
          </TableHead>
          {isDebouncing && <DebounceSpinner isDebouncing={isDebouncing} />}
          <TableBody sx={{}}>
            {!isDebouncing &&
              appoinments &&
              appoinments?.data?.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.doctor_appointments[0].doctor.name}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">{row.phone}</StyledTableCell>
                  <StyledTableCell align="center">{row.gender}</StyledTableCell>
                  <StyledTableCell align="center">{row.age}</StyledTableCell>
                  <StyledTableCell align="center">{row.city}</StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(row.created_at).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(
                      row.doctor_appointments[0].date
                    ).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {row?.doctors[0]?.deleted_at ? (
                      <Tooltip title="This Doctor is DELETED" arrow>
                        <IconButton>
                          <DeleteIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {row?.doctors[0]?.name}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {row.last_updated_by && row.last_updated_by.length > 0 ? (
                      <Stack direction={"column"}>
                        <Typography variant="body2" color="text.secondary">
                          {/* <img  height="40px" src={`${process.env.REACT_APP_BACKEND_USER}/${row?.last_updated_by[0].id}/${row?.last_updated_by[0].image}`} /> */}
                          {
                            row.last_updated_by.length > 0 ?
                              <img height="40px" src={`${process.env.REACT_APP_BACKEND_USER}/${row?.last_updated_by[row.last_updated_by.length - 1].id}/${row?.last_updated_by[row.last_updated_by.length - 1].image}`} />
                              :
                              <img height="40px" src={`${process.env.REACT_APP_BACKEND_USER}/${row?.last_updated_by[0].id}/${row?.last_updated_by[0].image}`} />
                          }
                          {
                            row.last_updated_by.length > 0 ?
                              row.last_updated_by[row.last_updated_by.length - 1].name
                              :
                              row.last_updated_by[0].name

                          }
                        </Typography>
                        <Typography variant="body2">
                          {
                            row.last_updated_by.length > 0 ?
                              row.last_updated_by[row.last_updated_by.length - 1].email
                              :
                              row.last_updated_by[0].email

                          }
                        </Typography>
                        <Typography variant="body2">
                          {row.last_updated_by[0].email}
                        </Typography>
                      </Stack>
                    ) : (
                      "null"
                    )}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Tooltip title="Click to view the history" arrow>
                      <IconButton onClick={() => handleStatusBadgeClick(row)}>
                        <Badge
                          sx={{
                            cursor: "pointer",
                          }}
                          badgeContent={row.status}
                          color={getStatusColor(row.status)}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        ></Badge>
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomizedMenus
                      opensModal={true}
                      setModalProps={setModalProps}
                      handleOpenModal={handleOpenModal}
                      openModal={openModal}
                      row={row}
                      menuButtons={menuButtons}
                      setGetRow={setGetRow}
                      btnText={"update status"}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={appoinments?.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <MenuModal
        updatingStatus={updatingStatus}
        handleOpen={handleOpenModal}
        handleClose={handleCloseModal}
        open={openModal}
        status={modalProps.status}
        modalProps={modalProps}
        setModalProps={setModalProps}
        handleClick={modalProps.onClick}
      />
      <StatusHistoryModal
        open={statusHistoryModal}
        handleClose={handleCloseStatusHistoryModal}
        data={statusHistoryModalData}
        setStatusHistoryModalData={setStatusHistoryModalData}
      />
    </>
  );
};

export default DoctorAppointments;
