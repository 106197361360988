import { Button, Modal, Typography, Box, TextField } from "@mui/material";
import { useEffect, useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const MenuModal = ({
  status,
  handleClick,
  handleOpen,
  handleClose,
  open,
  updatingStatus,
  setModalProps,
}) => {
  const [remarks, setRemarks] = useState("");

  const handleSave = () => {
    handleClick(remarks);
  };

  useEffect(() => {
    return () => {
      setRemarks("");
    };
  }, []);

  const localHandleClose = () => {
    setModalProps({});
    handleClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => localHandleClose()}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <Box id="modal-title" variant="h6" component="h2">
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              label="Status"
              value={status}
              disabled
              fullWidth
            />
          </Box>
          <Box id="modal-description" sx={{ mt: 2 }}>
            <TextField
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              rows={4}
              fullWidth
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              label="Remarks"
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button
              disabled={updatingStatus}
              variant="contained"
              onClick={handleSave}
              fullWidth
            >
              {updatingStatus ? "Saving..." : "Save"}
            </Button>
          </Box>
          <Button
            disabled={updatingStatus}
            onClick={() => localHandleClose()}
            variant="ghost"
            sx={{ mt: 2 }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default MenuModal;
