import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Swal from "sweetalert2";
import axios from "axios";
import api from "../../../API/api";
import JoditEditor from "jodit-react";
import { Paper } from "@mui/material";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCenterContext } from "../../../context/center-context";

const AddDiagnostic = (props) => {
  const { centers } = useCenterContext();
  console.log(centers, "centers");

  // for all department getting from api
  const [diagnostic, setDiagnostic] = useState({
    test_name: "",
    description: "",
    center_id: "",
  });

  // this component should handle edit and add
  useEffect(() => {
    if (props.isEdit) {
      let updateObject = {
        diagnostic_id: props.data.id,
        test_name: props.data.test_name,
        description: props.data.description,
        center_id: props.data.centers?.[0]?.id || "",
      };
      setDiagnostic(updateObject);
    }
  }, [props.isEdit, props.data]);

  const validateInputs = () => {
    const toastErrorConfig = {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
    };

    if (diagnostic.test_name === null) {
      toast.error("test name cannot empty", toastErrorConfig);
      return false;
    }
    if (diagnostic.description === null) {
      toast.error("description cannot empty", toastErrorConfig);
      return false;
    }
    if (diagnostic.center_id === null) {
      toast.error("Center cannot empty", toastErrorConfig);
      return false;
    }
    return true;
  };

  const updateDiagnostic = () => {
    const valid = validateInputs();
    if (!valid) {
      return;
    }

    api
      .updateDiagnostic(diagnostic)
      .then((res) => {
        console.log(res, "success");
        Swal.fire({
          title: "Success!",
          text: "Updated Succeffully !",
          icon: "success",
          confirmButtonText: "Ok",
        });
        props.updateList(0, 5);
        if (props.handleClose) {
          props.handleClose();
        }

        // clear the form inputs
        setDiagnostic({
          test_name: "",
          description: "",
          center_id: "",
        });
      })
      .catch((err) => {
        console.log(err.response, "dasbjd");
        if (err.response.data.message) {
          toast.error(`${err.response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
          });
        }
        if (err.response.data.errors) {
          toast.error(`${err.response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
          });
        }
      });
  };

  const handleSubmit = () => {
    const valid = validateInputs();
    if (!valid) {
      return;
    }

    const postData = new FormData();
    postData.append("test_name", diagnostic.test_name);
    postData.append("description", diagnostic.description);
    postData.append("center_id", diagnostic.center_id);

    api
      .addDiagnostic(postData)
      .then((res) => {
        console.log(res, "success");
        if (props.handleClose) {
          props.handleClose();
        }
        Swal.fire({
          title: "Success!",
          text: "Added Succeffully !",
          icon: "success",
          confirmButtonText: "Ok",
        });
        // clear the form inputs
        setDiagnostic({
          test_name: "",
          description: "",
          center_id: "",
        });
        // window.location.reload();
      })
      .catch((err) => {
        console.log(err.response, "dasbjd");
        if (err.response.data.errors) {
          toast.error(`${err.response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      });
  };

  const handleChangeCenter = (e) => {
    setDiagnostic({ ...diagnostic, center_id: e.target.value });
    // viewDepartment(e.target.value);
  };

  const handleInput = (e) => {
    setDiagnostic({ ...diagnostic, [e.target.name]: e.target.value });
  };

  return (
    <section>
      <div className="row justify-content-between">
        <div className="col-12 text-center">
          <h4>Add Diagnostics </h4>
        </div>
      </div>
      {/* <ToastContainer /> */}
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="p- border row">
              <Box
                component="form"
                sx={{
                  "& > :not(style)": { m: 1 },
                }}
                noValidate
                autoComplete="off"
                className="row"
              >
                <div className="row">
                  <div className="col-md-6 my-2">
                    <TextField
                      id="standard-basic"
                      label="Test Name"
                      variant="outlined"
                      size="small"
                      className="form-control"
                      name="test_name"
                      onChange={handleInput}
                      value={diagnostic.test_name}
                      required
                    />
                  </div>

                  <div className="col-md-6 my-2">
                    <FormControl fullWidth size="small" variant="outlined">
                      <InputLabel id="demo-select-small-label">
                        Select Center{" "}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={diagnostic.center_id}
                        label="Select Center"
                        onChange={handleChangeCenter}
                        name="center_id"
                      >
                        {centers?.map((center) => (
                          <MenuItem value={center.id}>{center.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-md-12 my-2">
                    <TextField
                      multiline
                      type="text"
                      id="standard-basic"
                      label="Test Description"
                      variant="outlined"
                      size="small"
                      className="form-control col-12"
                      name="description"
                      onChange={handleInput}
                      value={diagnostic.description}
                      required
                    />
                  </div>

                  {/* <label htmlFor="">Description</label> */}
                  {/* <textarea name="description" id="" cols="30" className='form-control' rows="3" onChange={handleInput} >{doctorName.description}</textarea> */}

                  {/* {Jodit()} */}
                </div>
                <div className="text-center my-2">
                  {props.isEdit ? (
                    <Button variant="contained" onClick={updateDiagnostic}>
                      Update
                    </Button>
                  ) : (
                    <Button variant="contained" onClick={handleSubmit}>
                      Submit
                    </Button>
                  )}
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddDiagnostic;
