import React, { useState, useEffect, useRef } from "react";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";

import api from "../../../API/api";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  Badge,
  IconButton,
  MenuItem,
  Stack,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomizedMenus from "../../../components/tools/menu";
import { toast } from "react-toastify";
import useDebounce from "../../../hooks/use-debounce";
import DebounceSpinner from "../../../components/common/debounce-spinner";
import TextFieldTableHead from "../../../components/table/textfield-table-head";
import AccordionFilter from "../../../components/filter/accordion-filter";
import { TableHeadTextFieldGenerator } from "../../../components/table/tablehead-textfield-generator";
import { useMenuModalStates } from "../../../hooks/use-menu-modal-states";
import MenuModal from "../../../components/tools/menu-modal";
import AppointmentStatusHistoryModal from "../../../components/appointment-status-history/appointment-status-history-modal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#016b8b",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const ServiceAppointments = () => {
  const tableRef = useRef(null);
  const [getRow, setGetRow] = useState();
  const [appointments, setAppointments] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const {
    openModal,
    modalProps,
    updatingStatus,
    setUpdatingStatus,
    statusHistoryModal,
    statusHistoryModalData,
    setStatusHistoryModalData,
    handleOpenStatusHistoryModal,
    handleCloseStatusHistoryModal,

    setModalProps,
    handleOpenModal,
    handleCloseModal,
  } = useMenuModalStates();

  const [filter, setFilter] = useState({
    filter_status: "",
  });

  const viewAppointments = (newPage) => {
    api
      .GetAppointmentsForService({ page: newPage, per_page: rowsPerPage }, 1)
      .then((res) => {
        console.log("===>>>>", res);
        // setDepartment(res.data.data)
        setAppointments(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    viewAppointments();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    viewAppointments(page, event.target.value);
  };

  const handleStatus = (status, remarks) => {
    setUpdatingStatus(true);
    console.log(getRow, "getRow");
    const payload = {
      service_id: getRow.service_appointments[0].service_id,
      appointment_id: getRow.service_appointments[0].appointment_id,
      status: status,
      remarks,
    };
    api
      .UpdateServiceAppointmentStatus(payload)
      .then((res) => {
        console.log(res);
        viewAppointments();
        handleCloseModal();
        if (res.data.success) {
          Swal.fire({
            title: "Success!",
            text: "Status Updated",
            icon: "success",
            confirmButtonText: "Ok",
          });
        }
        toast.success("Status Updated Successfully");
      })
      .catch((err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      })
      .finally(() => {
        setUpdatingStatus(false);
        setModalProps({});
      });
  };

  // status badge clic
  // will open a modal with a table
  const handleStatusBadgeClick = (row) => {
    setGetRow(row);
    setStatusHistoryModalData(row);
    handleOpenStatusHistoryModal();
  };

  const menuButtons = [
    {
      id: 1,
      name: "Confirmed",
      onClick: (remarks) => handleStatus("confirmed", remarks),
    },
    {
      id: 2,
      name: "Cancelled",
      onClick: (remarks) => handleStatus("cancelled", remarks),
    },
    {
      id: 3,
      name: "Completed",
      onClick: (remarks) => handleStatus("completed", remarks),
    },
  ];
  const getStatusColor = (status) => {
    switch (status) {
      case "requested":
        return "primary";
      case "confirmed":
        return "success";
      case "completed":
        return "warning";
      case "cancelled":
        return "error";
      default:
        return "default";
    }
  };

  const viewAppointmentsByFilters = () => {
    setPage(0);
    setRowsPerPage(5);
    let newFilters = { ...filter };
    Object.keys(newFilters).forEach((key) => {
      if (newFilters[key] === "") {
        delete newFilters[key];
      }
    });

    api
      .GetAppointmentsForService({ page: 1, per_page: 5 }, 1, null, newFilters)
      .then((res) => {
        console.log("===>>>>", res);
        // setDepartment(res.data.data)
        setAppointments(res.data.data);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const [debouncedViewAppointmentsByFilter, isDebouncing] = useDebounce(
    viewAppointmentsByFilters,
    300
  );

  useEffect(() => {
    debouncedViewAppointmentsByFilter();
  }, [filter]);

  useEffect(() => {
    console.log(statusHistoryModalData, "statusHistoryModalData");
  }, [statusHistoryModalData]);

  return (
    <>
      <h6>Services</h6>
      <AccordionFilter
        filterOptions={{
          from: {
            id: "from",
            label: "From",
            type: "date",
          },
          to: {
            id: "to",
            label: "To",
            type: "date",
          },
        }}
        filters={filter}
        setFilters={setFilter}
        onFilterChange={debouncedViewAppointmentsByFilter}
      ></AccordionFilter>
      <TableContainer component={Paper} ref={tableRef}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableHeadTextFieldGenerator
                heads={[
                  {
                    label: "ID",
                  },
                  {
                    label: "Patient Name",
                    isFilter: true,
                    onChange: (e) =>
                      setFilter({ ...filter, patient_name: e.target.value }),
                  },
                  {
                    label: "Phone No.",
                  },
                  {
                    label: "Gender",
                    // isFilter: true,
                    // onChange: (e) =>
                    //   setFilter({ ...filter, patient_gender: e.target.value }),
                    // type: "select",
                    // options: [
                    //   { value: "", label: "All" },
                    //   { value: "male", label: "Male" },
                    //   { value: "female", label: "Female" },
                    // ],
                  },
                  {
                    label: "Age",
                  },
                  {
                    label: "Address",
                    sx: {
                      alignSelf: "center",
                    },
                  },
                  {
                    label: "Generated At",
                  },
                  {
                    label: "Appoin. Date",
                  },
                  {
                    label: "Service For",
                  },
                  {
                    label: "Last Updated By",
                  },
                  {
                    label: "Status",
                    type: "select",
                    isFilter: true,
                    options: [
                      { value: "", label: "All" },
                      { value: "requested", label: "Requested" },
                      { value: "confirmed", label: "Confirmed" },
                      { value: "completed", label: "Completed" },
                      { value: "cancelled", label: "Cancelled" },
                    ],
                    onChange: (e) =>
                      setFilter({ ...filter, filter_status: e.target.value }),
                  },
                  {
                    label: "Action",
                  },
                ]}
                Wrapper={StyledTableCell}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {isDebouncing && <DebounceSpinner isDebouncing={isDebouncing} />}
            {!isDebouncing &&
              appointments &&
              appointments?.data?.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">{row.phone}</StyledTableCell>
                  <StyledTableCell align="center">{row.gender}</StyledTableCell>
                  <StyledTableCell align="center">{row.age}</StyledTableCell>
                  <StyledTableCell align="center">{row.city}</StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(row.created_at).getDate() +
                      ", " +
                      new Date(row.created_at).toLocaleString("default", {
                        month: "long",
                      }) +
                      "-" +
                      new Date(row.created_at).getFullYear()}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {new Date(row.service_appointments[0].date).getDate() +
                      ", " +
                      new Date(row.service_appointments[0].date).toLocaleString(
                        "default",
                        {
                          month: "long",
                        }
                      ) +
                      "-" +
                      new Date(row.service_appointments[0].date).getFullYear()}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    {row.services.map((obj) => obj.service + ",")}
                  </StyledTableCell>
                  {/* <StyledTableCell align="center">
                    {row.doctors[0].name}
                  </StyledTableCell> */}
                  <StyledTableCell align="center">
                    {row.last_updated_by && row.last_updated_by.length > 0 ? (
                      <Stack direction={"column"}>
                        <Typography variant="body2" color="text.secondary">
                          {/* <img  height="40px" src={`${process.env.REACT_APP_BACKEND_USER}/${row?.last_updated_by[0].id}/${row?.last_updated_by[0].image}`} /> */}
                          {
                            row.last_updated_by.length > 0 ?
                              <img height="40px" src={`${process.env.REACT_APP_BACKEND_USER}/${row?.last_updated_by[row.last_updated_by.length - 1].id}/${row?.last_updated_by[row.last_updated_by.length - 1].image}`} />
                              :
                              <img height="40px" src={`${process.env.REACT_APP_BACKEND_USER}/${row?.last_updated_by[0].id}/${row?.last_updated_by[0].image}`} />
                          }
                          {
                            row.last_updated_by.length > 0 ?
                              row.last_updated_by[row.last_updated_by.length - 1].name
                              :
                              row.last_updated_by[0].name

                          }
                        </Typography>
                        <Typography variant="body2">
                          {
                            row.last_updated_by.length > 0 ?
                              row.last_updated_by[row.last_updated_by.length - 1].email
                              :
                              row.last_updated_by[0].email

                          }
                        </Typography>
                      </Stack>
                    ) : (
                      "null"
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip title="Click to view the history" arrow>
                      <IconButton onClick={() => handleStatusBadgeClick(row)}>
                        <Badge
                          badgeContent={row.status}
                          color={getStatusColor(row.status)}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                        ></Badge>
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <CustomizedMenus
                      opensModal={true}
                      setModalProps={setModalProps}
                      handleOpenModal={handleOpenModal}
                      openModal={openModal}
                      row={row}
                      menuButtons={menuButtons}
                      setGetRow={setGetRow}
                      btnText={"update status"}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={appointments?.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <MenuModal
        updatingStatus={updatingStatus}
        handleOpen={handleOpenModal}
        handleClose={handleCloseModal}
        open={openModal}
        status={modalProps.status}
        handleClick={modalProps.onClick}
        setModalProps={setModalProps}
      />
      <AppointmentStatusHistoryModal
        open={statusHistoryModal}
        handleClose={handleCloseStatusHistoryModal}
        data={statusHistoryModalData}
        setStatusHistoryModalData={setStatusHistoryModalData}
        getAppointmentHistoryFromServer={api.serviceAppointmentHistory}
        historyFor="services"
      />
    </>
  );
};

export default ServiceAppointments;
