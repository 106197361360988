import { useState } from "react";

export const useMenuModalStates = () => {
  // modal state
  // this modal state is gonna go three levels deep
  // 1. on the menu component the modal props will be updated
  // along with setting the onClick function for the status update
  // 2. then on the menu-modal component remarks will be added
  // 3. then on the menu-modal component onClick function will be called
  // 4. then on the doctor.appointments component handleStatus function will be called
  const [openModal, setOpenModal] = useState(false);
  const [modalProps, setModalProps] = useState({
    status: "",
    onClick: () => {},
  });
  const [updatingStatus, setUpdatingStatus] = useState(false);

  // status history modal
  const [statusHistoryModal, setStatusHistoryModal] = useState(false);
  const [statusHistoryModalData, setStatusHistoryModalData] = useState({});
  const handleOpenStatusHistoryModal = () => {
    setStatusHistoryModal(true);
  };
  const handleCloseStatusHistoryModal = () => {
    setStatusHistoryModal(false);
    setStatusHistoryModalData({});
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return {
    openModal,
    modalProps,
    setModalProps,
    updatingStatus,
    setUpdatingStatus,
    statusHistoryModal,
    statusHistoryModalData,
    setStatusHistoryModalData,
    handleOpenStatusHistoryModal,
    handleCloseStatusHistoryModal,
    handleOpenModal,
    handleCloseModal,
  };
};
