import { Backdrop, Box, CircularProgress } from "@mui/material";

const DebounceSpinner = ({ isDebouncing }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        flex: 1,
        paddingLeft: {
          xs: 0, // Default padding for extra small screens
          sm: "250px", // Padding for screens wider than 600px (sm breakpoint)
        },
      }}
    >
      <Backdrop
        sx={{
          width: "100%",
          height: "100%",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: {
            xs: 0, // Default padding for extra small screens
            sm: "250px", // Padding for screens wider than 600px (sm breakpoint)
          },
        }}
        open={isDebouncing}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default DebounceSpinner;
