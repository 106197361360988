import React, { useState, useEffect, useRef } from "react";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Swal from "sweetalert2";

import api from "../../../API/api";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Badge, TablePagination } from "@mui/material";
import CustomizedMenus from "../../../components/tools/menu";
import AddHealthPackage from "./health-package.add-health-package";
import ResponsiveDialog from "../../../components/modal/prompt";
import { toast } from "react-toastify";
import { TableHeadTextFieldGenerator } from "../../../components/table/tablehead-textfield-generator";
import { useCenterContext } from "../../../context/center-context";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#016b8b',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const HealthPackages = (props) => {
  const { centers } = useCenterContext();

  const tableRef = useRef(null);
  const [getRow, setGetRow] = useState();
  const [healthPackages, setHealthPackages] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [isEdit, setIsEdit] = useState(false);
  const [selectedHealthPackage, setSelectedHealthPackage] = useState();
  const [open, setOpen] = React.useState(false);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [filters, setFilters] = useState({});

  const viewHealthPackages = (newPage, per_page) => {
    api
      .getHealthPackages({ page: newPage + 1, per_page: per_page }, 1)
      .then((res) => {
        console.log("===>>>>", res);
        // setDepartment(res.data.data)
        setHealthPackages(res.data.healthPackages);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleClickOpen = (edit = false) => {
    setOpen(true);
    setSelectedHealthPackage(getRow);
    setIsEdit(edit);
  };

  const handleDeleteHealthPackage = (row) => {
    setDeleteOpen(true);
    setSelectedHealthPackage(getRow);
  };

  useEffect(() => {
    viewHealthPackages();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    viewHealthPackages(0, event.target.value);
  };

  useEffect(() => {
    viewHealthPackages(page, rowsPerPage);
  }, [page, rowsPerPage]);

  const viewHealthPackagesFilters = (page, rowsPerPage, filters) => {
    setRowsPerPage(rowsPerPage);
    setPage(page);
    let localFilters = { ...filters };
    Object.keys(localFilters).forEach((key) => {
      if (localFilters[key] === "") {
        delete localFilters[key];
      }
    });
    api
      .getHealthPackages(
        { page: page + 1, per_page: rowsPerPage },
        localFilters
      )
      .then((res) => {
        console.log("===>>>>", res);
        // setDepartment(res.data.data)
        setHealthPackages(res.data.healthPackages);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  useEffect(() => {
    viewHealthPackagesFilters(0, 10, filters);
  }, [filters]);

  const deleteHealthPackage = () => {
    api
      .deleteHealthPackage(getRow.id)
      .then((res) => {
        console.log(res);

        setPage(0);
        setRowsPerPage(5);
        viewHealthPackages(0, rowsPerPage);
        setDeleteOpen(false);
        toast.success("Health Package Deleted Successfully");
      })
      .catch((err) => {
        console.log(err, "yo err");
        if (err.response) {
          if (err.response.data) {
            if (err.response.data.message) {
              setDeleteOpen(false);
              toast.error(err.response.data.message);
              return;
            }
          }
        }
        setDeleteOpen(false);
        toast.error("Health Package Deletion Failed");
      });
  };

  const handleClose = () => setOpen(false);

  const handleDeleteClose = () => setDeleteOpen(false);

  const menuButtons = [
    {
      id: 1,
      name: "edit",
      onClick: () => handleClickOpen(true),
      icon: "EditIcon",
    },
    {
      id: 3,
      name: "delete",
      onClick: handleDeleteHealthPackage,
      icon: "DeleteIcon",
    },
  ];

  return (
    <>
    <h6>Health Packages </h6>
      <TableContainer component={Paper} ref={tableRef}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableHeadTextFieldGenerator
                align="center"
                Wrapper={StyledTableCell}
                heads={[
                  {
                    label: "ID",
                    isFilter: false,
                  },
                  {
                    label: "Name",
                    isFilter: false,
                  },
                  {
                    label: "Price",
                    isFilter: false,
                  },
                  {
                    label: "Discounted Price",
                    isFilter: false,
                  },
                  {
                    label: "Discounted Percentage",
                    isFilter: false,
                  },
                  {
                    label: "Description",
                    isFilter: false,
                  },
                  {
                    label: "Center",
                    isFilter: true,
                    type: "select",
                    onChange: (e) => {
                      setFilters({ ...filters, center_id: e.target.value });
                    },
                    options: [
                      {
                        label: "All",
                        value: "",
                      },
                      ...centers.map((center) => ({
                        label: center.name,
                        value: center.id,
                      })),
                    ],
                  },
                  {
                    label: "Action",
                    isFilter: false,
                  },
                ]}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {healthPackages &&
              healthPackages?.data?.map((row, index) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.name}</StyledTableCell>
                  <StyledTableCell align="center">{row.price}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.discounted_price}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {(
                      ((row.price - row.discounted_price) / row.price) *
                      100
                    ).toFixed(2)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row.description}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {row?.centers[0]?.name}
                  </StyledTableCell>

                  {/* <StyledTableCell align="center">
                    {row.doctors[0].name}
                  </StyledTableCell> */}
                  <StyledTableCell align="center">
                    <CustomizedMenus
                      row={row}
                      menuButtons={menuButtons}
                      setGetRow={setGetRow}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={healthPackages?.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <ResponsiveDialog
        modalOpen={open}
        setModalOpen={setOpen}
        form={
          <AddHealthPackage
            data={selectedHealthPackage}
            handleClose={handleClose}
            isEdit={isEdit}
          />
        }
        title={"Update Health Package"}
        cancelButtonText={"cancel"}
        submitButtonText={" "}
        size="lg"
      />
      <ResponsiveDialog
        modalOpen={deleteOpen}
        setModalOpen={setDeleteOpen}
        form={<p></p>}
        title={"Are You Sure to Delete"}
        cancelButtonText={"cancel"}
        submitButtonText={"Delete"}
        submitFunction={deleteHealthPackage}
        size="lg"
      />
    </>
  );
};

export default HealthPackages;
