import React from "react";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

/**
 * @typedef {Object} SubItem
 * @property {string} title - The title of the sub-item
 * @property {React.ReactNode} icon - The icon component for the sub-item
 * @property {Function} onClick - The click handler for the sub-item
 * @property {string} [role] - The role required to view this sub-item (optional)
 */

/**
 * @typedef {Object} MenuItem
 * @property {string} title - The title of the menu item
 * @property {React.ReactNode} icon - The icon component for the menu item
 * @property {boolean} open - Whether the menu item is expanded or not
 * @property {Function} onClick - The click handler for the menu item
 * @property {string[]} [role] - The role required to view this menu item (optional)
 * @property {SubItem[]} [subItems] - An array of sub-items for this menu item (optional)
 */

/**
 * A component that renders a nested menu structure
 * @param {Object} props - The component props
 * @param {MenuItem[]} props.items - An array of menu items to render
 * @param {string} props.userRole - The role of the current user
 * @returns {React.ReactElement} The rendered menu component
 */
export const MenuComponent = ({ items, userRole }) => {
  console.log(items, userRole, "yeah yo know");
  return (
    <>
      {items.map((item, index) => {
        if (item.role && !item.role.includes(userRole)) return null;

        return (
          <React.Fragment key={index}>
            <ListItemButton onClick={item.onClick}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.title} />
              {item.subItems && (item.open ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>

            {item.subItems && (
              <Collapse in={item.open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.subItems.map((subItem, subIndex) => {
                    if (subItem.role && !subItem.role.includes(userRole))
                      return null;

                    return (
                      <ListItemButton
                        key={subIndex}
                        sx={{ pl: 4 }}
                        onClick={subItem.onClick}
                      >
                        <ListItemIcon>{subItem.icon}</ListItemIcon>
                        <ListItemText primary={subItem.title} />
                      </ListItemButton>
                    );
                  })}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
